import {WD, IAddressComponents, getBuildType} from '..';

export class MiscActions {
  static async postEnquiry(email: string, name: string, phone: string, comment: string) {
    try {
      const result = await WD.store.service.sendEnquiry(email, name, phone, comment);
      WD.store.throwableResponse.onSuccess('Success!');
      return result;
    } catch (e) {
      const msg = e + '';
      if (msg.startsWith('Thanks')) {
        WD.store.throwableResponse.onSuccess(e + '');
      } else {
        WD.store.throwableResponse.onError(e + '');
      }
    }
  }
  static async addressAutoComplete(text: string, countries: string[]) {
    let session = '5535774';
    if (WD.store.customer) {
      session = WD.store.customer.id + '';
    }
    const result = await WD.store.service.addressAutoComplete(text, countries, session);

    return result;
  }
  static async logWDApp(data: {message: string; screen: string; action: string; others: string}) {
    const logData = {
      ...data,
      user: WD.store.customer ? WD.store.customer.email : 'GUEST',
      env: process.env.NODE_ENV !== 'production' ? 'local' : getBuildType(),
    };

    const result = await WD.store.service.logWDApp(logData);
    return result;
  }
}
