import React from 'react';
import { FirebaseNamespace } from '@firebase/app-types';

import {
  EventHandler,
  CustomerActions,
  getPlatform,
  Logger,
  NotificationActions,
  sleep,
} from '@wine-delivery/wd-shared-lib';
import AskNotificationsPermissionModal from '../../Components/Notifications/AskNotificationsPermissionModal';
import { ModalHelper } from '../../Helpers/ModalHelper';

let firebase: FirebaseNamespace;
export class NotificationHandler {
  static init() {
    EventHandler.register('onLogin', this.onLogin.bind(this));
  }

  static async onLogin() {
    const firebaseModule = await import(/* webpackChunkName: "firebase-app" */ '@firebase/app');
    await import(/* webpackChunkName: "firebase-messaging" */ '@firebase/messaging');
    firebase = firebaseModule.firebase;
    if (!firebase.apps.length) {
      const config = {
        apiKey: 'AIzaSyDzp9IdyOSlXEtqLQmIS6_fx8MbOsBHV2I',
        authDomain: 'wine-delivery-1077.firebaseapp.com',
        databaseURL: 'https://wine-delivery-1077.firebaseio.com',
        projectId: 'wine-delivery-1077',
        storageBucket: 'wine-delivery-1077.appspot.com',
        messagingSenderId: '722795265254',
        appId: '1:722795265254:web:1dd2421ee60f5e790e8f27',
        measurementId: 'G-YVBXSYJY5R',
      };
      firebase.initializeApp(config);
    }
    try {
      firebase
        .messaging()
        .usePublicVapidKey('BOa-6BUUogKsu76eEpNfxrPhoJXhwb2qzpc9BBc4NpGHPY9I6mv2XN49clhO-TE0Tn_vfCbtwDDSM5FWIhmv540');
    } catch (error) {
      Logger.logError('NOTIFICATION', 'ON LOGIN init firsdk', error);
    }
    await NotificationActions.refresh();
    try {
      const type = NotificationActions.getWebOrApp();
      if (NotificationActions.userAllowPromotional(type) || NotificationActions.userAllowAlert(type)) {
        let token = null;
        token = await this.getToken();
        // Sometimes firebase returns null token;
        while (!token) {
          await sleep(5000);
          token = await this.getToken();
        }
        await CustomerActions.setDeviceToken(getPlatform(), token);
        await NotificationActions.refreshWithToken();
      }
    } catch (e) {
      Logger.logError('NOTIFICATION', 'ON LOGIN', e);
    }
  }
  static validatePermission() {
    return new Promise(async (resolve, reject) => {
      if (Notification.permission === 'default') {
        if (localStorage.getItem('rejectedNotification') === 'true') {
          throw 'User Has Previously Rejected Notification';
        }
        const type = NotificationActions.getWebOrApp();
        // await sleep(3000);
        while (ModalHelper.dispatcher.state.content) {
          await sleep(1000);
        }
        // await sleep(1000);
        ModalHelper.show(
          <AskNotificationsPermissionModal
            onAllow={async () => {
              try {
                await firebase.messaging().requestPermission();
                ModalHelper.close();
                // need to comment out resolve() in wdc because causing an error
                // resolve();
              } catch (e) {
                reject(e);
              }
            }}
            onDeny={() => {
              reject('User denied permission');
            }}
            onDontShowAgain={() => {
              localStorage.setItem('rejectedNotification', 'true');
              NotificationActions.setAllowPromotion(type, false);
              NotificationActions.setAllowAlert(type, false);
            }}
          />,
          { backgroundColor: 'grey', showCloseIcon: true },
        );
      }
    });
  }
  static async getToken() {
    Logger.logInfo('NOTIFICATION', 'TOKEN', 'VERIFYING USER PERMISSION...');
    await this.validatePermission();
    Logger.logInfo('NOTIFICATION', 'TOKEN', 'REQUESTING TOKEN...');
    const token = await firebase.messaging().getToken();
    Logger.logInfo('NOTIFICATION', 'TOKEN ACQUIRED', token);
    return token;
  }
}
