import {CURRENT_VERSION} from '../Config';

export class CacheData {
  private date: number;

  private data: string;

  private version: number;

  constructor(data: string = undefined) {
    this.date = new Date().getTime();
    this.data = data;
    this.version = CURRENT_VERSION;
  }
  setData(data: string) {
    this.data = data;
  }
  getData() {
    return this.data;
  }
  getDate() {
    return new Date(this.date);
  }
  getDateMs() {
    return this.date;
  }
  isExpired(expireMs: number) {
    return Date.now() - this.getDateMs() > expireMs;
  }
  isUpToDate() {
    if (this.version >= CURRENT_VERSION) {
      return true;
    }
    return false;
  }
}
