import {sleep} from '..';

export interface IProcessType {
  key: string;
}
export const ProcessType = {
  CART: {key: 'CART'},
  CART_ITEM_UPDATE: (sku: string) => ({key: 'CART_ITEM_UPDATE_' + sku}),
  ALL_ITEM: {key: 'ALL_ITEM'},
  CUSTOMER: {key: 'CUSTOMER'},
  DYNAMIC: (key: string) => ({key}),
  PAGE_LOAD: (key: string) => ({key: `PAGE_LOAD_${key}`}),
  NOTIFICATION: {key: 'NOTIFICATION'},
};

export class ThreadHandler {
  constructor(private store: {processState: {[key: string]: any[]}}) {}
  // Runs task sequentially, First In First Out
  async runSequential<T>(processType: IProcessType, process: () => Promise<T>, onLast?: () => any): Promise<T> {
    const queueNumber = this.queueUp(processType);
    while (this.isProcessBusy(processType, queueNumber)) {
      await sleep(50);
    }
    try {
      const result = await process();
      this.setProcessDone(processType, queueNumber);
      if (onLast && this.getQueue(processType).length === 0) {
        await onLast();
      }
      return result;
    } catch (e) {
      this.setProcessDone(processType, queueNumber);
      if (onLast && this.getQueue(processType).length === 0) {
        await onLast();
      }
      throw e;
    }
  }
  // Runs only the last task given in the specified throttle period
  runSingular<T>(
    processType: IProcessType,
    process: () => Promise<T>,
    options: {onLast?: () => any; throttle?: number} = {},
  ): Promise<T> {
    const Default = {throttle: 1000};
    options = {...Default, ...options};
    return new Promise((resolve, reject) => {
      try {
        this.clearProcessQueue(processType);
        const queueNumber = this.queueUp(processType);
        setTimeout(async () => {
          try {
            const queue = this.getQueue(processType);
            if (queue.length > 0 && queue[0] === queueNumber) {
              await process();
              if (options.onLast) {
                await options.onLast();
              }
              this.setProcessDone(processType, queueNumber);
            } else {
              while (this.getQueue(processType).length > 0) {
                await sleep(100);
              }
            }
            resolve();
          } catch (e) {
            reject(e);
          }
        }, options.throttle);
      } catch (e) {
        console.log('[ERROR]: Encountered error while executing Singular tasks,', e);
        reject(e);
      }
    });
  }
  getQueue(processType: IProcessType) {
    const key = processType.key;
    if (!this.store.processState[key]) {
      this.store.processState[key] = [];
    }
    return this.store.processState[key];
  }
  queueUp(processType: IProcessType) {
    const queue = this.getQueue(processType);
    const queueNumber = Math.floor(Math.random() * 10000000);
    queue.push(queueNumber);
    // this.store.processState = { ...this.store.processState, [processType.key]: queue };
    return queueNumber;
  }
  private clearProcessQueue(processType: IProcessType) {
    const key = processType.key;
    this.store.processState = {...this.store.processState, [key]: []};
  }
  private setProcessDone(processType: IProcessType, queueNumber: number) {
    const key = processType.key;
    const queue = this.getQueue(processType);
    this.store.processState = {...this.store.processState, [key]: queue.filter((pqn) => pqn !== queueNumber)};
  }
  public isProcessQueueBusy(processType: IProcessType) {
    const queue = this.getQueue(processType);
    return queue.length > 0;
  }
  public isProcessBusy(processType: IProcessType, queueNumber: number) {
    const queue = this.getQueue(processType);
    if (queue[0] === queueNumber) {
      return false;
    }
    return true;
  }
  public isProcessQueueEmpty(processType: IProcessType) {
    return this.getQueue(processType).length === 0;
  }
}
