import React from 'react';

import { COLOR } from '@wine-delivery/wd-shared-lib';
import { CircularProgress } from '@mui/material';
import { ResponsiveView } from '../Common/ResponsiveView';

type Props = {
  size?: number | string;
};

export function LoadingSpinner({ size }: Props): JSX.Element {
  return (
    <ResponsiveView style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress style={{ alignSelf: 'center', color: COLOR.TOSCA }} size={size} />
    </ResponsiveView>
  );
}
