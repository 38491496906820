import {WD} from '..';
import {ProductActions} from './ProductActions';

export class MyDiscoveryActions {
  static async refresh() {
    const result = await WD.store.service.getMyDiscovery();
    await ProductActions.addToAllItems(result.items);
    WD.store.myDiscoverySkus = result.items.map((i) => i.sku);
  }
  static async addToMyDiscovery(checkSum: string, sku: string) {
    const undo = [...WD.store.myDiscoverySkus];
    try {
      WD.store.myDiscoverySkus = [...WD.store.myDiscoverySkus, sku];
      await WD.store.service.addToMyDiscovery(sku, checkSum);
    } catch (e) {
      WD.store.myDiscoverySkus = undo;
      WD.store.throwableResponse.onError(e);
    }
  }
  static async removeFromMyDiscovery(sku: string) {
    const undo = [...WD.store.myDiscoverySkus];
    try {
      WD.store.myDiscoverySkus = WD.store.myDiscoverySkus.filter((s) => s !== sku);
      await WD.store.service.removeFromMyDiscovery(sku);
    } catch (e) {
      WD.store.myDiscoverySkus = undo;
      WD.store.throwableResponse.onError(e);
    }
  }
}
