import {Logger} from '..';

export class Timer {
  startTime: number;
  constructor(private name: string) {
    this.start();
  }
  start() {
    // console.log(`[TIMER][${this.name}]: TIMER STARTED`)
    this.startTime = Date.now();
  }
  getTiming() {
    return Date.now() - this.startTime;
  }
  stop() {
    return this.getTiming();
  }
  print() {
    Logger.logInfo('TIMER', this.name, `${this.getTiming()}ms`);
  }
  printLong(num = 0) {
    if (this.getTiming() > num) {
      this.print();
    }
  }
}
