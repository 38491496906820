import React from 'react';
import { Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { COLOR } from '@wine-delivery/wd-shared-lib';

export function LoadingBar() {
  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        left: 0,
        top: 0,
        // @ts-ignore
        zIndex: '1500',
        '& > * + *': {
          mt: 2,
        },
        '& .MuiLinearProgress-indeterminate': {
          backgroundColor: COLOR.TOSCA,
        },
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: COLOR.LIGHT_GREY,
        },
      }}>
      <LinearProgress />
    </Box>
  );
}
