import React, { useState } from 'react';

import { COLOR, SHADOW } from '@wine-delivery/wd-shared-lib';

import IphoneNotification from './IphoneNotification';
import { ResponsiveView } from '../Common/ResponsiveView';
import { Text } from '../Common/Text';
import { AppButton } from '../Common/Button/AppButton';
import { Touchable } from '../Common/Touchable';
import { ModalHelper } from '../../Helpers/ModalHelper';
import { useStore } from '../../Store/UseStore';

type Props = {
  onAllow?: () => any;
  onDeny?: () => any;
  onDontShowAgain?: () => any;
};

const AskNotificationsPermissionModal = ({ onAllow, onDeny, onDontShowAgain }: Props) => {
  const store = useStore();
  const [clickedAllow, setClickedAllow] = useState(false);

  return (
    <ResponsiveView style={styles.container}>
      <IphoneNotification
        style={styles.iphoneNotification}
        notification={{
          title: store.appLocale.customer_web.notifications.unbox_time,
          body: store.appLocale.customer_web.notifications.order_delivered,
        }}
      />
      <div style={styles.contentContainer}>
        {!clickedAllow && (
          <>
            <div>
              <Text header4>{store.appLocale.customer_web.notifications.allow_notification}</Text>
              <Text>{store.appLocale.customer_web.notifications.receive_delivery_notif}</Text>
            </div>
            <AppButton
              label={store.appLocale.customer_web.notifications.allow_notification}
              containerStyle={styles.fullWidth}
              style={styles.fullWidth}
              onPress={() => {
                setClickedAllow(true);
                if (onAllow) onAllow();
              }}
            />
            <AppButton
              label={store.appLocale.customer_web.notifications.ask_me_later}
              outlined
              containerStyle={styles.fullWidth}
              style={styles.fullWidth}
              onPress={() => {
                ModalHelper.close();
                if (onDeny) onDeny();
              }}
            />
            <Touchable
              onClick={() => {
                ModalHelper.close();
                if (onDontShowAgain) onDontShowAgain();
              }}>
              <Text style={styles.dontAskText}>{store.appLocale.customer_web.notifications.dont_ask_again}</Text>
            </Touchable>
          </>
        )}
        {clickedAllow && (
          <>
            <div>
              <Text header4>{store.appLocale.customer_web.notifications.last_step}</Text>
              <Text>{store.appLocale.customer_web.notifications.click_allow_button}</Text>
            </div>
          </>
        )}
      </div>
    </ResponsiveView>
  );
};

const styles = {
  container: {
    padding: 32,
    paddingBottom: 16,
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties,
  phoneContainer: {
    width: '90vw',
    padding: 8,
  } as React.CSSProperties,
  iphoneNotification: {
    marginBottom: 32,
    ...SHADOW.STANDARD(2, { innerColor: COLOR.LIGHT_TOSCA }),
  } as React.CSSProperties,
  contentContainer: {
    textAlign: 'center',
    display: 'grid',
    gap: 16,
  } as React.CSSProperties,
  fullWidth: {
    width: '100%',
  } as React.CSSProperties,
  dontAskText: {
    fontSize: 13,
    textDecoration: 'underline',
  } as React.CSSProperties,
};

export default AskNotificationsPermissionModal;
