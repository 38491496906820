import {WD, ProductActions} from '..';

export class SommelierActions {
  static async getRecommendation(ids: string[]) {
    const r = await WD.store.service.getSommelierRecommendation(ids);
    await ProductActions.addToAllItems(r.items);
    return r.items;
  }
  static async refresh() {
    const ids = await this.getRecommendationIds();
    WD.store.sommelierRecommendationIds = ids;
  }
  static getRecommendationMetaTypes() {
    return Object.keys(WD.store.sommelierRecommendationIds);
  }
  static getRecommendationNamesByType(type: string) {
    return WD.store.sommelierRecommendationIds[type] || [];
  }
  private static getRecommendationIds() {
    return WD.store.service.getSommelierRecommendationIds();
  }
  static async getRelatedItems(sku: string) {
    const r = await WD.store.service.getRelatedItems(sku);
    await ProductActions.addToAllItems(r.data);
    return r.data.map((i) => i.sku);
  }
}
