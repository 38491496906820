import {
  HARD_CODED_COUNTRIES,
  COUNTRY_AREA_CODE,
  AVAILABLE_SHIPPING_COUNTRIES,
  PASSWORD_MIN_CHARS,
  PASSWORD_MIN_CLASSES,
  IAddress,
  WD,
  MAX_WINE_PRICE,
  IMagentoStore,
  Timer,
  Logger,
  Wine,
  WineCustomAttributeType,
  ICustomAttribute,
  SearchFilterPriority,
} from '..';
import {CustomerCustomAttribute} from '../Interface/ICustomer';
import {XmlEntities} from 'html-entities';
import {BAD_WORDS} from '../Config';
import moment from 'moment';

const primitivesTypes = ['number', 'string'];
export const removeCircularReferences = (obj: any, seenObjects = []) => {
  obj = deepClone(obj);
  const pairs = getKeyValuePairs(obj);
  pairs.forEach(({key, value}) => {
    const type = typeof value;
    const allowedTypes = ['number', 'string', 'object', 'array'];
    if (primitivesTypes.indexOf(type) < 0) {
      if (seenObjects.indexOf(value) >= 0) {
        console.log('found circular obj', key);
        delete obj[key];
      }
      seenObjects.push(value);
    }
    if (type === 'object') {
      obj[key] = removeCircularReferences(value, seenObjects);
    }
  });
  return obj;
};

export const deepClone = <T>(obj: T, seenObjects = []): T => {
  // if (typeof obj === "object") {
  //     let newObject: any = {};
  //     if (obj instanceof Array) {
  //         newObject = [];
  //     }
  //     const pairs = getKeyValuePairs(obj);

  //     pairs.forEach(({ key, value }) => {
  //         if (value !== undefined && value !== null) {
  //             const type = typeof value;
  //             if (primitivesTypes.indexOf(type) >= 0) {
  //                 newObject[key] = value;
  //             } else if (type === "object") {
  //                 if (seenObjects.indexOf(value) < 0) {
  //                     seenObjects.push(value);
  //                     newObject[key] = deepClone(value, seenObjects);
  //                 }
  //             }
  //         }
  //     })
  //     return newObject;
  // }
  // return obj;
  return jsonClone(obj);
};

export const jsonClone = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const isEqual = (obj1: any, obj2: any, {currentDepth = 1, maxDepth = 100} = {}) => {
  if (currentDepth > maxDepth) {
    return obj1 == obj2;
  }
  if ((!obj1 && obj2) || (!obj2 && obj1)) {
    return false;
  }
  if (!obj1 && !obj2) {
    return true;
  }
  const type1 = typeof obj1;
  const type2 = typeof obj2;
  if (type1 !== type2) {
    return false;
  }
  if (obj1 instanceof Array) {
    if (obj2 instanceof Array) {
      if (obj1.length !== obj2.length) {
        return false;
      }
      if (obj1.length === 0) {
        return true;
      }
      for (let i = 0; i < obj1.length; i++) {
        if (!isEqual(obj1[i], obj2[i], {currentDepth: currentDepth + 1})) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }
  if (type1 === 'object' && type2 === 'object') {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (!isEqual(keys1, keys2, {currentDepth: currentDepth + 1})) {
      return false;
    }
    for (const key of keys1) {
      if (!isEqual(obj1[key], obj2[key], {currentDepth: currentDepth + 1})) {
        return false;
      }
    }
    return true;
  }
  return obj1 === obj2;
};

export const shallowCompare = function (dis: any, nextProp: any, nextState: any, {maxDepth = 1, ignoreList = []} = {}) {
  const thisProps = jsonClone({...dis.props, children: undefined, mainStore: undefined, magentoStore: undefined});
  nextProp = jsonClone({...nextProp, children: undefined, mainStore: undefined, magentoStore: undefined});
  ignoreList.forEach((variableName) => {
    delete thisProps[variableName];
    delete nextProp[variableName];
  });
  const propsDiff = !isEqual(thisProps, nextProp, {maxDepth});
  const stateDiff = !isEqual(dis.state, nextState, {maxDepth});
  return propsDiff || stateDiff;
};
export const shallowEqual = (a, b) => {
  return isEqual(a, b, {maxDepth: 1});
};
const ALLOWED_CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz-';
const escapeMax = 1000;
export const encodeNumber = (num: number) => {
  let remaining = num;
  let loopCount = 0;
  let str = '';
  const length = ALLOWED_CHARS.length;
  while (remaining !== 0 && loopCount < escapeMax) {
    const index = remaining % length;
    remaining = Math.floor(remaining / length);
    str = ALLOWED_CHARS[index] + str;
    loopCount++;
  }
  return str;
};

export const decodeNumber = (str: string) => {
  let accumulator = 0;
  for (let i = 0; i < str.length; i++) {
    const index = ALLOWED_CHARS.indexOf(str[i]);
    const paw = Math.pow(ALLOWED_CHARS.length, str.length - i - 1);
    accumulator += index * paw;
  }
  return accumulator;
};

export const encodeDate = (date: Date = new Date(), {precision = 1} = {}) => {
  const time = date.getTime();
  return encodeNumber(Math.floor(time / precision));
};

export const decodeDate = (encoded: string, {precision = 1} = {}) => {
  return new Date(new Date(decodeNumber(encoded) * precision).getTime());
};

export const shortId = (chars = 6) => {
  const num = Math.floor(Math.floor(Math.random() * Math.pow(ALLOWED_CHARS.length, chars)));
  return encodeNumber(num);
};

export const newUUID = (sections = 6) => {
  let id = '';
  let i = 0;
  for (i; i < sections; i++) {
    id = `${id}${shortId()}`;
  }
  return id;
};
export const toTimeZone = (date: Date, offsetHours: number): Date => {
  // create Date object for current location
  const d = new Date();

  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;

  // create new Date object for different city
  // using supplied offset
  const nd = new Date(utc + 3600000 * offsetHours);

  // return time as a string
  return nd;
};

export const toFixed = (num: number, dec?: number): string => {
  if (num) {
    return num.toFixed(dec);
  } else {
    return '0';
  }
};

export const getValues = <T>(object: {[key: string]: T}): T[] => {
  if (object) {
    return Object.keys(object).map((key) => object[key]);
  } else {
    return [];
  }
};

export const getKeyValuePairs = <T>(object: T): {key: keyof T & string; value: any}[] => {
  if (!object) {
    return [];
  }
  const l = [];
  for (const key in object) {
    l.push({key, value: object[key]});
  }
  return l;
};

export const getCountryFullName = (countryId: string) => {
  const name = getKeyValuePairs(HARD_CODED_COUNTRIES).find(({value}) => value === countryId);
  if (name) {
    return name.key;
  }
  return undefined;
};

export const isShipmentCounty = (country: string) => {
  let found = AVAILABLE_SHIPPING_COUNTRIES.find((c) => c === country);
  if (!found) {
    const countryName = getCountryFullName(country);
    if (countryName) {
      found = AVAILABLE_SHIPPING_COUNTRIES.find((c) => c === countryName);
    }
  }
  if (found) {
    return true;
  }
  return false;
};

export const validateEmail = (email: string): string => {
  // tslint:disable-next-line:max-line-length
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valid = re.test(email.toLowerCase());
  if (!valid) {
    return WD.store.appLocale.customer_web.error.invalid_email;
  }
  return '';
};
export const validatePhoneNumber = (number: string): string => {
  const valid = /^[0-9\(\)\s-+]*$/gm.test(number);
  if (!valid) {
    return 'phone number can only contain numbers, +, - and space';
  }
  return '';
};
export const validatePhoneNumberNoCountry = (number: string): string => {
  const valid = /^[0-9\(\)\s-]*$/gm.test(number);
  if (!valid) {
    return 'phone number can only contain numbers, - and space';
  }
  return '';
};
export const validatePostalCode = (code: string): string => {
  const valid = /^[0-9]*$/gm.test(code);
  if (!valid) {
    return 'postal code can only contain numbers.';
  }
  return '';
};
export const validatePassword = (password: string): string => {
  if (password.length < PASSWORD_MIN_CHARS) {
    return WD.store.appLocale.customer_web.error.password_too_short;
  }
  let classCount = 0;
  if (/[A-Z]/g.test(password)) {
    classCount++;
  }

  if (/[a-z]/g.test(password)) {
    classCount++;
  }

  if (/[0-9]/g.test(password)) {
    classCount++;
  }
  if (/[^a-zA-Z0-9]/g.test(password)) {
    classCount++;
  }
  if (classCount >= PASSWORD_MIN_CLASSES) {
    return '';
  } else {
    return `${WD.store.appLocale.customer_web.error.password_should_contain_1} ${PASSWORD_MIN_CLASSES} ${WD.store.appLocale.customer_web.error.password_should_contain_2}`;
  }
};

export const validateAddress = (address: IAddress): string[] => {
  const errors = [];
  for (const key in address) {
    const value = address[key];
    if (typeof value === 'string') {
      address[key] = value.trim();
    }
  }
  if (!address.firstname) {
    errors.push('firstname is required');
  }
  if (!address.lastname) {
    errors.push('lastname is required');
  }
  if (!address.street || address.street.length <= 0) {
    errors.push('address is required');
  }
  if (!address.city) {
    errors.push('city is required');
  }
  if (!address.country_id) {
    errors.push('country is required');
  }
  if (!address.telephone) {
    errors.push('telephone is required');
  } else if (address.telephone.length <= 4) {
    errors.push('phone number is too short');
  }
  if (!address.postcode) {
    errors.push('postcode is required');
  }
  return errors;
};

export const getShippingCountryCodes = () => {
  const shippingCountries = {};
  AVAILABLE_SHIPPING_COUNTRIES.forEach((c) => {
    shippingCountries[c] = HARD_CODED_COUNTRIES[c];
  });
  return shippingCountries;
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(() => resolve(), ms));

export const count = <T>(list: T[], item: T): number => {
  let count = 0;
  list.forEach((i) => {
    if (i === item) {
      count++;
    }
  });
  return count;
};

export const removeRepeated = (list: any[]) => {
  const newList = [];
  list.forEach((item) => {
    if (!newList.find((i) => i === item)) {
      newList.push(item);
    }
  });
  return newList;
};

export const hash = (str: string) => {
  return (
    str.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0) + ''
  );
};

export const removeFromArray = <T>(array: T[], item: T): T[] => {
  const index = array.indexOf(item);
  if (index >= 0) {
    array.splice(index, 1);
  }
  return array;
};

export const areAttributesInObject = (object: any, attributes: string[]) => {
  for (const key of attributes) {
    if (!object[key]) {
      return false;
    }
  }
  return true;
};

export const removeEmptyValues = (object: any) => {
  const newObj: any = {};
  for (const {key, value} of getKeyValuePairs(object)) {
    if (value) {
      let shouldAdd = true;
      if (typeof value === 'object' && Object.keys(value).length <= 0) {
        shouldAdd = false;
      }
      if (shouldAdd) {
        newObj[key] = value;
      }
    }
  }
  return newObj;
};

export const SORT_OPTIONS = {
  price: 'price_low_to_high',
  '-price': 'price_high_to_low',
  name: 'name_a_z',
  '-name': 'name_z_a',
  '-date': 'new_arrivals',
  '-updated_date': 'recently_updated',
};

export const getSortOptionList = () => {
  return getKeyValuePairs(SORT_OPTIONS).map(({key, value}) => ({display: value, key}));
};

export const removeQuotes = (str: string): string => {
  if (typeof str === 'string') {
    str = str.trim();
    if (str.startsWith('"') && str.endsWith('"')) {
      return str.substring(1, str.length - 1);
    }
  }
  return str;
};

export const removePunctuation = (str = '') => {
  return str.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\?]/g, '');
};

export const removeStopWords = (str: string): string => {
  const punctuation = [',', '\\:', '\\(', '\\)', '\\[', '\\]', '\\.', '\\-'];
  for (const word of punctuation) {
    const regex = new RegExp(`${word}`, 'g');
    str = str.replace(regex, ' ');
  }
  str = str.toLowerCase();
  const stopWords = [
    'color',
    'colour',
    'a',
    "a's",
    'able',
    'about',
    'above',
    'according',
    'accordingly',
    'across',
    'actually',
    'after',
    'afterwards',
    'again',
    'against',
    "ain't",
    'all',
    'allow',
    'allows',
    'almost',
    'alone',
    'along',
    'already',
    'also',
    'although',
    'always',
    'am',
    'among',
    'amongst',
    'an',
    'and',
    'another',
    'any',
    'anybody',
    'anyhow',
    'anyone',
    'anything',
    'anyway',
    'anyways',
    'anywhere',
    'apart',
    'appear',
    'appreciate',
    'appropriate',
    'are',
    "aren't",
    'around',
    'as',
    'aside',
    'ask',
    'asking',
    'associated',
    'at',
    'available',
    'away',
    'awfully',
    'b',
    'be',
    'became',
    'because',
    'become',
    'becomes',
    'becoming',
    'been',
    'before',
    'beforehand',
    'behind',
    'being',
    'believe',
    'below',
    'beside',
    'besides',
    'best',
    'better',
    'between',
    'beyond',
    'both',
    'brief',
    'but',
    'by',
    'c',
    "c'mon",
    "c's",
    'came',
    'can',
    "can't",
    'cannot',
    'cant',
    'cause',
    'causes',
    'certain',
    'certainly',
    'changes',
    'clearly',
    'co',
    'com',
    'come',
    'comes',
    'concerning',
    'consequently',
    'consider',
    'considering',
    'contain',
    'containing',
    'contains',
    'corresponding',
    'could',
    "couldn't",
    'course',
    'currently',
    'd',
    'definitely',
    'described',
    'despite',
    'did',
    "didn't",
    'different',
    'do',
    'does',
    "doesn't",
    'doing',
    "don't",
    'done',
    'down',
    'downwards',
    'during',
    'e',
    'each',
    'edu',
    'eg',
    'eight',
    'either',
    'else',
    'elsewhere',
    'enough',
    'entirely',
    'especially',
    'et',
    'etc',
    'even',
    'ever',
    'every',
    'everybody',
    'everyone',
    'everything',
    'everywhere',
    'ex',
    'exactly',
    'example',
    'except',
    'f',
    'far',
    'few',
    'fifth',
    'first',
    'five',
    'followed',
    'following',
    'follows',
    'for',
    'former',
    'formerly',
    'forth',
    'four',
    'from',
    'further',
    'furthermore',
    'g',
    'get',
    'gets',
    'getting',
    'given',
    'gives',
    'go',
    'goes',
    'going',
    'gone',
    'got',
    'gotten',
    'greetings',
    'h',
    'had',
    "hadn't",
    'happens',
    'hardly',
    'has',
    "hasn't",
    'have',
    "haven't",
    'having',
    'he',
    "he's",
    'hello',
    'help',
    'hence',
    'her',
    'here',
    "here's",
    'hereafter',
    'hereby',
    'herein',
    'hereupon',
    'hers',
    'herself',
    'hi',
    'him',
    'himself',
    'his',
    'hither',
    'hopefully',
    'how',
    'howbeit',
    'however',
    'i',
    "i'd",
    "i'll",
    "i'm",
    "i've",
    'ie',
    'if',
    'ignored',
    'immediate',
    'in',
    'inasmuch',
    'inc',
    'indeed',
    'indicate',
    'indicated',
    'indicates',
    'inner',
    'insofar',
    'instead',
    'into',
    'inward',
    'is',
    "isn't",
    'it',
    "it'd",
    "it'll",
    "it's",
    'its',
    'itself',
    'j',
    'just',
    'k',
    'keep',
    'keeps',
    'kept',
    'know',
    'known',
    'knows',
    'l',
    'last',
    'lately',
    'later',
    'latter',
    'latterly',
    'least',
    'less',
    'lest',
    'let',
    "let's",
    'like',
    'liked',
    'likely',
    'little',
    'look',
    'looking',
    'looks',
    'ltd',
    'm',
    'mainly',
    'many',
    'may',
    'maybe',
    'me',
    'mean',
    'meanwhile',
    'merely',
    'might',
    'more',
    'moreover',
    'most',
    'mostly',
    'much',
    'must',
    'my',
    'myself',
    'n',
    'name',
    'namely',
    'nd',
    'near',
    'nearly',
    'necessary',
    'need',
    'needs',
    'neither',
    'never',
    'nevertheless',
    'new',
    'next',
    'nine',
    'no',
    'nobody',
    'non',
    'none',
    'noone',
    'nor',
    'normally',
    'not',
    'nothing',
    'novel',
    'now',
    'nowhere',
    'o',
    'obviously',
    'of',
    'off',
    'often',
    'oh',
    'ok',
    'okay',
    'old',
    'on',
    'once',
    'one',
    'ones',
    'only',
    'onto',
    'or',
    'other',
    'others',
    'otherwise',
    'ought',
    'our',
    'ours',
    'ourselves',
    'out',
    'outside',
    'over',
    'overall',
    'own',
    'p',
    'particular',
    'particularly',
    'per',
    'perhaps',
    'placed',
    'please',
    'plus',
    'possible',
    'presumably',
    'probably',
    'provides',
    'q',
    'que',
    'quite',
    'qv',
    'r',
    'rather',
    'rd',
    're',
    'really',
    'reasonably',
    'regarding',
    'regardless',
    'regards',
    'relatively',
    'respectively',
    'right',
    's',
    'said',
    'same',
    'saw',
    'say',
    'saying',
    'says',
    'second',
    'secondly',
    'see',
    'seeing',
    'seem',
    'seemed',
    'seeming',
    'seems',
    'seen',
    'self',
    'selves',
    'sensible',
    'sent',
    'serious',
    'seriously',
    'seven',
    'several',
    'shall',
    'she',
    'should',
    "shouldn't",
    'since',
    'six',
    'so',
    'some',
    'somebody',
    'somehow',
    'someone',
    'something',
    'sometime',
    'sometimes',
    'somewhat',
    'somewhere',
    'soon',
    'sorry',
    'specified',
    'specify',
    'specifying',
    'still',
    'sub',
    'such',
    'sup',
    'sure',
    't',
    "t's",
    'take',
    'taken',
    'tell',
    'tends',
    'th',
    'than',
    'thank',
    'thanks',
    'thanx',
    'that',
    "that's",
    'thats',
    'the',
    'their',
    'theirs',
    'them',
    'themselves',
    'then',
    'thence',
    'there',
    "there's",
    'thereafter',
    'thereby',
    'therefore',
    'therein',
    'theres',
    'thereupon',
    'these',
    'they',
    "they'd",
    "they'll",
    "they're",
    "they've",
    'think',
    'third',
    'this',
    'thorough',
    'thoroughly',
    'those',
    'though',
    'three',
    'through',
    'throughout',
    'thru',
    'thus',
    'to',
    'together',
    'too',
    'took',
    'toward',
    'towards',
    'tried',
    'tries',
    'truly',
    'try',
    'trying',
    'twice',
    'two',
    'u',
    'un',
    'under',
    'unfortunately',
    'unless',
    'unlikely',
    'until',
    'unto',
    'up',
    'upon',
    'us',
    'use',
    'used',
    'useful',
    'uses',
    'using',
    'usually',
    'uucp',
    'v',
    'value',
    'various',
    'very',
    'via',
    'viz',
    'vs',
    'w',
    'want',
    'wants',
    'was',
    "wasn't",
    'way',
    'we',
    "we'd",
    "we'll",
    "we're",
    "we've",
    'welcome',
    'well',
    'went',
    'were',
    "weren't",
    'what',
    "what's",
    'whatever',
    'when',
    'whence',
    'whenever',
    'where',
    "where's",
    'whereafter',
    'whereas',
    'whereby',
    'wherein',
    'whereupon',
    'wherever',
    'whether',
    'which',
    'while',
    'whither',
    'who',
    "who's",
    'whoever',
    'whole',
    'whom',
    'whose',
    'why',
    'will',
    'willing',
    'wish',
    'with',
    'within',
    'without',
    "won't",
    'wonder',
    'would',
    "wouldn't",
    'x',
    'y',
    'yes',
    'yet',
    'you',
    "you'd",
    "you'll",
    "you're",
    "you've",
    'your',
    'yours',
    'yourself',
    'yourselves',
    'z',
    'zero',
    'forget',
    'accompany',
    'perfect',
    'make',
    'great',
    'favourite',
    'excellent',
    'lovely',
    'kick',
    'prefer',
    'wrong',
    'back',
    'makes',
    'stunning',
    'and',
  ];
  // ["an", "with", "very", "intense", "it", "and", "but", "is", "are", "was", "of"]
  let i = 0;
  const wordCount = stopWords.length;
  let word = undefined;
  for (i; i < wordCount; i++) {
    word = stopWords[i];
    const regex = new RegExp(`\\b${word}\\b`, 'g');
    str = str.replace(regex, ' ');
  }
  str = str.replace(/[\s][\s]/gm, ' ');
  return str;
};

export const currencyToSymbol = (str: 'SGD' | string = 'SGD') => {
  if (str === 'SGD') {
    return 'S$';
  } else {
    return '$';
  }
};

export const getGlobalSearchFilterPriceRange = () => {
  const price = WD.store.globalSearchOptions.price;
  let minPrice = 0;
  let maxPrice = MAX_WINE_PRICE;
  if (price) {
    const l = price.split('-');
    minPrice = Number.parseInt(l[0]);
    maxPrice = Number.parseInt(l[1]);
  }
  return {minPrice, maxPrice};
};

export const toJsonString = (obj, ...args) => {
  try {
    return JSON.stringify(obj, ...args);
  } catch (e) {
    console.log('Failed to convert to JSON string: ');
    throw {error: e, obj: args};
  }
};

export const getRegexMatches = (reg: RegExp, str: string): string[] => {
  const match = reg.exec(str);
  if (match) {
    match.shift();
    return match;
  }
  return [];
};

export const getFirstRegexMatch = (regex: RegExp, str: string): string => {
  const matches = getRegexMatches(regex, str);
  if (matches.length > 0) {
    return matches[0];
  }
  return null;
};

export const isEqualIgnoreCases = (a: string, b: string): boolean => {
  if ((a && !b) || (!a && b)) {
    return false;
  }
  if (!a && !b) {
    return true;
  }
  return a.toLowerCase() === b.toLowerCase();
};

export const containsIgnoreCases = (a: string, b: string): boolean => {
  if (!b) {
    b = '';
  }
  if (!a) {
    return false;
  }
  return a.toLowerCase().indexOf(b.toLowerCase()) >= 0;
};

export const getCountryAreaCodeByShortName = (name: string) => {
  return COUNTRY_AREA_CODE[name];
};

export const hydrateStore = async (targetStore: IMagentoStore, dataStore: IMagentoStore) => {
  Logger.logInfo('HYDRATE_STORE', 'HYDRATING STORE');
  delete dataStore.threadHandler;
  delete dataStore.cacheHandler;
  delete dataStore.throwableResponse;
  delete dataStore.service;
  delete dataStore.isLoading;
  delete dataStore.processState;
  delete dataStore.restProcesses;
  if (dataStore.itemMap) {
    targetStore.itemMap = WD.mergeItemMap(dataStore.itemMap, targetStore.itemMap);
  }
  delete dataStore.itemMap;

  if (dataStore.gifts) {
    targetStore.gifts = WD.addToList(
      dataStore.gifts.map((i) => i.data),
      [],
    );
  }
  if (WD.store && WD.store.customer) {
    delete dataStore.personalRecommendations;
  }
  delete dataStore.gifts;
  Object.assign(targetStore, dataStore);
};
export const timeFunction = (f: Function, str: string) => {
  const timer = new Timer(str);
  f();
  timer.print();
};

export const parseDate = (dateString: string) => {
  return new Date(dateString.replace(/-/g, '/'));
};

export const getSGTime = () => {
  const d = new Date();
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  return moment(new Date(utc + 3600000 * 8)).toDate();
};

export const convertBasedOnSGTime = (date: string) => {
  const d = new Date(date);
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;
  return new Date(utc + 3600000 * 8);
};

export const formatToMagentoDate = (date: Date) => {
  return date ? `${date.getFullYear()}-${formatNumber(date.getMonth() + 1, 2)}-${formatNumber(date.getDate(), 2)}` : '';
};

export const getAvailableTimeSlots = (date: Date) => {
  if (WD.store.blockedDeliveryDates === undefined || WD.store.blockedDeliveryTimeSlots === undefined) {
    console.log('timeSlots empty');
    return [];
  }
  const dateString = formatToMagentoDate(date);
  const blocked = WD.store.blockedDeliveryTimeSlots.filter((b) => b.date === dateString).map((b) => b.time_slot);
  const timeSlots = WD.store.timeSlots.filter((slot) => blocked.indexOf(slot) < 0);
  console.log('timeSlots', {timeSlots, blocked, dateString});
  return timeSlots;
};

export const formatNumber = (n: number, targetLength: number) => {
  let output = n + '';
  while (output.length < targetLength) {
    output = '0' + output;
  }
  return output;
};

export const getBlockedDeliveryDates = () => {
  return WD.store.blockedDeliveryDates.map((d) => new Date(d));
};

export const formatTimePassed = (diff: number) => {
  return new Date(new Date('2017-05-02T00:00').getTime() + diff).toLocaleTimeString();
};

export const isGift = (item: {sku: string}) => {
  return item.sku.indexOf('Gift') >= 0;
};

export const randomFromList = <T>(list: T[]): T => {
  return list[Math.floor(Math.random() * list.length)];
};

export const getDayOfWeek = (date: Date) => {
  return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
};

export const getDaysInBetween = (date1: Date, date2: Date) => {
  date1.setHours(0);
  date1.setMinutes(0);
  date2.setHours(0);
  date2.setMinutes(0);
  const diff = Math.abs(date1.getTime() - date2.getTime());
  return Math.ceil(diff / 1000 / 60 / 60 / 24);
};

export const getPriceRange = (price: number) => {
  if (price < 50) {
    return 'Below $50';
  }
  if (price <= 100) {
    return '$50 - $100';
  }
  if (price <= 200) {
    return '$100 - $200';
  }
  if (price <= 400) {
    return '$200 - $400';
  }
  if (price > 400) {
    return 'Over  $400';
  }
  return 'UNKNOWN';
};

type AllCustomAttributes = CustomerCustomAttribute | WineCustomAttributeType;
interface ICustomAttributeObject {
  custom_attributes?: ICustomAttribute[];
}

export const getCustomAttribute = (obj: ICustomAttributeObject, attributeName: AllCustomAttributes) => {
  if (obj.custom_attributes) {
    const attribute = obj.custom_attributes.find((a) => a.attribute_code === attributeName);
    if (attribute) {
      return attribute.value;
    }
  }
  return '';
};

export const getCustomAttributeBoolean = (obj: ICustomAttributeObject, attributeName: AllCustomAttributes) => {
  const str = getCustomAttribute(obj, attributeName) as any;
  if (str === '1' || str === 1) {
    return true;
  }
  return false;
};

export const setCustomAttribute = (obj: ICustomAttributeObject, attributeName: AllCustomAttributes, value: string) => {
  if (!obj.custom_attributes) {
    obj.custom_attributes = [];
  }
  obj.custom_attributes = obj.custom_attributes.filter((i) => i.attribute_code !== attributeName);
  obj.custom_attributes.push({attribute_code: attributeName, value});
};
export const doAllAsync = (...tasks: (() => Promise<any>)[]) => {
  const promises: Promise<any>[] = [];
  tasks.forEach((task) => {
    promises.push(
      (async () => {
        try {
          return await task();
        } catch (e) {
          return e;
        }
      })(),
    );
  });
  return Promise.all(promises);
};
export const escapeRegex = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const capitalize = (str: string) => {
  return str.toLowerCase().replace(/(^|\s)\S/g, (l) => l.toUpperCase());
};
export const getUrlDomain = (url: string) => {
  const matches = /http[s]*:\/\/(.*?)\//gm.exec(url);
  if (matches) {
    const [_, domain] = matches;
    return domain;
  }
  return url;
};
export const splitListToChunks = (list: any[], chunkSize: number) => {
  const chunks: any[][] = [];
  const count = 0;
  while (list.length > chunkSize && count < 1000) {
    const chunk = list.splice(0, chunkSize);
    chunks.push(chunk);
  }
  chunks.push(list);
  return chunks;
};

export const containsNonEnglish = (str: string) => {
  const newString = convertiOSSmartPunctuation(str);
  return /[^a-z0-9_.,\-!\?:;"'@#%^&*()${}[]<>~`\s]/i.test(newString);
};
export const containsEmoticons = (str: string) => {
  return /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g.test(
    str,
  );
};
const MEMORIZED: any = {};
export const memorize = <T>(key: string, f: () => T): T => {
  const memorized = MEMORIZED[key];
  if (memorized) return memorized;
  const result = f();
  MEMORIZED[key] = result;
  return result;
};
export const sortAscending = (a: string | number, b: string | number) => {
  return a > b ? 1 : 0;
};
export const sortStringAscending = (a: string, b: string) => {
  return ('' + a).localeCompare('' + b);
};

export const decodeChar = (char: string | string[]): any => {
  const entities = new XmlEntities();
  if (Array.isArray(char)) {
    return char.map((x) => entities.decode(x));
  } else {
    return entities.decode(char);
  }
};

export const flattenArray = (arr: any[]) => {
  const flatted = arr.reduce((prev, curr) => prev.concat(curr), []);
  return flatted;
};

export const convertiOSSmartPunctuation = (str: string): string => {
  return str.replace(/[\u2018\u2019\u201C\u201D]/g, (c) => '\'\'""'.substr('\u2018\u2019\u201C\u201D'.indexOf(c), 1));
};

export const findBadWords = (sentence: string): string => {
  const badWords = BAD_WORDS.split(',');
  const result = [];
  for (let i = 0; i < badWords.length; i++) {
    const word = badWords[i];
    const re = new RegExp(`\\b${word}\\b`, 'g');
    if (sentence.toLowerCase().match(re)) {
      result.push(word);
    }
  }
  return result.join(', ');
};

export const removeBadWordMessage = (): string => {
  return `This message contains prohibited language. Please remove obscene words.`;
};

export const isSameDay = (date: Date, earliestDate: Date): boolean => {
  if (
    date.getFullYear() === earliestDate.getFullYear() &&
    date.getMonth() === earliestDate.getMonth() &&
    date.getDate() === earliestDate.getDate()
  ) {
    return true;
  } else {
    return false;
  }
};

export const chunkSubstr = (str: string, size: number) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

type SearchFilterProps = {
  id: string;
  display: any;
  data: any;
};
export const getPrioritySearchFilter = (type: 'grapes' | 'country', data: SearchFilterProps[]) => {
  let filteredItems = jsonClone(data);
  const priorityItems = filteredItems.filter((i) => SearchFilterPriority[type].indexOf(decodeChar(i.display)) !== -1);
  const sortedArray = priorityItems.sort(
    (a, b) =>
      SearchFilterPriority[type].indexOf(decodeChar(a.display)) -
      SearchFilterPriority[type].indexOf(decodeChar(b.display)),
  );
  filteredItems = [
    ...sortedArray,
    ...filteredItems.filter((i) => SearchFilterPriority[type].indexOf(decodeChar(i.display)) === -1),
  ];

  return filteredItems;
};

export const checkAllDataComplete = (data: IAddress) => {
  return (
    data.firstname &&
    data.lastname &&
    data.telephone &&
    data.telephone.length > 5 &&
    data.city &&
    data.country_id &&
    data.postcode &&
    data.street &&
    data.street.length > 0 &&
    data.street[0] !== ''
  );
};
