import {ProductActions, WD, Wine, CustomerActions} from '..';
import {deepClone} from '../Helper/Helper';

export class WishListActions {
  static async addToWishList(sku: string) {
    const undo = deepClone(WD.store.wishList);
    try {
      console.log([sku, ...undo]);
      WD.store.wishList = [sku, ...undo];
      const result = await WD.store.service.addToWishList(sku);
      if (!result.status) {
        // await WD.store.refreshWishList();
        WD.store.wishList = undo;
      }
      CustomerActions.sendSommelierOnPurchaseEvent([sku]);
    } catch (e) {
      WD.store.wishList = undo;
      WD.store.throwableResponse.onError(e + '');
    }
  }
  static async removeFromWishList(sku: string) {
    const undo = deepClone(WD.store.wishList);
    try {
      WD.store.wishList = WD.store.wishList.filter((i) => i !== sku);
      const result = await WD.store.service.removeFromWishList(sku);
      if (!result.status) {
        // await WD.store.refreshWishList();
        WD.store.wishList = undo;
      }
    } catch (e) {
      WD.store.wishList = undo;
      console.log(e);
    }
  }
  static async refresh() {
    const result = await WD.store.service.getWhishList();
    await ProductActions.addToAllItems(result.items);
    WD.store.wishList = result.items.map((i) => i.sku).reverse();
  }
}
