import {Wine, getWebBaseUrl, IRecommendation, getFirstRegexMatch, Logger} from '..';
import {getFacebookAppId} from '../Config';

export const removeAccents = (strAccents) => {
  if (!strAccents) {
    return strAccents;
  }
  var strAccents = strAccents.split('');
  let strAccentsOut: any = [];
  const strAccentsLen = strAccents.length;
  const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
  for (let y = 0; y < strAccentsLen; y++) {
    if (accents.indexOf(strAccents[y]) != -1) {
      strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
    } else strAccentsOut[y] = strAccents[y];
  }
  strAccentsOut = strAccentsOut.join('');
  return strAccentsOut;
};

export const removeIllegalUrlChars = (str: string) => {
  return encodeURI(
    removeAccents(str)
      .replace(/[\s]/g, '-')
      .replace(/[^a-z^A-Z^\-^\/^0-9]*/g, ''),
  );
};

export const getWineUrl = (wine: Wine, {full = false} = {}) => {
  return `${full ? getWebBaseUrl() : '/'}product/${wine.getSku()}/${removeIllegalUrlChars(wine.getName())}`;
};
export const getWineUrlBySku = (sku: string, {full = false} = {}) => {
  return `${full ? getWebBaseUrl() : '/'}product/${sku}/`;
};
export const getWinePageTitle = (wine: Wine) => {
  return `${wine.getNameAndYear()} | Wine.Delivery`;
};
export const getOrderPage = (orderId: string, {full = false} = {}) => {
  return `${full ? getWebBaseUrl() : '/'}order/${orderId}`;
};

export const getWinePageShareMessage = (wine: Wine, {noUrl = false} = {}) => {
  return noUrl ? '' : getWineUrl(wine, {full: true});
};

export const getFacebookShareLink = (url: string) => {
  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
};

export const getTwitterShareLink = (url: string, {message}) => {
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}&original_referer=${encodeURIComponent(
    'https://wine.delivery',
  )}`;
};

export const getMessengerShareLink = (url: string) => {
  return `https://www.facebook.com/dialog/send?app_id=${getFacebookAppId()}&link=${encodeURIComponent(
    url,
  )}&redirect_uri=${encodeURIComponent(url)}`;
};

export const getEmailShareLink = ({title, message}) => {
  return `mailto:?subject=${encodeURIComponent(title)}&body=${message}`;
};

export const getRecommendationPageTitle = (name: string) => {
  return `${name} | Wine.Delivery Singapore`;
};

export const getRecommendationUrl = (id: any, name: string, {full = true} = {}) => {
  return `${full ? getWebBaseUrl() : '/'}recommendations/${id}/${encodeURIComponent(removeIllegalUrlChars(name))}`;
};

type UrlType = 'product' | 'recommendation' | 'other' | 'cart' | 'order' | 'sale';
export const getUrlMeta = (url: string): {type: UrlType; id?: string} => {
  const productSku = getFirstRegexMatch(/product\/(.*?)\//gm, url);
  if (productSku) {
    return {type: 'product', id: productSku};
  }
  const recommendationId = getFirstRegexMatch(/recommendations\/(.*?)\//gm, url);
  if (recommendationId) {
    return {type: 'recommendation', id: recommendationId};
  }
  if (url.indexOf('/cart') >= 0) {
    return {type: 'cart'};
  }
  const orderId = getFirstRegexMatch(/order\/(.*)/gm, url);
  if (orderId) {
    return {type: 'order', id: orderId};
  }
  if (url.indexOf('/Wines-On-Sale') >= 0) {
    return {type: 'sale'};
  }
  return {type: 'other'};
};
