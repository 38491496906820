import {ICombinedProductAttributes, IProductAttribute} from '../Interface/ICombinedProductAttributes';

export class ProductAttributeMap {
  [id: string]: string;

  constructor(attributes: IProductAttribute[]) {
    attributes.map((att) => {
      this[att.id] = att.name;
    });
  }
}
