export class Logger {
  static loggingDisabled = false;
  static disableStyle = false;

  static setDisableStyle(boo = true) {
    this.disableStyle = boo;
  }
  static disableLogging() {
    this.loggingDisabled = true;
  }
  static log(...args) {
    if (this.loggingDisabled === false) {
      this.logToWindow(...args);
      console.log(...args);
    }
  }
  private static logToWindow(...args) {
    // if (!this.disableStyle) {
    //     args = args.slice(1, args.length);
    // }
    //@ts-ignore
    if (window) {
      //@ts-ignore
      if (!window.WD) {
        //@ts-ignore
        window.WD = {};
      }
      //@ts-ignore
      if (!window.WD.logList) {
        //@ts-ignore
        window.WD.logList = [];
      }
      //@ts-ignore
      const logList = window.WD.logList;
      logList.push([...args]);
    }
  }
  static logSystem(
    options: {
      title: string;
      subtitle: string;
      color: string;
      backgroundColor: string;
    },
    ...args
  ) {
    if (!this.disableStyle) {
      options = {
        title: 'UNKNOWN',
        subtitle: undefined,
        color: 'black',
        backgroundColor: 'yellow',
        ...options,
      };
      this.log(
        `%c${options.title}[${options.subtitle}]`,
        `background-color: ${options.backgroundColor}; color: ${options.color};`,
        ...args,
      );
    } else {
      this.log(`${options.title}[${options.subtitle}]`, ...args);
    }
  }
  static logSuccess(title: string, subtitle: string, ...args) {
    this.logSystem(
      {
        title,
        subtitle,
        color: 'white',
        backgroundColor: 'green',
      },
      ...args,
    );
  }

  static logError(title: string, subtitle: string, ...args) {
    this.logSystem(
      {
        title,
        subtitle,
        color: 'white',
        backgroundColor: 'red',
      },
      ...args,
    );
  }

  static logInfo(title: string, subtitle: string, ...args) {
    this.logSystem(
      {
        title,
        subtitle,
        color: 'black',
        backgroundColor: 'yellow',
      },
      ...args,
    );
  }
}
