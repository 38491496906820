import {Cart} from './Cart';
import {WD} from '../Helper/WD';
import {InCartItem, ICartInfo} from '../Interface/ICartInfo';
import {IStandardResponse} from '../Interface/IStandardResponse';
import {IInCartItemTotal, ICartTotal} from '../Interface/ICartTotal';
import {IShipmentInformation} from '../Interface/IShipmentInformation';
import {ISetShipmentInformationResponse} from '../Interface/ISetShipmentInformationResponse';
import {IPaymentInformation} from '../Interface/IPaymentInformation';
import {isGift} from '..';
import {formatToMagentoDate} from '../Helper/Helper';

export class CustomerCart extends Cart {
  constructor() {
    super();
  }
  async addGift(sku: string, message: string) {
    const r = await WD.store.service.addToCustomerCart(WD.store.cartInfo.id, sku, 1, message);
    return r;
  }
  async add(sku: string, amount: number): Promise<InCartItem & IStandardResponse> {
    const r = await WD.store.service.addToCustomerCart(WD.store.cartInfo.id, sku, amount);
    return r;
  }
  async getCartID(): Promise<string> {
    return WD.store.cartInfo.id + '';
  }
  async remove(itemId: number) {
    const r = await WD.store.service.removeFromCustomerCart(itemId);
    return r;
  }
  async removeAll(items: IInCartItemTotal[]) {
    const promises: Promise<any>[] = items.map((i) => WD.store.service.removeFromCustomerCart(i.item_id));
    const r = await Promise.all(promises);
    return r;
  }
  async getCart() {
    let cart: ICartInfo;
    try {
      cart = await WD.store.service.getCustomerCart();
    } catch (e) {
      await WD.store.service.createCustomerCart();
      cart = await WD.store.service.getCustomerCart();
    }
    if (cart.items.find((i) => isGift(i))) {
      cart.items = await WD.store.service.getCustomerCartItems();
    }
    return cart;
  }
  async createNewCart() {
    return WD.store.service.createCustomerCart();
  }
  getCartTotal(): Promise<ICartTotal> {
    if (WD.getUserSelectedDeliveryDate() && WD.getUserSelectedDeliveryTime()) {
      return WD.store.service.getCustomerCartTotal(
        formatToMagentoDate(WD.getUserSelectedDeliveryDate()),
        WD.getUserSelectedDeliveryTime(),
      );
    }
    return WD.store.service.getCustomerCartTotal();
  }
  async setShipmentDetails(info: IShipmentInformation): Promise<ISetShipmentInformationResponse> {
    const r = await WD.store.service.setCustomerCartShipmentInformation(info);
    return r;
  }
  // order(info: IPaymentInformation): Promise<any> {
  //     return WD.store.service.customerCartOrder(info);
  // }
  async setPaymentInformation(info: IPaymentInformation): Promise<any> {
    const r = await WD.store.service.setCustomerPaymentInformation(info);
    return r;
  }
  async setCoupon(couponCode: string): Promise<any> {
    const r = await WD.store.service.setCustomerCartCoupon(couponCode);
    return r;
  }
  async updateItem(inCartItem: InCartItem): Promise<any> {
    const r = await WD.store.service.updateCustomerCartItem(inCartItem);
    return r;
  }
  async removeCoupon() {
    const r = await WD.store.service.removeCustomerCartCoupon();
    return r;
  }
  async getPaymentInformation() {
    return WD.store.service.getCustomerPaymentInformation();
  }
  async generatePayPalPayment() {
    return WD.store.service.generateCustomerPayPalPayment(WD.store.cartInfo.id);
  }
}
