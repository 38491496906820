import React from 'react';
import { useLocation } from 'react-router-dom';
import { AnalyticHandler, getWebBaseUrl, IMagentoStore } from '@wine-delivery/wd-shared-lib';
import queryString from 'query-string';
import { isBrowser } from './CommonHelper';
import { AdminStore } from '../Store/AdminStore';

export function navigateTo(store: IMagentoStore | AdminStore, history: any, path: string, yPosition = 0): void {
  setCurrentPath(store, path);
  AnalyticHandler.onPageView(path);
  history.push(path);
}

export function navigateBack(store: IMagentoStore, history: any): void {
  // store.navigation.setIsBack(true);
  history.goBack();
}

export function setCurrentPath(store: IMagentoStore | AdminStore, path: string): void {
  // store.navigation.setCurrentPage(path);
}

export function setCurrentYPosition(store: IMagentoStore, yPosition: number): void {
  // store.UI.lastYPosition = yPosition;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getUrlQueryString(history: any) {
  return queryString.parse(history.location.search);
}

export function setUrlQueryStrings(history: any, params: any) {
  const loc = history.location;
  setUrl(history, loc.pathname + `?${queryString.stringify(params)}`);
}

export function setUrlQueryString(history: any, newKey: string, newValue: any) {
  if (window && window.location) {
    let queries = queryString.parse(history.location.search);
    if (newValue !== '') {
      queries = {
        ...queries,
        [newKey]: newValue,
      };
      console.log(`Setting url query string ${queryString.stringify(queries)}`);
      setUrlQueryStrings(history, queries);
    }
  }
}

export function setUrl(history: any, urlWithQuery: string) {
  history.replace(urlWithQuery);
  AnalyticHandler.onPageView(urlWithQuery);
}

export function getCurrentQueryString(history: any) {
  if (window && window.location) {
    const q = window.location.search;
    if (q) {
      if (q.startsWith('?')) {
        return q.substr(1, q.length);
      }
      return window.location.search;
    }
    return '';
  }
  return '';
}

export function toQueryString(obj: any) {
  return queryString.stringify(obj);
}

export const NO_SIDEBAR_URL = ['/cart', '/checkout', '/payment-method', '/order-summary'];

export const NO_TOPBAR_URL = ['/cart', '/checkout', '/payment-method', '/order-summary'];

export const NO_PROMOTION_URL = ['/cart', '/checkout', '/payment-method', '/order-summary'];

export const NO_FOOTER_URL = ['/cart', '/checkout', '/payment-method', '/order-summary'];

export const CHECKOUT_PAGE_STEP = [
  {
    path: '/cart',
    title: 'Shopping Cart',
    backTitle: 'Back To Store',
    backPath: '/',
    activeNumber: 1,
  },
  {
    path: '/checkout',
    title: 'Delivery & Billing',
    backTitle: 'Back To Cart',
    backPath: '/cart',
    activeNumber: 2,
  },
  {
    path: '/payment-method',
    title: 'Payment',
    backTitle: 'Back To Delivery & Billing',
    backPath: '/checkout',
    activeNumber: 3,
  },
  {
    path: '/order-summary',
    title: 'Purchase Completed',
    backTitle: '',
    backPath: '/',
    activeNumber: 4,
  },
];

export const TRANSPARENT_TOPBAR_URL = {
  path: [
    '/',
    '//',
    '/home',
    '/index.html',
    '/New-Arrival-Wines',
    '/Wines-On-Sale',
    '/Express-Wines',
    '/Picked-For-You',
    '/profile',
    '/profile/:tab',
    '/about',
    '/faq',
    '/fine-print',
    '/privacy',
    '/cookie',
    '/terms',
    '/refund',
    '/other-info',
    '/customer/account/accountDeletion',
    '/customer/account/confirmAccountDeletion',
    '/saved',
    // '/contact',
  ],
  exact: true,
};

export const NO_TOP_PROMOTION_MOBILE_URL = {
  path: [
    '/product/:sku/:name',
    '/product/:sku',
    '/profile',
    '/profile/:tab',
    '/about',
    '/fine-print',
    '/contact',
    '/faq',
    '/privacy',
    '/cookie',
    '/terms',
    '/refund',
    '/other-info',
    '/customer/account/accountDeletion',
    '/customer/account/confirmAccountDeletion',
    '/saved',
  ],
  exact: true,
};

export const NO_TOP_BAR_MOBILE_URL = { path: ['/profile'], exact: true };

export const NO_TOP_SEARCH_MOBILE_URL = {
  path: [
    '/profile/:tab',
    '/about',
    '/fine-print',
    '/contact',
    '/faq',
    '/privacy',
    '/cookie',
    '/terms',
    '/refund',
    '/other-info',
    '/customer/account/accountDeletion',
    '/customer/account/confirmAccountDeletion',
  ],
  exact: true,
};

export const HAS_BACK_BUTTON_MOBILE = {
  path: [
    '/product/:sku/:name',
    '/product/:sku',
    '/profile/:tab',
    '/about',
    '/fine-print',
    '/contact',
    '/faq',
    '/privacy',
    '/cookie',
    '/terms',
    '/refund',
    '/other-info',
    '/customer/account/accountDeletion',
    '/customer/account/confirmAccountDeletion',
  ],
  exact: true,
};

export const OPEN_LOGIN = { path: ['/logIn'], exact: true };

export const OPEN_SIGNUP = { path: ['/signUp'], exact: true };

export const OPEN_FORGOT_PASS_REDIRECT = { path: ['/customer/account/createPassword/'], exact: true };

export const BOTTOM_MENU_EXPLORE_URL = {
  path: [
    '/',
    '//',
    '/home',
    '/New-Arrival-Wines',
    '/Wines-On-Sale',
    '/Express-Wines',
    '/Picked-For-You',
    '/recommendations/:id/:name',
    '/recommendations',
  ],
  exact: true,
};

export const BOTTOM_MENU_ALL_PRODUCTS_URL = {
  path: ['/search', '/Buy-Wine-Online/:subPage', '/Buy-Wine-Online', '/product/:sku/:name', '/product/:sku'],
  exact: true,
};

export const BOTTOM_MENU_SAVED_URL = {
  path: ['/saved'],
  exact: true,
};

export const BOTTOM_MENU_CART_URL = {
  path: ['/cart', '/checkout', '/payment-method', '/order-summary'],
  exact: true,
};

export const BOTTOM_MENU_ACCOUNT_URL = {
  path: [
    '/profile',
    '/profile/:tab',
    '/about',
    '/contact',
    '/faq',
    '/fine-print',
    '/privacy',
    '/cookie',
    '/terms',
    '/refund',
    '/other-info',
    '/login',
    '/signup',
    '/customer/account/createPassword/',
    '/customer/account/accountDeletion',
    '/customer/account/confirmAccountDeletion',
  ],
  exact: true,
};

export function getWebBaseWithCurrentUrl(): string {
  return getWebBaseUrl().replace(/\/$/, '') + getCurrentUrl();
}

export function getFullUrl(): string {
  if (window.location) {
    let url = getCurrentUrl();
    const query = window.location.search;
    if (query) {
      url += `?${query}`;
    }
    return url;
  }
  return '';
}

export function getCurrentUrl(): string {
  if (window.location) {
    return window.location.pathname;
  }
  return 'UNKNOWN-ROUTE';
}

export function getCurrentRoute(): string {
  const location = useLocation();
  if (location) {
    return location.pathname;
  }
  return 'UNKNOWN-ROUTE';
}

export function redirectExternal(url: string, { newTab = false } = {}) {
  if (newTab) {
    window.open(url);
    return;
  }
  if (window && window.location) {
    window.location.replace(url);
  }
}

export function navigateToInternalUrl(store: IMagentoStore, history: any, url: string) {
  if (isBrowser()) {
    url = url.replace(window.location.origin, '');
    if (url.startsWith('http')) {
      redirectExternal(url, { newTab: true });
    } else {
      navigateTo(store, history, url);
    }
  }
}

export const pageRedirection = () => {
  const page = [
    {
      from: 'WD-8186-M31',
      to: 'WD-265050-M31',
    },
  ];
  return page;
};

export const getHashedQueryStrings = () => {
  if (location && location.hash) {
    const str = location.hash.substring(1);
    return queryString.parse(str);
  } else {
    return {};
  }
};

export const refreshPage = () => {
  if (window && window.location) {
    window.location.reload();
  }
};
