import {
  CustomerActions,
  IAddress,
  ICartInfo,
  ICartTotal,
  IInCartItemTotal,
  IPaymentInformation,
  IPayPalPayment,
  IShipmentInformation,
  MagentoItem,
  ProcessType,
  sleep,
  ThreadHandler,
  WD,
} from '..';
import {AnalyticHandler} from '../Handler/AnalyticHandler';
import {
  deepClone,
  getSGTime,
  isSameDay,
  jsonClone,
  convertBasedOnSGTime,
  formatToMagentoDate,
  parseDate,
  getAvailableTimeSlots,
} from '../Helper/Helper';
import {IOrderInformation, IPaymentIntent, IPaymentIntentResult} from '../Interface/IPaymentInformation';
import {ProductActions} from './ProductActions';
import {Logger} from '../Handler/Logger';

export class CartActions {
  static get threadHandler(): ThreadHandler {
    return WD.store.threadHandler;
  }

  static async addItemToCart(sku: string, amount = 1, giftMassage?: string) {
    AnalyticHandler.onEvent({category: 'CART', action: 'ADD', label: sku, value: amount});
    AnalyticHandler.onProductAddToCart(sku, amount);
    await this.threadHandler.runSequential(
      ProcessType.CART,
      async () => {
        while (!(await WD.store.cartInfo)) {
          await sleep(100);
        }
        WD.store.cartInfo = {...WD.store.cartInfo, items_qty: WD.store.cartInfo.items_qty + amount};
        let result;
        if (giftMassage) {
          result = await WD.getCartOperator().addGift(sku, giftMassage);
        } else {
          result = await WD.getCartOperator().add(sku, amount);
        }
      },
      async () => {
        console.log('onLast');
        await CartActions.refresh();
        console.log('onLast done');
      },
    );
    return;
  }
  static async removeItemFromCart(itemId: number) {
    const undo = deepClone(WD.store.cartTotal.items);
    const item = WD.store.cartTotal.items.find((i) => i.item_id === itemId);
    AnalyticHandler.onProductRemoveFromCart(itemId);
    if (item) {
      WD.store.cartInfo = {...WD.store.cartInfo, items_qty: WD.store.cartInfo.items_qty - item.qty};
    }
    WD.store.cartTotal.items = WD.store.cartTotal.items.filter((i) => i.item_id !== itemId);
    WD.store.cartTotal = this.calculateSegmentTotals(WD.store.cartTotal);
    WD.store.cartInfo = {...WD.store.cartInfo, items: WD.store.cartInfo.items.filter((i) => i.item_id !== itemId)};
    await this.threadHandler.runSequential(
      ProcessType.CART,
      async () => {
        try {
          await WD.getCartOperator().remove(itemId);
        } catch (e) {
          console.log(e);
          WD.store.throwableResponse.onError('Failed to remove item from cart');
          WD.store.cartTotal.items = undo;
        }
      },
      async () => {
        await CartActions.refresh();
      },
    );
  }
  public static async changeItemAmountInCart(item: MagentoItem, value: number, itemTotal: IInCartItemTotal) {
    const undo = deepClone(WD.store.cartInfo);
    const sku = item.data.sku;
    const newCartInfo = deepClone(WD.store.cartInfo);
    const newItem = newCartInfo.items.find((i) => i.sku === item.getSku());
    WD.store.cartTotal.items.find((i) => i.item_id === WD.getCartOperator().getTotalIdBySku(sku)).qty = value;
    newItem.qty = value;
    WD.store.cartTotal = this.calculateSegmentTotals(WD.store.cartTotal);
    WD.store.cartInfo.items = newCartInfo.items.filter((i) => true);
    await this.threadHandler.runSingular(
      ProcessType.CART_ITEM_UPDATE(item.data.sku),
      async () => {
        try {
          if (value > 0) {
            await WD.getCartOperator().updateItem(newItem);
            AnalyticHandler.onEvent({
              category: 'CART',
              action: 'UPDATE',
              label: `change ${item.data.sku} amount to ${value}`,
              value: value,
            });
          } else {
            await CartActions.removeItemFromCart(itemTotal.item_id);
          }
          // ModalHandler.showToastSuccess(`${item.getName()} amount has been changed to ${value}.`);
        } catch (e) {
          WD.store.cartInfo = undo;
          console.log(e);
          WD.store.throwableResponse.onError(e + '');
        }
      },
      {
        throttle: 2000,
        onLast: async () => {
          console.log('RUN ON LAST');
          await CartActions.refresh();
        },
      },
    );
  }
  // Calculates price info offline, use to create instant feedback for user before server replies
  private static calculateSegmentTotals(cartTotal: ICartTotal): ICartTotal {
    cartTotal = deepClone(WD.store.cartTotal);
    const subtotal = cartTotal.total_segments.find((i) => i.title === 'Subtotal');
    const shipping = cartTotal.total_segments.find((i) => i.title === 'Shipping & Handling');
    const tax = cartTotal.total_segments.find((i) => i.title === 'Tax');
    const grandTotal = cartTotal.total_segments.find((i) => i.title === 'Grand Total' || i.title === 'Estimated Total');

    subtotal.value = 0;
    tax.value = 0;
    grandTotal.value = 0;
    let totalQuantity = 0;

    cartTotal.items.forEach((item) => {
      const magentoItem = WD.getItem(WD.getCartOperator().getSkuByTotalId(item.item_id));
      let price = magentoItem.getPrice();
      if (magentoItem.getSpecialPrice()) {
        console.log(magentoItem.getSpecialPrice());
        price = magentoItem.getSpecialPrice();
      }
      const tieredPrice = magentoItem.getTieredPrices() || [];
      tieredPrice.forEach((discount) => {
        if (item.qty >= discount.qty) {
          price = discount.value;
        }
      });
      const taxRate = item.tax_percent / 100;
      item.row_total_incl_tax = price * item.qty;
      subtotal.value += price * item.qty;
      tax.value += (item.row_total_incl_tax / (taxRate + 1)) * taxRate;
      grandTotal.value += price * item.qty;
      totalQuantity += item.qty;
    });
    cartTotal.items_qty = totalQuantity;
    return cartTotal;
  }
  static async refresh(isLoggedIn?: boolean, notUpdateWDExpress?: boolean) {
    WD.store.isLoading = true;
    if (isLoggedIn === undefined) {
      isLoggedIn = await WD.isUserLoggedIn();
    }
    if (!isLoggedIn) {
      // IF USER IS NOT LOGGED IN, EMPTY ORDERS AND WISH LIST
      WD.store.orders = undefined;
      WD.store.wishList = [];
      let cartId = await WD.getGuestCartID();
      if (!cartId) {
        cartId = await WD.getCartOperator().getCartID();
      }
      await CartActions.setGuestCartID(cartId);
    }
    let cartInfo: ICartInfo;
    cartInfo = await WD.getCartOperator().getCart();
    WD.store.cartTotal = await WD.getCartOperator().getCartTotal();
    if (cartInfo) {
      const giftInCart = cartInfo.items.find((i) => i.sku.indexOf('Gift') >= 0);
      if (giftInCart) {
        const giftMessage: string = await WD.getProductOption(giftInCart, 'Gift Message');
        giftInCart.giftCardMessage = giftMessage;
      }
    }
    WD.store.cartInfo = cartInfo;
    WD.store.isLoading = false;

    // Determine WD Express Eligible
    if (!notUpdateWDExpress) {
      this.updateCartExpressEligible(isLoggedIn);
    }

    return;
  }

  static async setGuestCartID(cartID: string) {
    WD.store.guestCartID = cartID;
    WD.store.cacheHandler.saveToDisk('guestCartID', cartID);
  }
  static async payWithCreditCardStripeToken(token: string, saveCard) {
    const paymentInfo = await this.getPaymentInfo(token);
    if (saveCard) {
      paymentInfo.paymentMethod.additional_data.stripe_token = token;
      if (saveCard) {
        paymentInfo.paymentMethod.additional_data.cc_save = true;
      }
    }
    return await this.setPaymentInformation(paymentInfo);
  }
  static async payWithSavedCard(card) {
    const paymentInfo = this.getPaymentInfo('');
    paymentInfo.paymentMethod.additional_data.cc_savecard_id = card.payment_method_id;
    paymentInfo.paymentMethod.additional_data.cc_type = card.brand;
    return this.setPaymentInformation(paymentInfo);
  }

  static async generatePayPalPayment(): Promise<IPayPalPayment> {
    return WD.getCartOperator().generatePayPalPayment();
  }
  static async payWithPayPal(token: string, payerId: string) {
    const paymentInfo = this.getPaymentInfo('');
    paymentInfo.paymentMethod.method = 'paypal_express';
    paymentInfo.paymentMethod.additional_data.paypal_express_checkout_token = token;
    paymentInfo.paymentMethod.additional_data.paypal_express_checkout_redirect_required = false;
    paymentInfo.paymentMethod.additional_data.paypal_express_checkout_payer_id = payerId;
    return this.setPaymentInformation(paymentInfo);
  }

  static async payWithZeroSubtotalCheckout() {
    const paymentInfo = this.getPaymentInfo('');
    paymentInfo.paymentMethod.method = 'free';
    paymentInfo.paymentMethod.additional_data = null;
    paymentInfo.paymentMethod.po_number = null;
    return this.setPaymentInformation(paymentInfo);
  }
  static async createPaymentIntent(option: IPaymentIntent = {}): Promise<IPaymentIntentResult> {
    const intent = await WD.store.service.createPaymentIntent(option);
    return intent;
  }
  static async getOrderIdFromPaymentId(id: string): Promise<IOrderInformation> {
    const order = await WD.store.service.getOrderIdFromPaymentId(id);
    return order;
  }
  // This method WILL actually pay, call this when you want to pay
  static async setPaymentInformation(info: IPaymentInformation): Promise<{orderId: string; soNumber: string}> {
    WD.store.throwableResponse.onLoading(WD.store.appLocale.customer_web.payment.writing_down_order);
    const orderId = await WD.getCartOperator().setPaymentInformation(info);
    try {
      await AnalyticHandler.onProductPurchase(orderId, info);
    } catch (e) {
      console.error(e);
    }

    if (WD.store.customer) {
      WD.store.throwableResponse.onLoading('Little bit more...');
      CustomerActions.refreshSavedCreditCards();
    }
    const soNumber = await WD.store.service.getOrderSoNumber(orderId);
    CartActions.refresh();
    if (WD.store.customer) {
      await CustomerActions.refreshOrders();
    }
    return {orderId, soNumber};
  }
  static async setCoupon(couponCode: string) {
    try {
      if (couponCode) {
        if (await WD.getCartOperator().setCoupon(couponCode)) {
          await this.refresh();
          WD.store.throwableResponse.onToastSuccess(`Coupon ${couponCode} has been successfully applied!`);
        } else {
          WD.store.throwableResponse.onError(`Applying coupon failed`);
        }
      } else {
        WD.store.throwableResponse.onError(`Coupon code cannot be empty!`);
      }
    } catch (e) {
      const errorString = e + '';
      if (errorString.indexOf('not available') >= 0) {
        WD.store.throwableResponse.onError('Sorry, the product that you are trying to add is currently not available.');
      } else {
        WD.store.throwableResponse.onError(errorString);
      }
    }
  }
  static async removeCoupon() {
    try {
      if (await WD.getCartOperator().removeCoupon()) {
        await this.refresh();
        WD.store.throwableResponse.onToastSuccess(`Coupon has been successfully removed!`);
      } else {
        //
      }
    } catch (e) {
      WD.store.throwableResponse.onError(`Removing coupon failed, ${e}`);
    }
  }
  static async setShipmentDetails(args: {
    shipping: IAddress;
    billing: IAddress;
    email: string;
    deliveryDate: string;
    deliveryTime: string;
    cartInfo: ICartInfo;
  }) {
    AnalyticHandler.onProductCheckout();
    const {shipping, billing, email, deliveryDate, deliveryTime, cartInfo} = args;
    if (!billing.email) {
      billing.email = email;
    }
    const info: IShipmentInformation = {
      addressInformation: {
        billing_address: {...billing},
        shipping_address: {...shipping},
        custom_attributes: [],
        shipping_method_code: 'freeshipping_freeshipping',
        shipping_carrier_code: 'vendor_rates',
        extension_attributes: {
          csdelivery_date: `["${deliveryDate}"]`,
          cstimestamp: `["${deliveryTime}"]`,
          csvendorId: '[null]',
          collectiondate: `[ "2233": "${deliveryDate}"]`,
          csdelivery_comment: '[""]',
        },
      },
    };
    await WD.getCartOperator().setShipmentDetails(info);
    await this.refresh();
    return this.getPaymentInfo('');
  }

  static getPaymentInfo(stripeToken: string): any {
    const customer = WD.store.customer;
    let email = '';
    if (customer) {
      email = customer.email;
    } else {
      email = WD.store.cartInfo.billing_address.email;
    }
    const paymentInfo = jsonClone({
      email,
      cartId: WD.store.cartInfo.id + '',
      paymentMethod: {
        additional_data: {
          stripe_token: stripeToken,
        },
        method: 'ced_stripe_method_one',
      },
      billingAddress: WD.store.cartInfo.billing_address,
    });
    delete paymentInfo.billingAddress.id;
    delete paymentInfo.billingAddress.region;
    delete paymentInfo.billingAddress.region_code;
    return paymentInfo;
  }

  static getSummary() {
    const cartTotal = WD.store.cartTotal;
    if (cartTotal && cartTotal.total_segments) {
      const segs = cartTotal.total_segments
        //.filter(seg => seg.value !== 0)
        .filter((seg) => seg.code !== 'shipping' && seg.value !== 0)
        .map((seg) => {
          const newSeg = {...seg};
          if (newSeg.title === 'Tax') {
            const gstRate =
              newSeg.extension_attributes &&
              newSeg.extension_attributes.tax_grandtotal_details &&
              newSeg.extension_attributes.tax_grandtotal_details.length > 0
                ? newSeg.extension_attributes.tax_grandtotal_details[0].rates[0].percent
                : '9';
            newSeg.title = `${gstRate}% GST (Inclusive)`;
          }
          return newSeg;
        });
      return segs;
    } else {
      return [];
    }
  }
  static getItemQuantity(sku: string) {
    const cartItem = WD.getCartItems().find((i) => i.item.getSku() === sku);
    if (cartItem) {
      return cartItem.inCartItem.qty;
    } else {
      return 0;
    }
  }

  // WD Express Section
  static updateCartExpressEligible(isLoggedIn?: boolean): void {
    const cartItems = WD.store.cartInfo.items;
    if (cartItems) {
      if (cartItems.length > 0) {
        const earliestDates = cartItems
          .filter((it) => it.product_type !== 'virtual')
          .map((item) => item.extension_attributes.earliest_delivery_date.date);
        // If before cut off time, the all item earliest date should be today
        // If after cut off time, the all item earliest date should be tomorrow
        if (WD.isExpressBeforeCutOffTime()) {
          const todayCount = earliestDates.filter((d) => isSameDay(getSGTime(), convertBasedOnSGTime(d)));
          if (todayCount.length > 0) {
            if (todayCount.length === earliestDates.length) {
              WD.store.expressCartEligible = 'eligible';
            } else {
              WD.store.expressCartEligible = 'halfEligible';
              WD.store.expressSelectedTimeslot = '';
              WD.resetExpressData(isLoggedIn);
            }
          } else {
            WD.store.expressCartEligible = 'notEligible';
            WD.store.expressSelectedTimeslot = '';
            WD.resetExpressData(isLoggedIn);
          }
        } else {
          const tomorrowCount = earliestDates.filter((d) =>
            isSameDay(new Date(getSGTime().getTime() + 24 * 60 * 60 * 1000), convertBasedOnSGTime(d)),
          );
          if (tomorrowCount.length > 0) {
            if (tomorrowCount.length === earliestDates.length) {
              WD.store.expressCartEligible = 'eligible';
            } else {
              WD.store.expressCartEligible = 'halfEligible';
              WD.store.expressSelectedTimeslot = '';
              WD.resetExpressData(isLoggedIn);
            }
          } else {
            WD.store.expressCartEligible = 'notEligible';
            WD.store.expressSelectedTimeslot = '';
            WD.resetExpressData(isLoggedIn);
          }
        }
      } else {
        WD.resetExpressData(isLoggedIn);
      }
    }
  }

  static showExpressTimeslot(show: boolean): void {
    WD.store.showExpressTimeslot = show;
  }

  // check last updated timeslot before final checkout
  static async timeSlotNotAvailable(): Promise<boolean> {
    const {blocked_delivery_dates, blocked_delivery_slots} = await ProductActions.getTimeSlots();

    const selectedDeliveryDate = formatToMagentoDate(new Date(WD.getSelectedDate()));
    const selectedDeliveryTime = WD.getUserSelectedDeliveryTime()
      ? WD.getUserSelectedDeliveryTime()
      : getAvailableTimeSlots(parseDate(selectedDeliveryDate))[0];

    WD.setUserSelectedDeliveryTime(selectedDeliveryTime);

    const blockedDeliveryDates = blocked_delivery_dates;
    const blockedDeliveryTimes = blocked_delivery_slots;

    if (blockedDeliveryDates.length > 0 && blockedDeliveryDates.indexOf(selectedDeliveryDate) !== -1) {
      return true;
    }

    if (
      blockedDeliveryTimes.length > 0 &&
      blockedDeliveryTimes.findIndex((x) => x.date === selectedDeliveryDate && x.time_slot === selectedDeliveryTime) !==
        -1
    ) {
      return true;
    }

    return false;
  }

  static async updateEarliestDateWhenExpired(currentDate: Date): Promise<void> {
    try {
      const lastEarliestDeliveryDate = WD.getEarliestDeliveryDate();
      await CartActions.refresh();
      await ProductActions.updateTimeSlots();
      await ProductActions.updateExpressCutOffTime();
      const newEarliestDeliveryDate = WD.getEarliestDeliveryDate();
      if (lastEarliestDeliveryDate.getTime() !== newEarliestDeliveryDate.getTime()) {
        if (currentDate < newEarliestDeliveryDate) {
          WD.setUserSelectedDeliveryDate(newEarliestDeliveryDate);
          // ModalHandler.showPromptGeneral(
          //   `Earliest delivery date has been updated to ${WD.formatDate(newEarliestDeliveryDate.toDateString(), {
          //     offset: 0,
          //   })}`,
          // );
          WD.store.throwableResponse.onError(
            `Earliest delivery date has been updated to ${WD.formatDate(newEarliestDeliveryDate.toDateString(), {
              offset: 0,
            })}`,
          );
        }
      }
    } catch (e) {
      Logger.logError('CHECKOUT PAGE', 'ERROR', e);
    }
  }
}
