import {
  CacheHandler,
  ThreadHandler,
  getSGTime,
  hydrateStore,
  WD,
  initWDConfig,
  getPlatform,
  Wine,
  IMagentoStore,
  AnalyticHandler,
  GENERAL_CACHE_EXPIRE_MINUTES,
  Logger,
  formatTimePassed,
  GOOGLE_ANALYTIC_PROD_ID,
  GOOGLE_ANALYTIC_DEV_ID,
  Timer,
  getGTMID,
} from '@wine-delivery/wd-shared-lib';

import { CacheHelper } from './Helpers/CacheHelper';
import AnalyticAdaptor from './Helpers/AnalyticAdaptor';
import { WDStore } from './Store/WDStore';
import { isDev, isBrowser, isOnProductionHost, updateServiceWorkers } from './Helpers/CommonHelper';
import { ThrowableResponseHelper } from './Helpers/ThrowableResponseHelper';
import { NotificationHandler } from './Server/Handlers/NotificationHandler';
import TagManager from 'react-gtm-module';

const timer = new Timer('Cache Store : false, Cache Render: true');
export const wdStore = new WDStore(new ThrowableResponseHelper());
let hasPreloadedMainStore = false;
let serverCacheTimestamp = 0;
let preloaded: IMagentoStore;
//@ts-ignore
if (window && window['hasPreloadedMainStore']) {
  hasPreloadedMainStore = true;
  // BOOTSTRAP MAIN STORE FROM HTML
  // @ts-ignore
  preloaded = window['preloadedMainStore'];

  // @ts-ignore
  if (preloaded['serverCacheTimestamp']) {
    // @ts-ignore
    serverCacheTimestamp = preloaded['serverCacheTimestamp'];
  }
  hydrateStore(wdStore, preloaded);

  // REMOVE SSR JSS
  // const jssStyles = document.getElementById('server-side-jss');
  // if (jssStyles && jssStyles.parentNode) {
  //     jssStyles.parentNode.removeChild(jssStyles);
  // }
} else {
  //@ts-ignore
  window.hasPreloadedMainStore = false;
}
export const threadHandler = new ThreadHandler(wdStore);
const cacheHandler = new CacheHandler(new CacheHelper(), wdStore);

type buildType = 'PRODUCTION' | 'STAGING' | 'UAT' | 'DEV';
type StoreLocation = 'SG' | 'ZRH';

export let envBuildType: buildType = 'UAT';
export let storeLocation: StoreLocation = 'SG';

// console.log(mainStore);
if (getPlatform() !== 'server_render') {
  if (isDev() === false) {
    envBuildType = process.env.REACT_APP_BUILD_TYPE as any;
    storeLocation = process.env.REACT_APP_STORE_LOCATION as any;
  }
  initWDConfig('web', envBuildType, storeLocation);
  let updateProducts = false;
  const currentTime = getSGTime().getTime();
  const log = {
    serverCacheTimestamp: new Date(serverCacheTimestamp),
    currentTime: new Date(currentTime),
    diff: formatTimePassed(currentTime - serverCacheTimestamp),
    maxCacheTime: formatTimePassed(GENERAL_CACHE_EXPIRE_MINUTES * 60 * 1000),
  };
  if (!hasPreloadedMainStore || currentTime - serverCacheTimestamp > GENERAL_CACHE_EXPIRE_MINUTES * 60 * 1000) {
    Logger.logInfo('SERVER PRELOAD CACHE', 'REFRESH CACHE', 'Refresh cache is set to TRUE', log);
    updateProducts = true;
    // updateServiceWorkers();
  } else {
    Logger.logInfo('SERVER PRELOAD CACHE', 'REFRESH CACHE', 'Refresh cache is set to FALSE', log);
  }
  let analyticId = '';
  if (isBrowser()) {
    if (!isDev() && envBuildType === 'PRODUCTION' && isOnProductionHost()) {
      analyticId = GOOGLE_ANALYTIC_PROD_ID;
    } else {
      analyticId = GOOGLE_ANALYTIC_DEV_ID;
    }
  }

  AnalyticHandler.setAdaptor(new AnalyticAdaptor(analyticId, 'web'));

  // initialize GTM
  const tagManagerArgs = {
    gtmId: getGTMID(),
  };

  TagManager.initialize(tagManagerArgs);

  // get prefered language
  const prefLang = window.localStorage.getItem(`wd-${storeLocation}-lang`);
  WD.init(wdStore, cacheHandler, threadHandler, {
    updateProducts,
    useCache: false,
    defaultLocale: prefLang ? JSON.parse(prefLang).data : null,
  });

  timer.print();
  (async () => {
    NotificationHandler.init();
  })();
}
