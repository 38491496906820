import {IMagentoItem} from '../Interface/IMagentoItem';
import {WineCustomAttributeType} from '../Interface/WineCustomAttributeType';
import {getImagePrefix, WD} from '..';
import {getWebBaseUrl} from '../Config';
import {getCustomAttribute, formatToMagentoDate} from '../Helper/Helper';
import moment from 'moment';

export class MagentoItem {
  constructor(public data: IMagentoItem) {}
  getData() {
    return this.data;
  }
  isWine() {
    return this.getType() === 'simple';
  }
  isGift() {
    return this.getType() === 'virtual';
  }
  getId() {
    return this.data.id;
  }
  getName() {
    return this.data.name;
  }
  getType() {
    return this.data.type_id;
  }
  getPrice() {
    return this.data.price;
  }
  hasImage() {
    const img = this.getCustomAttributeValue(WineCustomAttributeType.image);
    if (img) {
      return true;
    }
    return false;
  }
  getImage(options?: {size: 'small' | 'medium' | 'large' | 'original' | 'square'}): string {
    options = {
      size: 'large',
      ...options,
    };
    if (!this.data.extension_attributes || !this.data.extension_attributes.images) {
      return getImagePrefix() + this.getCustomAttributeValue(WineCustomAttributeType.image);
    } else {
      if (options.size === 'original') {
        return getImagePrefix() + this.getCustomAttributeValue(WineCustomAttributeType.image);
      }
      return this.data.extension_attributes.images[options.size];
    }
  }
  getCustomAttributeValue(type: WineCustomAttributeType): string {
    return getCustomAttribute(this.data, type);
  }
  getCategories() {
    const id = this.getCustomAttributeValue(WineCustomAttributeType.type);
    const ids = id.split(',');
    return ids.map((i) => WD.getType(i));
  }
  getSku() {
    return this.data.sku;
  }
  getSpecialPrice(): number {
    if (this.isSpecialPriceValid()) {
      return Number.parseFloat(this.getCustomAttributeValue(WineCustomAttributeType.special_price));
    } else {
      return undefined;
    }
  }
  hasSpecialPrice(): boolean {
    return this.getSpecialPrice() >= 0;
  }
  getSpecialPriceExpireDate() {
    const dateString = this.getCustomAttributeValue(WineCustomAttributeType.special_to_date);
    if (!dateString) {
      return new Date(Date.now() + 24 * 1000 * 60 * 60 * 100);
    }
    return moment(dateString).toDate();
  }
  getSpecialPriceStartDate() {
    const dateString = this.getCustomAttributeValue(WineCustomAttributeType.special_from_date);
    if (!dateString) {
      return new Date(Date.now() - 24 * 1000 * 60 * 60 * 100);
    }
    return moment(dateString).toDate();
  }
  isSpecialPriceValid(): boolean {
    const expireDate = this.getSpecialPriceExpireDate();
    expireDate.setDate(expireDate.getDate() + 1);
    const startDate = this.getSpecialPriceStartDate();
    const now = Date.now();
    return now <= expireDate.getTime() && now >= startDate.getTime();
  }
  getTieredPrices() {
    return this.data.tier_prices;
  }
  hasTieredPrices() {
    return this.getTieredPrices() && this.getTieredPrices().length > 0;
  }
  hasDiscount() {
    return this.hasSpecialPrice();
  }
  getTieredPrice(amount: number) {
    let price = undefined;
    if (this.hasTieredPrices()) {
      const tieredPrices = this.getTieredPrices();
      tieredPrices.forEach((tp) => {
        if (tp.qty <= amount) {
          if (tp.value < price || price === undefined) {
            price = tp.value;
          }
        }
      });
    }
    return price;
  }
  getPriceInfo(amount: number) {
    const specialPrice = this.getSpecialPrice();
    const tieredPrice = this.getTieredPrice(amount);
    const normalPrice = this.getPrice();
    let cheapest = {type: 'normalPrice', price: normalPrice};
    if (specialPrice !== undefined && specialPrice > 0 && specialPrice < cheapest.price) {
      cheapest = {type: 'specialPrice', price: specialPrice};
    }
    if (tieredPrice !== undefined && tieredPrice < cheapest.price) {
      cheapest = {type: 'tieredPrice', price: tieredPrice};
    }
    return {specialPrice, tieredPrice, normalPrice, cheapest};
  }
  isLoading() {
    return (this.getId() as any) === -1;
  }
  getShortInfo() {
    return JSON.stringify({
      sku: this.getSku(),
      name: this.getName(),
      url: `${getWebBaseUrl()}product/${this.getSku()}/`,
    });
  }
  hasSquareImage() {
    return this.data.created_at
      ? formatToMagentoDate(new Date(this.data.created_at.replace(/-/g, '/'))) >= '2021-11-01'
      : false;
  }
}
