import {Cart} from './Cart';
import {WD} from '../Helper/WD';
import {InCartItem, ICartInfo} from '../Interface/ICartInfo';
import {IStandardResponse} from '../Interface/IStandardResponse';
import {IInCartItemTotal, ICartTotal} from '../Interface/ICartTotal';
import {IShipmentInformation} from '../Interface/IShipmentInformation';
import {ISetShipmentInformationResponse} from '../Interface/ISetShipmentInformationResponse';
import {IPaymentInformation} from '../Interface/IPaymentInformation';
import {CartActions} from '../MobxStore/CartActions';
import {isGift} from '../Helper/Helper';
import {Logger} from '..';

export class GuestCart extends Cart {
  async getCartID() {
    if (!WD.store.guestCartID) {
      const id = await WD.store.service.createGuestCart();
      await CartActions.setGuestCartID(id);
    }
    return WD.store.guestCartID;
  }
  async getCart(): Promise<ICartInfo> {
    let cartInfo: ICartInfo;
    try {
      const id = await this.getCartID();
      cartInfo = await WD.store.service.getGuestCart(id);
      if (!cartInfo.items) {
        Logger.logError('CART', 'CART INFO NO ITEMS', "Cart doesn't have item in it, throwing error");
        throw new Error('No Item');
      }
      if (!cartInfo.is_active) {
        Logger.logError('CART', 'CART INFO NOT ACTIVE', 'Cart is not active, throwing error');
        throw new Error('No Item');
      }
    } catch (e) {
      Logger.logError('CART', 'CHANGE CART ID', 'Creating new id for guest cart');
      WD.store.guestCartID = undefined;
      cartInfo = await WD.store.service.getGuestCart(await this.getCartID());
    }
    if (cartInfo.items.find((i) => isGift(i))) {
      cartInfo.items = await WD.store.service.getGuestCartItems(await this.getCartID());
    }

    return cartInfo;
  }
  async getCartTotal(): Promise<ICartTotal> {
    return await WD.store.service.getGuestCartTotal(await this.getCartID());
  }
  async addGift(sku: string, message: string) {
    const r = await WD.store.service.addToGuestCart(await this.getCartID(), sku, 1, message);
    return r;
  }
  async add(sku: string, amount: number): Promise<any> {
    const result = await WD.store.service.addToGuestCart(await this.getCartID(), sku, amount);
    return result;
  }
  async remove(itemId: number) {
    const result = await WD.store.service.removeFromGuestCart(await this.getCartID(), itemId);
    return result;
  }
  async removeAll(items: IInCartItemTotal[]) {
    const promises: Promise<any>[] = items.map(async (i) =>
      WD.store.service.removeFromGuestCart(await this.getCartID(), i.item_id),
    );
    const r = await Promise.all(promises);
    return r;
  }
  async setShipmentDetails(info: IShipmentInformation) {
    const result = await WD.store.service.setGuestCartShipmentInformation(await this.getCartID(), info);
    return result;
  }
  // async order(info: IPaymentInformation) {
  //     const result = await WD.store.service.guestCartOrder(await this.getCartID(), info);
  //     return result;
  // }
  async setPaymentInformation(info: IPaymentInformation): Promise<ISetShipmentInformationResponse> {
    return await WD.store.service.setGuestCartPaymentInformation(await this.getCartID(), info);
  }
  async setCoupon(couponCode: string): Promise<any> {
    const r = await WD.store.service.setGuestCartCoupon(await this.getCartID(), couponCode);
    return r;
  }
  async updateItem(inCartItem: InCartItem, refresh = true): Promise<any> {
    const r = await WD.store.service.updateGuestCartItem(await this.getCartID(), inCartItem);
    return r;
  }
  async removeCoupon() {
    const r = await WD.store.service.removeGuestCartCoupon(await this.getCartID());
    return r;
  }
  async getPaymentInformation() {
    return await WD.store.service.getGuestCartPaymentInformation(await this.getCartID());
  }
  async generatePayPalPayment() {
    return WD.store.service.generateGuestPayPalPayment(await this.getCartID());
  }
}
