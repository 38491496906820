import {
  ICartInfo,
  ICartTotal,
  IInCartItemTotal,
  InCartItem,
  IPaymentInformation,
  ISetShipmentInformationResponse,
  IShipmentInformation,
  IStandardResponse,
  WD,
  IPayPalPayment,
} from '..';

export class Cart {
  getSkuByTotalId(id: number): string {
    const item = WD.store.cartInfo.items.find((i) => i.item_id === id);
    return item ? item.sku : undefined;
  }
  getTotalIdBySku(sku: string): number {
    const item = WD.store.cartInfo.items.find((i) => i.sku === sku);
    return item ? item.item_id : undefined;
  }
  getInCartItemSkus() {
    return WD.store.cartInfo.items.map((i) => i.sku);
  }
  getCartTotal(): Promise<ICartTotal> {
    throw new Error('Method not implemented.');
  }
  getInCartItemBySku(sku: string) {
    return WD.store.cartInfo.items.find((i) => i.sku === sku);
  }
  addGift(sku: string, message: string) {
    throw new Error('Method not implemented.');
  }
  add(sku: string, amount: number): Promise<InCartItem & IStandardResponse> {
    throw new Error('Method not implemented.');
  }
  remove(itemId: number): Promise<any> {
    throw new Error('Method not implemented.');
  }
  removeAll(items: IInCartItemTotal[]): Promise<any> {
    throw new Error('Method not implemented.');
  }
  setShipmentDetails(info: IShipmentInformation): Promise<ISetShipmentInformationResponse> {
    throw new Error('Method not implemented.');
  }
  order(info: IPaymentInformation): Promise<any> {
    throw new Error('Method not implemented.');
  }
  setPaymentInformation(info: IPaymentInformation, updateCart: boolean): Promise<any> {
    throw new Error('Method not implemented.');
  }
  setCoupon(couponCode: string): Promise<any> {
    throw new Error('Method not implemented.');
  }
  getCartID(): Promise<string> {
    throw new Error('Method not implemented.');
  }
  getCart(): Promise<ICartInfo> {
    throw new Error('Method not implemented.');
  }
  refreshCartTotal(): Promise<any> {
    throw new Error('Method not implemented.');
  }
  updateItem(inCartItem: InCartItem, refresh: boolean): Promise<any> {
    throw new Error('Method not implemented.');
  }

  removeCoupon(): Promise<any> {
    throw new Error('Method not implemented.');
  }
  getPaymentInformation(): Promise<any> {
    throw new Error('Method not implemented.');
  }

  createNewCart(): Promise<any> {
    throw new Error('Method not implemented.');
  }

  generatePayPalPayment(): Promise<IPayPalPayment> {
    throw new Error('Method not implemented.');
  }
}
