import React, { useState, useEffect } from 'react';
import { useStore } from './Store/UseStore';
import { observer } from 'mobx-react';
import { AppRouter } from './Router/AppRouter';
import { LoadingBar } from './Components/Loading/LoadingBar';
// import { Toast } from './Components/Common/Toast';
import 'date-fns';
import { BrowserRouter as Router } from 'react-router-dom';
import { DatadogHelper } from './Helpers/DatadogHelper';

const App = observer(() => {
  const [loaded, setLoaded] = useState(false);
  const store = useStore();
  const { customer } = store;

  useEffect(() => {
    (async () => {
      DatadogHelper.init();
      DatadogHelper.startSessionReplay();
      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    if (customer) {
      DatadogHelper.setUser(customer);
    } else {
      DatadogHelper.clearUser();
    }
  }, [customer]);

  return (
    <Router>
      {store.isLoading && <LoadingBar />}
      {/* <Toast /> */}
      <AppRouter />
    </Router>
  );
});

export default App;
