import { makeObservable, observable } from 'mobx';
import { ISommelierRecommendationMeta } from '@wine-delivery/wd-shared-lib';
import { INotificationTemplate } from '../Server/Interface/INotificationTemplate';
import { ISommelierConfigs } from '../Server/Interface/ISommelierConfigs';

export class AdminStore {
  @observable adminToken: string;
  @observable isLoggedIn: boolean;
  @observable notificationTemplates: INotificationTemplate[] = [];
  @observable sommelierRecommendationMetaList: ISommelierRecommendationMeta[] = [];
  @observable sommelierConfigs: ISommelierConfigs;

  constructor() {
    makeObservable(this);
  }
}

export interface IAdminStore {
  adminToken: string;
  isLoggedIn: boolean;
  notificationTemplates: INotificationTemplate[];
  sommelierRecommendationMetaList: ISommelierRecommendationMeta[];
  sommelierConfigs: ISommelierConfigs;
}

export const ADMIN_STORE = new AdminStore();
