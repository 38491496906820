import React from 'react';
import { COLOR } from '@wine-delivery/wd-shared-lib';

type Props = {
  name: string;
  id?: string;
  style?: React.CSSProperties;
  selected?: boolean;
};

export function Icon({ id, style, name, selected }: Props): JSX.Element {
  return (
    <span id={id} className="material-icons" style={{ color: selected ? COLOR.TOSCA : COLOR.GREY, ...style }}>
      {name}
    </span>
  );
}
