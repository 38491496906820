import React from 'react';
import styled, { keyframes } from 'styled-components';

import wdLogo from '../../../Assets/Icons/WD_logo_small.png';

const jello = keyframes`
    0% {  
        transform: scale3d(1, 1, 1);
    }

    30% {    
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {      
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {      
        transform: scale3d(1.15, 0.85, 1);
    }

    65% { 
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        transform: scale3d(1.05, 0.95, 1);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
`;

const AnimatedImg = styled.img`
  animation: ${jello} 0.9s infinite both;
`;

const LoadingIcon = () => {
  return (
    <div style={styles.loading}>
      <AnimatedImg src={wdLogo} alt="loading..." style={styles.loadingIcon} />
    </div>
  );
};

const styles = {
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8, // has to be the same as the button border radius
  } as React.CSSProperties,
  loadingIcon: {
    height: '50%',
    maxHeight: 20,
  } as React.CSSProperties,
};

export default LoadingIcon;
