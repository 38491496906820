import React from 'react';

import { INotification, COLOR } from '@wine-delivery/wd-shared-lib';

import wdIcon from '../../Assets/Icons/WD_icon_square.png';
import { Text } from '../Common/Text';
import { useStore } from '../../Store/UseStore';

type Props = {
  notification: INotification;
  style?: React.CSSProperties;
};

const IphoneNotification = ({ notification, style }: Props) => {
  const store = useStore();

  return (
    <div style={{ ...styles.container, ...style }}>
      <div style={styles.topContainer}>
        <div style={styles.iconContainer}>
          <img src={wdIcon} alt="wine.delivery" style={styles.icon} />
          <Text style={styles.iconText}>WINE.DELIVERY</Text>
        </div>
        <Text style={styles.nowText}>{store.appLocale.customer_web.notifications.now}</Text>
      </div>
      <Text style={styles.title}>{notification.title}</Text>
      <Text style={styles.body}>{notification.body}</Text>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: COLOR.LIGHT_TOSCA,
    width: 250,
    borderRadius: 14,
    padding: '6px 12px',
  } as React.CSSProperties,
  topContainer: {
    display: 'flex',
    marginBottom: 6,
    alignItems: 'center',
    justifyContent: 'space-between',
  } as React.CSSProperties,
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  } as React.CSSProperties,
  icon: {
    height: 16,
    width: 16,
    objectFit: 'contain',
    borderRadius: 4,
    marginRight: 8,
  } as React.CSSProperties,
  iconText: {
    fontSize: 11,
    fontWeight: 600,
  } as React.CSSProperties,
  nowText: {
    fontSize: 10,
  } as React.CSSProperties,
  title: { fontWeight: 800, fontSize: 14 } as React.CSSProperties,
  body: {
    fontSize: 12,
  } as React.CSSProperties,
};

export default IphoneNotification;
