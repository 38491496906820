import * as React from 'react';
import { AnalyticHandler, COLOR } from '@wine-delivery/wd-shared-lib';
import { Text } from '../Components/Common/Text';
import { ResponsiveView } from '../Components/Common/ResponsiveView';
import { ModalDispatcher, ModalOptions } from '../Components/Modal/ModalDispatcher';
import { LoadingSpinner } from '../Components/Loading/LoadingSpinner';
import { AppButton } from '../Components/Common/Button/AppButton';
import { ToastDispatcher } from '../Components/Toast/ToastDispatcher';
import errorIcon from '../Assets/Icons/red-cross.png';
import { DatadogHelper } from './DatadogHelper';

export class ModalHelper {
  static dispatcher: ModalDispatcher;
  static toastDispatcher: ToastDispatcher;

  static show(
    content: any,
    {
      canDismiss = true,
      containerStyle,
      phoneContainerStyle,
      padding,
      side,
      showCloseIcon,
      fullscreen,
      backgroundColor = 'white',
    }: ModalOptions = {},
  ) {
    this.dispatcher.dispatch(content, {
      canDismiss,
      containerStyle: {
        margin: 'auto',
        borderRadius: 8,
        backgroundColor: backgroundColor === 'grey' ? COLOR.LIGHT_GREY : COLOR.WHITE,
        ...containerStyle,
      },
      phoneContainerStyle: {
        width: fullscreen ? '100%' : 'fit-content',
        height: fullscreen ? '100%' : 'fit-content',
        margin: fullscreen ? 0 : 'auto 4px',
        borderRadius: fullscreen ? 0 : 8,
        backgroundColor: backgroundColor === 'grey' ? COLOR.LIGHT_GREY : COLOR.WHITE,
        ...phoneContainerStyle,
      },
      padding,
      side,
      showCloseIcon,
    });
  }

  static showNoPadding(content: any, { containerStyle = {} }: { containerStyle?: React.CSSProperties } = {}) {
    this.show(content, {
      padding: 0,
      containerStyle: { padding: 0, overflowY: 'visible', ...containerStyle },
      showCloseIcon: false,
    });
  }

  static showNonDismissible(content: any, { containerStyle = {} }: { containerStyle?: React.CSSProperties } = {}) {
    this.show(content, {
      canDismiss: false,
      padding: 0,
      containerStyle: {
        padding: 0,
        overflowY: 'auto',
        maxWidth: '98vw',
        width: '100%',
        maxHeight: '85vh',
        height: '100%',
        ...containerStyle,
      },
      showCloseIcon: false,
    });
  }

  static showConfirm(msg: string, onConfirm: () => any) {
    this.show(
      <ResponsiveView style={styles.container}>
        <Text>{msg}</Text>
        <ResponsiveView style={{ flexDirection: 'row', gap: 10 }}>
          <AppButton
            label="YES"
            onPress={async () => {
              await onConfirm();
              this.close();
            }}
          />
          <AppButton label="NO" onPress={() => this.close()} />
        </ResponsiveView>
      </ResponsiveView>,
      { backgroundColor: 'grey', showCloseIcon: true },
    );
  }
  static showPromptError(msg: string) {
    msg = msg + '';
    if (msg && msg.startsWith('Error: ')) {
      msg = msg.replace('Error: ', '');
    }
    DatadogHelper.log(
      'Error Modal',
      {
        message: msg,
        action: 'Error',
        others: 'Generic from error modal',
      },
      'error',
    );
    this.show(
      <ResponsiveView style={styles.container}>
        <img src={errorIcon} style={styles.icon} />
        <Text>{msg + ''}</Text>
        <ResponsiveView>
          <AppButton label="OK" style={styles.button} onPress={() => this.close()} />
        </ResponsiveView>
      </ResponsiveView>,
      { backgroundColor: 'grey', showCloseIcon: true },
    );
    AnalyticHandler.onEvent({ category: 'ERROR', action: 'PROMPTED ERROR', label: `${msg}` });
  }
  static showPromptErrorWithConfirm(msg: string, onConfirm: () => any) {
    msg = msg + '';
    if (msg && msg.startsWith('Error: ')) {
      msg = msg.replace('Error: ', '');
    }
    DatadogHelper.log(
      'Error Confirm Modal',
      {
        message: msg,
        action: 'Error',
        others: 'Generic from prompt with confirm modal',
      },
      'error',
    );
    this.show(
      <ResponsiveView style={styles.container}>
        <img src={errorIcon} style={styles.icon} />
        <Text>{msg + ''}</Text>
        <ResponsiveView>
          <AppButton
            label="OK"
            style={styles.button}
            onPress={async () => {
              await onConfirm();
              this.close();
            }}
          />
        </ResponsiveView>
      </ResponsiveView>,
      { backgroundColor: 'grey', showCloseIcon: true },
    );
    AnalyticHandler.onEvent({ category: 'ERROR', action: 'PROMPTED ERROR', label: `${msg}` });
  }
  static showPromptGeneral(msg: string) {
    DatadogHelper.log('Modal', {
      message: msg,
      action: 'Prompt',
      others: 'Generic from general prompt modal',
    });
    this.show(
      <ResponsiveView style={styles.container}>
        <Text>{msg + ''}</Text>
        <ResponsiveView>
          <AppButton label="OK" style={styles.button} onPress={() => this.close()} />
        </ResponsiveView>
      </ResponsiveView>,
      { backgroundColor: 'grey', showCloseIcon: true },
    );
  }
  static showLoading(msg: string) {
    this.show(
      <ResponsiveView style={styles.container}>
        <LoadingSpinner />
        <Text>{msg + ''}</Text>
      </ResponsiveView>,
      { canDismiss: false, backgroundColor: 'grey' },
    );
  }
  static close() {
    ModalHelper.dispatcher.close();
  }
  static showToast(msg: string, duration = 2000) {
    ModalHelper.toastDispatcher.dispatch(msg, duration);
  }
}

const styles = {
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: 10,
    margin: 10,
  } as React.CSSProperties,
  button: {
    width: 150,
  } as React.CSSProperties,
  icon: {
    height: 64,
    width: 64,
    marginBottom: 12,
  } as React.CSSProperties,
};
