import {
  IMagentoStore,
  MagentoServiceHandler,
  Wine,
  IThrowableResponse,
  ICartInfo,
  ICartTotal,
  Gift,
  ICustomer,
  IRecommendation,
  IGetRecommendationsResponse,
  IOrder,
  RestProcess,
  IElasticSearchBucket,
  IElasticSearchOptions,
  ISavedCreditCard,
  ProductAttributeMap,
  CacheHandler,
  INotification,
  ISommelierRecommendationIds,
  ISearchFilter,
} from '@wine-delivery/wd-shared-lib';
import { ICustomerDeletion } from '@wine-delivery/wd-shared-lib/dist/Interface/ICustomer';
import { ILang } from '@wine-delivery/wd-shared-lib/dist/Interface/ILang';
import { observable, makeObservable } from 'mobx';

export class WDStore implements IMagentoStore {
  @observable selectedDeliveryDate: Date;
  @observable selectedDeliveryTime: string;
  @observable blockedDeliveryTimeSlots: { date: string; time_slot: string }[] = [];
  @observable blockedDeliveryDates: string[] = [];
  @observable myDiscoverySkus: string[];
  @observable requestedWineSkus: string[] = [];
  @observable threadHandler: any;
  @observable guestCartID: string;
  @observable stockQuantity: { [sku: string]: number } = {};
  @observable wishList: string[] = [];
  @observable homeProductsCount: number;
  @observable homeProducts: string[] = [];
  @observable latestProducts: string[] = [];
  @observable saleProducts: string[] = [];
  @observable staffPicks: string[] = [];
  @observable spiritProductsCount: number;
  @observable spiritProducts: string[] = [];
  @observable organicProductsCount: number;
  @observable organicProducts: string[] = [];
  @observable latestProductsCount: number;
  @observable saleProductsCount: number;
  @observable nextDayDeliveriesCount: number;
  @observable nextDayDeliveries: string[] = [];
  @observable expressProductsCount: number;
  @observable expressProducts: string[] = [];
  @observable expressEarliestDeliveryDate: string;
  @observable expressCartEligible: string;
  @observable expressSelectedTimeslot: string;
  @observable showExpressTimeslot: boolean;
  @observable gifts: Gift[];
  @observable cart: any;
  @observable cartInfo: ICartInfo;
  @observable cartTotal: ICartTotal;
  @observable customer: ICustomer;
  @observable isLoggedIn: boolean;
  @observable isMovingItemToCart = false;
  @observable isInLogginProcess = false;
  @observable isLoading: boolean;
  @observable countries: ProductAttributeMap;
  @observable regions: ProductAttributeMap;
  @observable grapeVarieties: ProductAttributeMap;
  @observable productTypes: ProductAttributeMap;
  @observable currentTapNav: any;
  @observable recommendations: IGetRecommendationsResponse[] = [];
  @observable recommendationDetails: IRecommendation[] = [];
  @observable timeSlots: string[];
  @observable expressCutOffTime: string;
  @observable orders: IOrder[] = [];
  @observable ordersCount = 0;
  @observable ordersCurrentPage = 1;
  @observable ordersPageSize = 10;
  @observable internetWorking: boolean;
  @observable isCartLoading: boolean;
  @observable cacheHandler: CacheHandler;

  @observable processState = {};

  @observable itemMap: { [sku: string]: Wine } = {};

  @observable restProcesses: RestProcess[] = [];

  service: MagentoServiceHandler;

  @observable currentRoute = '/';

  @observable globalSearchFilterBuckets: IElasticSearchBucket[] = [];
  @observable globalSearchOptions: IElasticSearchOptions = {};
  @observable globalSearchPage = 1;
  @observable globalOnSearchPage = false;
  @observable globalShouldKeepScrollPosition = false;
  @observable globalScrollPosition = 0;
  @observable globalSearchItems: string[] = [];
  @observable globalSearchIsLoading = false;
  @observable globalSearchItemTotal: number;

  @observable savedCreditCards: ISavedCreditCard[] = [];

  @observable myNotifications: INotification[] = [];

  serverCacheTimestamp: number;

  @observable sommelierRecommendationIds: ISommelierRecommendationIds = {};

  @observable availableSearchFilters: ISearchFilter[] = [];

  @observable personalRecommendations: string[] = [];

  @observable selectedAccountDeletion = '';

  @observable accountDeletionReason: ICustomerDeletion = {
    deletedCustomer: {
      reason_for_deletion: '',
      reason_for_deletion_label: '',
      reason_for_deletion_description: '',
    },
  };

  @observable changingAddress = false;

  @observable appLocale: ILang;

  constructor(public throwableResponse: IThrowableResponse) {
    makeObservable(this);
  }
}
