import {ILang} from '../Interface/ILang';
import {getStoreLocation, SiteLang, WD} from '..';

import en from '../i18n/en.json';
import de from '../i18n/de.json';

const appLocale = {
  en,
  de,
};

const storeDefaultLocale = {
  SG: 'en',
  ZRH: 'de',
};

export class LangActions {
  public static init(defaultLocale?: SiteLang) {
    WD.store.appLocale = appLocale[defaultLocale || storeDefaultLocale[getStoreLocation()]];
  }

  public static setAppLocale(locale: SiteLang) {
    WD.store.appLocale = appLocale[locale] as ILang;
    WD.store.cacheHandler.saveToDisk(`wd-${getStoreLocation()}-lang`, locale);
  }
}
