import React from 'react';
import { COLOR, FONT } from '@wine-delivery/wd-shared-lib';

type Props = {
  children: React.ReactNode;
  id?: string;
  style?: React.CSSProperties;
  className?: string;
  main?: boolean;
  secondary?: boolean;
  header1?: boolean;
  header2?: boolean;
  header3?: boolean;
  header4?: boolean;
  list?: boolean;
  span?: boolean;
};

export function Text({
  children,
  id,
  style,
  className,
  main,
  secondary,
  header1,
  header2,
  header3,
  header4,
  list,
  span,
}: Props): JSX.Element {
  if (header1) {
    return (
      <h1
        id={id}
        style={{
          fontFamily: main ? FONT.MAIN : FONT.SECONDARY,
          color: COLOR.GOLD,
          fontSize: 60,
          ...style,
        }}>
        {children}
      </h1>
    );
  }
  if (header2) {
    return (
      <h2
        id={id}
        style={{
          fontFamily: main ? FONT.MAIN : FONT.SECONDARY,
          color: COLOR.GOLD,
          fontSize: 60,
          ...style,
        }}>
        {children}
      </h2>
    );
  }
  if (header3) {
    return (
      <h3
        id={id}
        style={{
          fontFamily: main ? FONT.MAIN : FONT.SECONDARY,
          color: COLOR.DARK_GOLD,
          fontSize: 48,
          ...style,
        }}>
        {children}
      </h3>
    );
  }
  if (header4) {
    return (
      <h4
        id={id}
        style={{
          fontFamily: main ? FONT.MAIN : FONT.SECONDARY,
          color: COLOR.DARK_GOLD,
          fontSize: 24,
          ...style,
        }}>
        {children}
      </h4>
    );
  }
  if (list) {
    return (
      <li
        id={id}
        style={{
          fontFamily: secondary ? FONT.SECONDARY : FONT.MAIN,
          ...style,
        }}
        className={className}>
        {children}
      </li>
    );
  }
  if (span) {
    return (
      <span
        id={id}
        style={{
          fontFamily: secondary ? FONT.SECONDARY : FONT.MAIN,
          ...style,
        }}
        className={className}>
        {children}
      </span>
    );
  }
  return (
    <p
      id={id}
      style={{
        fontFamily: secondary ? FONT.SECONDARY : FONT.MAIN,
        color: COLOR.MEDIUM_BLACK,
        ...style,
      }}>
      {children}
    </p>
  );
}
