import { ICacheSystem } from '@wine-delivery/wd-shared-lib';

export class CacheHelper implements ICacheSystem {
  async get(key: string): Promise<string> {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return undefined;
    }
  }
  async save(key: string, data: string): Promise<void> {
    try {
      return localStorage.setItem(key, data);
    } catch (e) {
      return undefined;
    }
  }
  async exists(key: string): Promise<boolean> {
    return this.get(key) !== undefined;
  }
  async clear() {
    localStorage.clear();
  }
  async delete(key: string) {
    localStorage.clear();
  }
}
