export enum WineCustomAttributeType {
  options_container = 'options_container',
  required_options = 'required_options',
  has_options = 'has_options',
  url_key = 'url_key',
  tax_class_id = 'tax_class_id',
  featured = 'featured',
  type = 'type',
  producer = 'producer',
  grape_varieties = 'grape_varieties',
  vintage_year = 'vintage_year',
  bottle_size = 'bottle_size',
  alcohol = 'alcohol',
  occasion = 'occasion',
  food_pairing = 'food_pairing',
  country = 'country',
  region = 'region',
  description = 'description',
  short_description = 'short_description',
  special_price = 'special_price',
  special_from_date = 'special_from_date',
  image = 'image',
  small_image = 'small_image',
  thumbnail = 'thumbnail',
  news_from_date = 'news_from_date',
  custom_design_from = 'custom_design_from',
  awards_and_recognition = 'awards_and_recognition',
  category_ids = 'category_ids',
  country_of_manufacture = 'country_of_manufacture',
  custom_design = 'custom_design',
  custom_design_to = 'custom_design_to',
  custom_layout = 'custom_layout',
  custom_layout_update = 'custom_layout_update',
  gallery = 'gallery',
  gift_message_available = 'gift_message_available',
  media_gallery = 'media_gallery',
  merchant_sku = 'merchant_sku',
  meta_description = 'meta_description',
  meta_keyword = 'meta_keyword',
  meta_title = 'meta_title',
  msrp = 'msrp',
  msrp_display_actual_price_type = 'msrp_display_actual_price_type',
  name = 'name',
  news_to_date = 'news_to_date',
  page_layout = 'page_layout',
  price = 'price',
  price_type = 'price_type',
  price_view = 'price_view',
  quantity_and_stock_status = 'quantity_and_stock_status',
  shipment_type = 'shipment_type',
  sku = 'sku',
  sku_type = 'sku_type',
  special_to_date = 'special_to_date',
  status = 'status',
  swatch_image = 'swatch_image',
  tier_price = 'tier_price',
  visibility = 'visibility',
  weight = 'weight',
  weight_type = 'weight_type',
  wd_earliest_delivery_date = 'wd_earliest_delivery_date',
  wd_earliest_delivery_time_slot = 'wd_earliest_delivery_time_slot',
  wd_earliest_collection_date = 'wd_earliest_collection_date',
}
