export const FONT = {
  MAIN: 'Gotham-Book',
  MAIN_LIGHT: 'Gotham-Light',
  MAIN_MEDIUM: 'Gotham-Medium',
  MAIN_BOLD: 'Gotham-Bold',
  SECONDARY: 'KoHo-SemiBold',
};

export const COLOR = {
  WHITE: '#FFFFFF',
  // LIGHT_BLACK: '#52575C',
  MEDIUM_BLACK: '#3E4246',
  BLEND_BLACK: '#373330',
  DARK_BLACK: '#231F20',
  GREEN: '#30d158',
  DARK_GREEN: '#118C16',
  RED: '#ED2927',
  MEDIUM_RED: '#FF0000',
  AMBER: '#FFBF00',
  YELLOW: '#C8B100',
  PAYPAL_YELLOW: '#fbc33c',
  LIGHT_GOLD: '#cc962c',
  GOLD: '#BF9254',
  BLUR_GOLD: '#D4c1a5',
  DARK_GOLD: '#8D6537',
  DARK_GOLD_2: '#95622B',
  GREY: '#6A6D6D',
  LIGHT_GREY: '#E4E4E4',
  LIGHT_GREY_2: '#CBCAC8',
  LIGHT_GREY_3: '#CAC9C9',
  DARK_GREY: '#3F4246',
  TOSCA: '#55A3A7',
  LIGHT_TOSCA: 'rgba(126, 190, 189, .7)',
  NOTIFICATION_COLOR: 'rgba(126, 190, 189, .7)',
};

export const CARD = {
  STANDARD: (height: number) => ({...SHADOW.STANDARD(height), borderRadius: 5, backgroundColor: 'white', padding: 16}),
  FLAT: () => ({
    borderRadius: 6,
    backgroundColor: 'white',
    padding: 16,
    border: `1px solid ${COLOR.LIGHT_GREY}`,
  }),
};

export const SHADOW = {
  STANDARD: (
    height: number,
    {outerColor = 'rgba(0,0,0,.1)', innerColor = 'rgba(204,150,44,.1)', heightOffset = 1} = {},
  ) => ({
    boxShadow: `0 ${height * 3}px ${heightOffset + height * 10}px ${outerColor}, 0 ${heightOffset + height * 1.5}px ${
      height * 5
    }px ${innerColor}`,
  }),
  COLOR: (height: number, color: any = `rgba(255,255,255,.9)`) => {
    return {boxShadow: `0 ${height * 6}px ${height * 20}px ${color}`};
  },
  TEXT: ({radius = 64, color = 'black', outline = false} = {}) => {
    return {textShadow: `0px 0px ${radius}px ${color}${outline ? `, 0px 0px 8px ${color}` : ``}`};
  },
};

export const backgroundImageStyle = (
  image,
  {
    backgroundPosition = 'center',
    backgroundSize = 'cover',
  }: {backgroundPosition?: 'center'; backgroundSize?: 'cover' | 'contain'} = {},
) => ({
  backgroundImage: `url('${image}')`,
  backgroundPosition,
  backgroundSize,
});
