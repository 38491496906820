import * as React from 'react';
import { Toast } from './Toast';
import { sleep, newUUID, shallowCompare } from '@wine-delivery/wd-shared-lib';

export interface ToastOptions {
  msg?: string;
  duration?: number;
  uuid?: string;
  state?: ToastState;
}

interface Props {
  classes?: any;
}

interface States {
  toasts: ToastOptions;
  show: boolean;
}

type ToastState = 'success' | 'fail' | 'error';

export class ToastDispatcher extends React.Component<Props, States> {
  static defaultProps = {};

  state: States = { toasts: {}, show: false };

  async dispatch(msg: string, duration: number, state: ToastState = 'success') {
    const { toasts } = this.state;
    const uuid = newUUID();
    this.setState({ toasts: { msg, duration, uuid, state }, show: true });
    await sleep(duration);
    this.setState({ show: false });
  }

  shouldComponentUpdate(p, s) {
    return shallowCompare(this, p, s);
  }

  render() {
    const { toasts, show } = this.state;
    return <Toast options={toasts} show={show} />;
  }
}
