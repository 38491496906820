import {
  AnalyticHandler,
  IAddressDetailsResponse,
  ICartTotal,
  ICreateCustomerResponse,
  IElasticSearchOptions,
  IElasticSearchResult,
  IMagentoStore,
  IMyAddressAutoCompleteResponse,
  INotification,
  IPayPalPayment,
  IProductAttributes,
  WD,
  ISommelierRecommendationIds,
} from '..';
import {getLambdaApiURL} from '../Config';
import {ICartInfo, InCartItem} from '../Interface/ICartInfo';
import {ICollection} from '../Interface/ICollection';
import {ICollectionDetail} from '../Interface/ICollectionDetail';
import {ICombinedProductAttributes} from '../Interface/ICombinedProductAttributes';
import {ICustomer, ICustomerDeletion} from '../Interface/ICustomer';
import {IDelivery} from '../Interface/IDelivery';
import {IDeliveryDetail} from '../Interface/IDeliveryDetail';
import {IDeliveryUser} from '../Interface/IDeliveryUser';
import {IGetRecommendationsResponse} from '../Interface/IGetRecommendationsResponse';
import {IGetTimeSlotResponse} from '../Interface/IGetTimeSlotResponse';
import {IMagentoItem} from '../Interface/IMagentoItem';
import {IOrder, IOrderSearchResult} from '../Interface/IOrderSearchResult';
import {IPaymentInformation, IPaymentIntent} from '../Interface/IPaymentInformation';
import {IRecommendation} from '../Interface/IRecommendation';
import {IRestService} from '../Interface/IRestService';
import {ISavedCreditCard} from '../Interface/ISavedCreditCard';
import {ISearchResult} from '../Interface/ISearchResult';
import {ISetShipmentInformationResponse} from '../Interface/ISetShipmentInformationResponse';
import {IShipmentInformation} from '../Interface/IShipmentInformation';
import {IStandardResponse} from '../Interface/IStandardResponse';
import {MagentoSearchQueryBuilder} from './MagentoSearchQueryBuilder';
import {IPurchaseOrderSummaryList, IPurchaseOrderSummaryDetail, ICollectProduct} from '../Interface/ICollectionSummary';
import {INewsletterSave} from '../Interface/INewsletterSave';
import {IExpressCutOffTime} from '../Interface/IWDExpress';
import Axios from 'axios';

export class MagentoServiceHandler {
  constructor(public restService: IRestService) {
    (async () => {})();
  }

  public async init() {
    // console.log(await this.getFeatured());
  }
  public async getProductInfo(productID: string): Promise<IMagentoItem> {
    return await this.restService.request(`rest/V1/products/${productID}`, 'GET');
  }
  public async createGuestCart(): Promise<string> {
    return await this.restService.request('rest/V1/guest-carts', 'POST');
  }
  public async createCustomerCart(): Promise<string> {
    return await this.restService.request('rest/V1/carts/mine', 'POST');
  }
  public async getGuestCart(cartID: string): Promise<ICartInfo> {
    return this.restService.request(`rest/V1/guest-carts/${cartID}`, 'GET');
  }
  public async getCustomerCart(): Promise<ICartInfo> {
    return await this.restService.request(`rest/V1/carts/mine`, 'GET');
  }
  public async search(keywords: string): Promise<any> {
    return await this.restService.request(
      `rest/V1/products/?
        searchCriteria[filter_groups][0][filters][0][field]=name&
        searchCriteria[filter_groups][0][filters][0][value]=%${keywords}%25&
        searchCriteria[filter_groups][0][filters][0][condition_type]=like&
        searchCriteria[page_size]=5`,
      'GET',
    );
  }

  public async addToCustomerCart(
    cart_id: number,
    sku: string,
    qty: number,
    giftCartMsg?: string,
  ): Promise<IStandardResponse & InCartItem> {
    let extension_attributes = undefined;
    if (giftCartMsg) {
      extension_attributes = {
        custom_options: [
          {
            option_id: await this.getGiftMessageOptionId(sku),
            option_value: giftCartMsg,
          },
        ],
      };
    }

    return await this.restService.request(`rest/V1/carts/mine/items`, 'POST', {
      cartItem: {
        quote_id: cart_id,
        sku,
        qty,
        product_option: {
          extension_attributes,
        },
      },
    });
  }
  public async addToGuestCart(cartID: string, sku: string, amount, giftCartMsg?: string) {
    let extension_attributes = undefined;
    if (giftCartMsg) {
      extension_attributes = {
        custom_options: [
          {
            option_id: await this.getGiftMessageOptionId(sku),
            option_value: giftCartMsg,
          },
        ],
      };
    }

    return await this.restService.request(`rest/V1/guest-carts/${cartID}/items`, 'POST', {
      cartItem: {
        quote_id: cartID,
        sku: sku,
        qty: amount,
        product_option: {
          extension_attributes,
        },
      },
    });
  }
  private async getGiftMessageOptionId(sku: string) {
    const options = await this.getProductOptionIds(sku);
    return options.find((i) => i.title === 'Gift Message').option_id;
  }
  public async getProductOptionIds(sku: string): Promise<{title: string; option_id}[]> {
    return await this.restService.request(`rest/V1/products/${sku}/options`, 'GET');
  }
  public async removeFromGuestCart(cartID: string, id: number) {
    return await this.restService.request(`rest/V1/guest-carts/${cartID}/items/${id}`, 'DELETE');
  }
  public async removeFromCustomerCart(itemId: number) {
    return await this.restService.request(`rest/V1/carts/mine/items/${itemId}`, 'DELETE');
  }
  public async setGuestCartShipmentInformation(
    cartID: string,
    info: IShipmentInformation,
  ): Promise<ISetShipmentInformationResponse> {
    return await this.restService.request(`rest/V1/guest-carts/${cartID}/shipping-information`, 'POST', info);
  }
  public async setCustomerCartShipmentInformation(
    info: IShipmentInformation,
  ): Promise<ISetShipmentInformationResponse> {
    return await this.restService.request(`rest/V1/carts/mine/shipping-information`, 'POST', info);
  }
  public async getGuestCartTotal(cartID: string): Promise<ICartTotal> {
    return await this.restService.request(`rest/V1/guest-carts/${cartID}/totals`, 'GET');
  }
  public async getCustomerCartTotal(deliveryDate: string = null, deliveryTime: string = null): Promise<ICartTotal> {
    if (deliveryDate && deliveryTime) {
      return await this.restService.request(
        `rest/V1/carts/mine/totals?deliveryDate=${deliveryDate}&deliveryTime=${deliveryTime}`,
        'GET',
      );
    } else {
      return await this.restService.request(`rest/V1/carts/mine/totals`, 'GET');
    }
  }
  public async getFeatured(): Promise<ISearchResult> {
    const all: any = await this.restService.request(
      `rest/V1/products/?${new MagentoSearchQueryBuilder()
        // .like("sku", "WD-2423-1-M4")
        .like('image' as any, '.jpg')
        .AND()
        .greaterThen('price', 1)}`,
      'GET',
    );
    return all;
  }
  public async getGifts(): Promise<ISearchResult> {
    const all: any = await this.restService.request(
      `rest/V1/products/?${new MagentoSearchQueryBuilder().like('sku', 'Gift').AND().equalTo('status', 1)}`,
      'GET',
    );
    return all;
  }
  public async getItems(skuList: string[]): Promise<ISearchResult> {
    const builder = new MagentoSearchQueryBuilder();
    for (const sku of skuList) {
      builder.equalTo('sku', sku).OR();
    }
    const all: ISearchResult = await this.restService.request(`rest/V1/products/?${builder}`, 'GET');
    return all;
  }
  public async createCustomer(customer: ICreateCustomerResponse): Promise<ICreateCustomerResponse> {
    const result = await this.restService.request('rest/V1/customers', 'POST', customer);
    return result;
  }
  public async updateCustomer(newCustomer: ICustomer) {
    return this.restService.request('rest/V1/customers/me', 'PUT', newCustomer);
  }
  public async login(username: string, password: string): Promise<IStandardResponse> {
    const result = await this.restService.request('rest/V1/integration/customer/token', 'POST', {username, password});
    return result;
  }
  public async getCustomerShippingAddress() {
    const result = await this.restService.request('rest/V1/customers/me/shippingAddress', 'GET');
    return result;
  }
  public async getCustomerInfo(): Promise<ICustomer> {
    return await this.restService.request('rest/V1/customers/me', 'GET');
  }
  public async setGuestCartPaymentInformation(cartID: string, info: IPaymentInformation) {
    return await this.restService.request(`rest/V1/guest-carts/${cartID}/payment-information`, 'POST', info);
  }
  public async setCustomerPaymentInformation(info: IPaymentInformation) {
    return await this.restService.request('rest/V1/carts/mine/payment-information', 'POST', info);
  }
  public async getGuestCartPaymentInformation(cartID: string) {
    return await this.restService.request(`rest/V1/guest-carts/${cartID}/payment-information`, 'GET');
  }
  public async getCustomerPaymentInformation() {
    return await this.restService.request('rest/V1/carts/mine/payment-information', 'GET');
  }
  public async createPaymentIntent(option: IPaymentIntent) {
    const {use_three_d_secure, save_card, payment_method_id} = option;
    return await this.restService.request('rest/V1/wd/mine/payment-intent', 'POST', {
      // POSTPONED; in able to use this we need to implement deeplinking first,
      // else the popup modal from stripe wont redirect automatically
      // use_three_d_secure,
      save_card,
      payment_method_id: payment_method_id || null,
    });
  }
  public async getOrderIdFromPaymentId(id: string) {
    return await this.restService.request(`rest/V1/wd/stripe/get-order/${id}`, 'GET');
  }
  public async logWDApp(data) {
    return await Axios.request({url: 'https://user.oms.uat.winedelivery.tech/log/wd/app', method: 'POST', data: data});
  }
  // public async guestCartOrder(cartId: string, info: IPaymentInformation) {
  // 	return this.restService.request(`rest/V1/guest-carts/${cartId}/order`, "PUT", info)
  // }
  // public async customerCartOrder(info: IPaymentInformation) {
  // 	return this.restService.request(`rest/V1/carts/908/order`, "PUT", info)
  // }
  public async setGuestCartCoupon(cartId: string, couponCode: string) {
    return this.restService.request(`rest/V1/guest-carts/${cartId}/coupons/${couponCode}`, 'PUT');
  }
  public async getGuestCartCoupon(cartId: string, couponCode: string) {
    return this.restService.request(`rest/V1/guest-carts/${cartId}/coupons`, 'GET');
  }
  public async removeGuestCartCoupon(cartId: string) {
    return this.restService.request(`rest/V1/guest-carts/${cartId}/coupons`, 'DELETE');
  }
  public async setCustomerCartCoupon(couponCode: string) {
    return this.restService.request(`rest/V1/carts/mine/coupons/${couponCode}`, 'PUT');
  }
  public async getCustomerCartCoupon(couponCode: string) {
    return this.restService.request(`rest/V1/carts/mine/coupons`, 'GET');
  }
  public async removeCustomerCartCoupon() {
    return this.restService.request(`rest/V1/carts/mine/coupons`, 'DELETE');
  }
  public async resetPassword(currentPassword: string, newPassword: string) {
    return this.restService.request(`rest/V1/customers/me/password`, 'PUT', {currentPassword, newPassword});
  }
  public async sendForgotPasswordEmail(email: string, template = 'email_reset', websiteId = 1) {
    return this.restService.request(`rest/V1/customers/password`, 'PUT', {email, template, websiteId});
  }
  public async updateGuestCartItem(cartID: string, item: InCartItem) {
    item.quote_id = cartID;
    return this.restService.request(`rest/V1/guest-carts/${cartID}/items/${item.item_id}`, 'PUT', {cartItem: item});
  }
  public async updateCustomerCartItem(item: InCartItem) {
    return this.restService.request(`rest/V1/carts/mine/items/${item.item_id}`, 'PUT', {cartItem: item});
  }
  public async getCustomerCartItems(): Promise<InCartItem[]> {
    return this.restService.request(`rest/V1/carts/mine/items`, 'GET');
  }
  public async getGuestCartItems(cartID: string): Promise<InCartItem[]> {
    return this.restService.request(`rest/V1/guest-carts/${cartID}/items`, 'GET');
  }
  public async getRecommendations(): Promise<IGetRecommendationsResponse[]> {
    return this.restService.request(`rest/V1/recommendations`, 'GET');
  }
  public async getRecommendation(id: string): Promise<IRecommendation> {
    return this.restService.request(`rest/V1/recommendations/${id}`, 'GET');
  }
  public async getCountries(): Promise<IProductAttributes> {
    return this.restService.request('rest/V1/wd/products/attributes/countries', 'GET');
  }
  public async getRegions(): Promise<IProductAttributes> {
    return this.restService.request('rest/V1/wd/products/attributes/regions', 'GET');
  }
  public async getGrapeVarieties(): Promise<IProductAttributes> {
    return this.restService.request('rest/V1/wd/products/attributes/grape-varieties', 'GET');
  }
  public async getCombinedProductAttributes(): Promise<ICombinedProductAttributes> {
    return this.restService.request('rest/V1/wd/products/combined-attributes', 'GET');
  }
  public async getWhishList(): Promise<ISearchResult> {
    return this.restService.request('rest/V1/mobiconnect/wishlist/getwishlist', 'GET');
  }
  public async clearWishList() {
    return this.restService.request('rest/V1/mobiconnect/wishlist/clear', 'PUT');
  }
  public async removeFromWishList(product_sku: string) {
    return this.restService.request('rest/V1/mobiconnect/wishlist/remove', 'POST', {product_sku});
  }
  public async addToWishList(product_sku: string) {
    return this.restService.request('rest/V1/mobiconnect/wishlist/add', 'POST', {product_sku});
  }
  public async getHomeProducts(pageSize = 50, page_number = 1): Promise<ISearchResult> {
    return this.elasticSearch({keywords: '*', sort: '-updated_date'}, page_number, pageSize);
  }
  public async getLatestProducts(pageSize = 50, page_number = 1): Promise<ISearchResult> {
    return this.elasticSearch({keywords: '*', sort: '-date'}, page_number, pageSize);
  }
  public async getSalesProducts(pageSize = 50, page_number = 1): Promise<ISearchResult> {
    return this.elasticSearch({sales: true}, page_number, pageSize);
  }
  public async getSpiritProducts(pageSize = 50, page_number = 1): Promise<ISearchResult> {
    return this.elasticSearch({type: 'Spirit'}, page_number, pageSize);
  }
  public async getOrganicProducts(pageSize = 50, page_number = 1): Promise<ISearchResult> {
    return this.elasticSearch({type: 'Organic'}, page_number, pageSize);
  }
  public async getNextDayDeliveryProducts(pageSize = 50, page_number = 1): Promise<ISearchResult> {
    return this.restService.request(
      `rest/V1/mobiconnect/availablenextdayproducts?searchCriteria[pageSize]=${pageSize}&searchCriteria[currentPage]=${page_number}`,
      `GET`,
    );
  }
  public async getExpressProducts(pageSize = 50, page_number = 1): Promise<ISearchResult> {
    return this.restService.request(
      `rest/V1/mobiconnect/wdexpressproducts?searchCriteria[pageSize]=${pageSize}&searchCriteria[currentPage]=${page_number}`,
      `GET`,
    );
  }
  public async sendEnquiry(email: string, name: string, telephone: string, comment: string) {
    return this.restService.request(`rest/V1/mobiconnect/enquiry`, 'POST', {
      email,
      name,
      telephone,
      comment,
    });
  }
  public async socialLogin(
    token: string,
    type: 'facebook' | 'google' | 'linkedin' | 'google-id-token' | 'apple-id',
    email = null,
    firstname = null,
    lastname = null,
  ) {
    return this.restService.request(`rest/V1/mobiconnect/sociallogin/login`, 'POST', {
      parameters: {token, email, firstname, lastname, type},
    });
  }
  public async elasticSearch(options: IElasticSearchOptions, page = 1, pageSize = 30): Promise<IElasticSearchResult> {
    const builder = new MagentoSearchQueryBuilder().setPageSize(0);
    Object.keys(options)
      .filter((key) => key !== 'keywords')
      .map((key) => {
        let value: string = options[key];
        if (value) {
          if (typeof value === 'boolean') {
            value = value ? 'true' : 'false';
          } else if (key === 'bottle_size_group') {
            switch (value) {
              case '<375ml':
                value = '0-375';
                break;
              case 'Half':
                value = '375-375';
                break;
              case '375ml - 750ml':
                value = '375-750';
                break;
              case 'Standard':
                value = '750-750';
                break;
              case '750ml - 1500ml':
                value = '750-1500';
                break;
              case 'Magnum':
                value = '1500-1500';
                break;
              case '>1500ml':
                value = '1500-99999';
                break;
              default:
                break;
            }
          } else if (key === 'grape_varieties') {
            value = encodeURIComponent(value);
          } else if (['price', 'sort'].indexOf(key) < 0) {
            value = value
              .toLowerCase()
              .replace(/[-\(\)]/gm, ' ')
              .replace(/\s\s/gm, ' ')
              .replace(/\sand\s/gm, ' ')
              // .replace(/[^a-zA-Z ]/g, ' ')
              .trim();
          }
        }
        if (key === 'bottle_size_group') {
          builder.custom('wine', 'bottle_size', value);
        } else {
          builder.custom('wine', key, value);
        }
      });
    if (options.keywords) {
      builder.custom('wine', 'q', options.keywords);
    }
    builder.custom('wine', 'currentPage', page);
    builder.custom('wine', 'pageSize', pageSize);
    return this.restService.request(`rest/V1/wd/products/search?${builder.toString()}`, 'GET');
  }
  public async getTimeSlots(store_id = 1): Promise<IGetTimeSlotResponse> {
    return this.restService.request(`rest/V1/mobiconnect/timeslots`, 'POST', {store_id});
  }
  public async getExpressCutOffTime(store_id = 1): Promise<IExpressCutOffTime> {
    return this.restService.request(`rest/V1/mobiconnect/expresscutofftime`, 'POST', {store_id});
  }
  public async getCustomerOrders(page: number, pageSize: number): Promise<IOrderSearchResult> {
    const r = await this.restService.request(`rest/V1/wd/orders?page=${page}&pageSize=${pageSize}`, 'GET');
    return r;
  }
  public async getCustomerOrderDetails(id: number): Promise<IOrder> {
    return await this.restService.request(`rest/V1/wd/orders/${id}`, 'GET');
  }
  public async changeCustomerOrderDatetime(id: string, date: string, timeslot: string): Promise<void> {
    await this.restService.request('rest/V1/mobiconnect/setdeliverytime', 'POST', {
      order_id: id,
      time_slot: timeslot,
      date,
    });
  }
  public async getProductStockStatus(productSku: string): Promise<{qty: number}> {
    const r = await this.restService.request(`rest/V1/stockStatuses/${productSku}`, 'GET');
    return r;
  }
  public async visionSearch(
    imageData: string,
  ): Promise<{checksum: string; scan_image_id: number; items: IMagentoItem[]}> {
    return this.restService.request(`rest/V1/my-discovery/scan/wine`, 'POST', {
      file: imageData,
      longitude: null,
      latitude: null,
    });
  }
  public async getMyDiscovery(): Promise<ISearchResult> {
    return this.restService.request('rest/V1/my-discovery', 'GET');
  }
  public async addToMyDiscovery(
    sku: string,
    checksum: string,
  ): Promise<{
    customer_id: number;
    image_scan_id: number;
    sku: string;
  }> {
    return this.restService.request('rest/V1/my-discovery', 'POST', {
      sku,
      checksum,
    });
  }
  public async removeFromMyDiscovery(sku: string) {
    return this.restService.request(`rest/V1/my-discovery/remove-with-sku?sku=${sku}`, 'DELETE');
  }
  public async requestForWine(sku: string): Promise<{result: boolean}> {
    return this.restService.request('rest/V1/my-discovery/notify', 'POST', {
      sku,
    });
  }
  public async getRequestedWine(): Promise<string[]> {
    return this.restService.request('rest/V1/my-discovery/notify', 'GET');
  }
  public async isEmailAvailable(options: {customerEmail: string; websiteId?: number}): Promise<boolean> {
    options = {
      websiteId: 0,
      ...options,
    };
    return this.restService.request('rest/V1/customers/isEmailAvailable', 'POST', options);
  }
  public async assignGuestCartToCustomer(cartId: any, customerId: any) {
    return this.restService.request(`rest/V1/guest-carts/${cartId}`, 'PUT', {
      customerId: customerId,
      storeId: 1,
    });
  }
  public async getCustomerSavedCreditCards(): Promise<ISavedCreditCard[]> {
    return await this.restService.request(`rest/V2/wd/my-card`, 'GET');
  }
  public async deleteCustomerSavedCreditCard(payment_method_id: string): Promise<{success: boolean}> {
    return this.restService.request(`rest/V2/wd/my-card/delete`, 'PUT', {
      payment_method_id,
    });
  }
  public async validateEmail(UserId: string, verificationKey: string) {
    return this.restService.request(`rest/V1/wd/customer/account/confirm/${UserId}/${verificationKey}`, 'GET');
  }
  public async getProductByURL(url: string): Promise<IMagentoItem> {
    return this.restService.request(`rest/V1/wd/products/url?key=${url}`, 'GET');
  }
  public async validatePasswordResetToken(customerId: string, resetPasswordLinkToken: string): Promise<boolean> {
    return this.restService.request(
      `rest/V1/customers/${customerId}/password/resetLinkToken/${resetPasswordLinkToken}`,
      'GET',
    );
  }
  public async resetPasswordByResetToken(customerId: string, reset_token: string, password: string): Promise<string> {
    return this.restService.request(`rest/V1/wd/customer/account/resetPassword`, 'POST', {
      customerId,
      reset_token,
      password,
    });
  }
  public async generateCustomerPayPalPayment(cartId: number | string): Promise<IPayPalPayment> {
    return this.restService.request(`rest/V1/carts/mine/paypal-create-payment`, 'POST', {cartId});
  }
  public async generateGuestPayPalPayment(cartId: number | string): Promise<IPayPalPayment> {
    return this.restService.request(`rest/V1/guest-carts/${cartId}/paypal-create-payment`, 'POST');
  }
  public async setSubscribeNewsletter(newsletterData: INewsletterSave) {
    return await this.restService.request('rest/V1/newsletter/save', 'POST', newsletterData);
  }
  public getSSRMainStoreCache(route = 'home'): Promise<IMagentoStore> {
    return this.restService.request(
      `api/ssr/mainStoreCache/${route}`,
      'GET',
      {},
      {
        baseUrl: getLambdaApiURL(),
      },
    );
  }
  public getLambdaApiVersion(): Promise<string | number> {
    return this.restService.requestToLambda(`api/version`, 'GET');
  }
  public postShareEvent({
    shareContentType,
    sharedTo,
    link,
    platform,
  }: {
    shareContentType: 'product' | 'recommendation';
    sharedTo: 'facebook' | 'messenger' | 'twitter' | 'copyLink' | 'email' | 'unknown';
    link: string;
    platform: string;
  }): Promise<any> {
    let email = 'UNKNOWN';
    let customerId: any = 'UNKNOWN';
    const {customer} = WD.store;
    if (customer) {
      email = customer.email;
      customerId = customer.id;
    }
    AnalyticHandler.onEvent({
      category: 'GOAL',
      action: 'SHARE',
      label: {
        shareContentType,
        sharedTo,
        link,
        platform,
        email,
        customerId,
      },
    });
    return this.restService.requestToLambda(`api/share`, 'POST', {
      shareContentType,
      sharedTo,
      link,
      platform,
      email,
      customerId,
    });
  }
  public subscribeToTopic(deviceTokens: string[], topic: string) {
    return this.restService.requestToLambda(`api/notification/subscribe`, 'POST', {
      deviceTokens,
      topic,
    });
  }
  public unsubscribeFromTopic(deviceTokens: string[], topic: string) {
    return this.restService.requestToLambda(`api/notification/unsubscribe`, 'POST', {
      deviceTokens,
      topic,
    });
  }
  public async getMyNotifications(): Promise<INotification[]> {
    const result = await this.restService.requestToLambda(`api/notification/my`, 'GET');

    return result.notifications;
  }
  public markNotificationAsRead(notificationId: string) {
    return this.restService.requestToLambda(`api/notification/markAsRead`, 'POST', {
      notificationId,
    });
  }
  public markAllNotificationAsRead() {
    return this.restService.requestToLambda(`api/notification/markAllAsRead`, 'POST');
  }
  public markNotificationAsDeleted(id: string) {
    return this.restService.requestToLambda(`api/notification/${encodeURIComponent(id)}`, 'DELETE');
  }
  public addressAutoComplete(
    text: string,
    countries: string[],
    session: string,
  ): Promise<IMyAddressAutoCompleteResponse[]> {
    return this.restService.requestToLambda(`api/maps/autoComplete`, 'POST', {text, countries, session});
  }
  public getAddressDetails(id: string): Promise<IAddressDetailsResponse> {
    return this.restService.requestToLambda(`api/maps/details/${id}`, 'GET');
  }
  public getOrderSoNumber(orderId: string): Promise<string> {
    return this.restService.request(`rest/V1/wd/incrementid/${orderId}`, 'GET');
  }
  public getSommelierRecommendation(ids: string[], {resultLength = 12} = {}): Promise<{items: IMagentoItem[]}> {
    return this.restService.requestToLambda(`api/sommelier/recommend`, 'POST', {ids, resultLength});
  }
  public getSommelierRecommendationIds(): Promise<ISommelierRecommendationIds> {
    return this.restService.requestToLambda(`api/sommelier/recommendationIds`, 'GET');
  }
  public getSommelierConfigs() {
    return this.restService.requestToLambda(`api/sommelier/configs`, 'GET');
  }
  public setSommelierConfigs(configs: any) {
    return this.restService.requestToLambda(`api/sommelier/configs`, 'POST', configs);
  }
  public getPersonalRecommendation(userId: string): Promise<{items: IMagentoItem[]}> {
    return this.restService.requestToLambda(
      `api/sommelier/personalRecommendation/${encodeURIComponent(userId)}`,
      'GET',
    );
  }
  public getRelatedItems(sku: string): Promise<{data: IMagentoItem[]}> {
    return this.restService.request(`rest/V1/relatedItems?sku=${sku}`, 'GET');
  }
  public sommelierOnPurchase(email: string, skus: string[]) {
    return this.restService.requestToLambda(`api/sommelier/onUserPurchase`, 'POST', {
      userId: email,
      skus,
    });
  }
  public async deleteCustomerAccount(data: ICustomerDeletion): Promise<any> {
    return await this.restService.request(`rest/V1/wd/account/delete`, 'POST', data);
  }
}
