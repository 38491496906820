import {Logger} from '..';

export type EventName =
  | 'onLogin'
  | 'onServerCartAbandoned'
  | 'onServerOrderDelivered'
  | 'onServerCustomerProductRestocked';

type HandlerFunction = (param?: {[key: string]: any}) => any;

export class EventHandler {
  static eventHandlers: {[key: string]: HandlerFunction[]} = {};

  static register(eventName: EventName, handler: (param?: {[key: string]: any}) => any) {
    Logger.logInfo('EVENT HANDLER', 'REGISTER', {eventName, handler});
    if (!this.eventHandlers[eventName]) {
      this.eventHandlers[eventName] = [];
    }
    this.eventHandlers[eventName].push(handler);
  }
  static async trigger(eventName: EventName, params?: {[key: string]: any}): Promise<any[]> {
    const results = [];
    if (this.eventHandlers[eventName]) {
      for (const handler of this.eventHandlers[eventName]) {
        Logger.logInfo('EVENT HANDLER', 'TRIGGERED', {eventName, handler});
        results.push(await handler(params));
      }
    }
    return results;
  }
}
