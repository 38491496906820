import { IThrowableResponse } from '@wine-delivery/wd-shared-lib';
import { ModalHelper } from './ModalHelper';

export class ThrowableResponseHelper implements IThrowableResponse {
  onSuccess: (msg?: string) => Promise<any> = async (msg) => {
    ModalHelper.showPromptGeneral(msg);
  };
  onError: (msg?: string) => Promise<any> = async (msg) => {
    ModalHelper.showPromptError(msg);
  };
  onLoading?: (msg: string) => void = async (msg) => {
    ModalHelper.showLoading(msg);
  };
  onToastNormal?: (msg: string, duration?: number) => any = async (msg) => {
    ModalHelper.showToast(msg);
  };
  onToastSuccess?: (msg: string, duration?: number) => any = async (msg) => {
    ModalHelper.showToast(msg);
  };
  onToastFail?: (msg: string, duration?: number) => any = async (msg) => {
    ModalHelper.showToast(msg);
  };
  onToastLoading?: (msg: string, duration?: number) => any = async (msg) => {
    ModalHelper.showToast(msg);
  };
  closeModal?: () => any = async () => {
    ModalHelper.close();
  };
}
