import React from 'react';
import Button from '@mui/material/Button';
import { AnalyticHandler, COLOR, FONT } from '@wine-delivery/wd-shared-lib';
import { Icon } from '../Icon';
import { Touchable } from '../Touchable';
import LoadingIcon from './LoadingIcon';

type Props = {
  id?: string;
  label: string | React.ReactNode;
  style?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset';
  containerStyle?: React.CSSProperties;
  outlined?: boolean;
  onlyButton?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onPress?: (e?: any) => void;
  options?: {
    hasIconLeft?: boolean;
    iconLeft?: string;
    iconLeftStyle?: React.CSSProperties;
    hasIconRight?: boolean;
    iconRight?: string;
    iconRightStyle?: React.CSSProperties;
  };
};

export function AppButton({
  id,
  label,
  type,
  style,
  containerStyle,
  outlined,
  onlyButton,
  disabled,
  isLoading,
  onPress,
  options = {},
}: Props): JSX.Element {
  const { hasIconLeft, hasIconRight, iconLeft, iconRight, iconLeftStyle, iconRightStyle } = options;

  const isDisabled = disabled || isLoading;

  if (onlyButton) {
    return (
      <Button
        id={id}
        variant={outlined ? 'outlined' : 'contained'}
        type={type}
        style={{
          ...styles.button,
          backgroundColor: outlined ? 'transparent' : isDisabled ? COLOR.LIGHT_GREY_2 : COLOR.TOSCA,
          color: outlined ? COLOR.TOSCA : isDisabled ? COLOR.DARK_GREY : COLOR.WHITE,
          borderColor: outlined ? COLOR.TOSCA : undefined,
          ...style,
        }}
        disabled={isDisabled}
        startIcon={hasIconLeft ? <Icon name={iconLeft || ''} style={{ ...iconLeftStyle }} /> : undefined}
        endIcon={hasIconRight ? <Icon name={iconRight || ''} style={{ ...iconRightStyle }} /> : undefined}>
        {label}
        {isLoading && <LoadingIcon />}
      </Button>
    );
  }

  return (
    <Touchable
      style={containerStyle}
      onClick={async (e) => {
        if (isDisabled) {
          return;
        }
        AnalyticHandler.onEvent({
          category: 'BUTTON',
          action: 'CLICK',
          label: typeof label === 'string' ? label : '',
        });
        onPress(e);
      }}>
      <Button
        id={id}
        variant={outlined ? 'outlined' : 'contained'}
        disabled={isDisabled}
        style={{
          ...styles.button,
          backgroundColor: outlined ? 'transparent' : isDisabled ? COLOR.LIGHT_GREY_2 : COLOR.TOSCA,
          color: outlined ? COLOR.TOSCA : isDisabled ? COLOR.DARK_GREY : COLOR.WHITE,
          borderColor: outlined ? COLOR.TOSCA : undefined,
          ...style,
        }}
        startIcon={hasIconLeft ? <Icon name={iconLeft || ''} style={{ ...iconLeftStyle }} /> : undefined}
        endIcon={hasIconRight ? <Icon name={iconRight || ''} style={{ ...iconRightStyle }} /> : undefined}>
        {label}
        {isLoading && <LoadingIcon />}
      </Button>
    </Touchable>
  );
}

const styles = {
  button: {
    fontFamily: FONT.MAIN_MEDIUM,
    textTransform: 'none',
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 500,
    padding: '10px 36px',
    borderRadius: 8,
    position: 'relative',
  } as React.CSSProperties,
};

AppButton.displayName = 'AppButton';
