import { sleep } from '@wine-delivery/wd-shared-lib';
import React from 'react';
import { AppModal } from './AppModal';

interface Props {
  style?: React.CSSProperties;
}

type ModalPopupSide = 'center' | 'bottom';
interface States {
  content: any;
  hidden: boolean;
  canDismiss: boolean;
  padding: number | string;
  containerStyle?: React.CSSProperties;
  phoneContainerStyle?: React.CSSProperties;
  side?: ModalPopupSide;
  initial?: boolean;
  showCloseIcon?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
}

export interface ModalOptions {
  canDismiss?: boolean;
  containerStyle?: React.CSSProperties;
  phoneContainerStyle?: React.CSSProperties;
  padding?: number | string;
  side?: ModalPopupSide;
  showCloseIcon?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  fullscreen?: boolean;
  backgroundColor?: 'grey' | 'white' | 'dark';
}

export class ModalDispatcher extends React.Component<Props, States> {
  static defaultProps = {};
  state: States = {
    content: undefined,
    hidden: true,
    canDismiss: true,
    padding: 16,
    initial: true,
    showCloseIcon: true,
  };
  body: HTMLDivElement;
  dispatch(
    content,
    {
      canDismiss = true,
      padding = 16,
      containerStyle = {},
      phoneContainerStyle = {},
      side = 'center',
      showCloseIcon,
      showHeader = false,
      showFooter = false,
    }: ModalOptions,
  ) {
    this.setState({
      content,
      hidden: false,
      initial: false,
      canDismiss,
      padding,
      containerStyle,
      phoneContainerStyle,
      side,
      showCloseIcon,
      showHeader,
      showFooter,
    });
  }

  async close() {
    const { content, hidden } = this.state;
    if (content || !hidden) {
      this.setState({ hidden: true });
      await sleep(300);
      this.setState({ content: undefined });
    }
  }

  render() {
    const {
      content,
      canDismiss,
      initial,
      hidden,
      containerStyle,
      phoneContainerStyle,
      showHeader,
      showFooter,
      padding,
      showCloseIcon,
    } = this.state;

    return (
      <AppModal
        id="wdc-modal"
        isOpen={!hidden}
        showCloseIcon={showCloseIcon}
        noFooter={!showFooter}
        webContainerStyle={{ padding, ...containerStyle }}
        phoneContainerStyle={{ padding, ...phoneContainerStyle }}
        onClose={() => {
          if (canDismiss) {
            this.close();
          }
        }}>
        {content}
      </AppModal>
    );
  }
}
