// import RNFetchBlob from 'react-native-fetch-blob';
import {CacheData, ICacheSystem, IMagentoStore, ProcessType, ThreadHandler, Timer, sleep} from '..';
import {Logger} from './Logger';

export class CacheHandler {
  cachedData: {[key: string]: string} = {};

  diskLoadCount: {[key: string]: number} = {};

  processing: {[key: string]: boolean} = {};

  private get threadHandler(): ThreadHandler {
    return this.store.threadHandler;
  }

  constructor(private cacheSystem: ICacheSystem, private store: {threadHandler: ThreadHandler}) {}
  async getCache(
    key: string,
    expireMs: number,
    {
      onNotFound,
      onUsedCache,
      inMemoryCache = true,
    }: {onNotFound?: () => Promise<any>; onUsedCache?: (result: any) => any; inMemoryCache?: boolean} = {},
  ) {
    // console.log("### getCache key: ", key);
    let data = this.cachedData[key];
    let isCache = false;
    const timer = new Timer(key);
    // console.log("### getCache data1: ", data);
    if (data) {
      isCache = true;
    }
    if (!data) {
      data = await this.loadFromDisk(key, expireMs);
      // console.log("### getCache data2: ", data);
      if (data) {
        // console.log("loaded from disk");
        // console.log("### getCache inMemoryCache: ", inMemoryCache);
        if (inMemoryCache) {
          this.cachedData[key] = data;
        }
        isCache = true;
      }
    }
    // console.log("### getCache data3: ", data);
    if (!data) {
      Logger.logError('[CACHE_HANDLER]', '[CACHE_NOT_FOUND]', key);
      if (onNotFound) {
        data = await onNotFound();
        // console.log("### getCache data4: ", data);
        if (data) {
          this.saveToDisk(key, data, {inMemoryCache});
        }
        // console.log("using function");
      }
    }
    // console.log("### getCache isCache: ", isCache);
    // console.log("### getCache onUsedCache: ", onUsedCache);
    if (isCache && onUsedCache) {
      onUsedCache(data);
    }
    // timer.print();
    // console.log("### getCache data5: ", isCache);
    return data;
  }
  private isProcessing(path: string) {
    return this.processing[path];
  }
  private setProcessing(path: string, boo: boolean) {
    this.processing[path] = boo;
  }
  private async waitForProcessing(path: string) {
    while (this.isProcessing(path)) {
      console.log('waiting..');
      await sleep(50);
    }
    return;
  }
  public async saveToDisk(key: string, data: string, {inMemoryCache = true} = {}) {
    if (inMemoryCache) {
      this.cachedData[key] = data;
    }
    const str = JSON.stringify(new CacheData(data));
    try {
      await this.cacheSystem.save(key, str);
    } catch (err) {
      Logger.logInfo('ERR SAVE TO DISK', key, data, err + '');
    }
  }
  public async deleteFromDisk(key: string) {
    await this.cacheSystem.delete(key);
  }
  public async loadFromDiskSequential(key: string, expireMs = -1) {
    const processType = ProcessType.DYNAMIC(key);
    if (this.threadHandler.isProcessQueueEmpty(processType)) {
      return await this.threadHandler.runSequential(processType, async () => {
        const result = await this.getCache(key, expireMs);
        this.cachedData[key] = result;
        return result;
      });
    } else {
      return await this.threadHandler.runSequential(processType, async () => {
        return this.cachedData[key];
      });
    }
  }

  private async loadFromDisk(key: string, expireMs = -1) {
    let str;

    try {
      str = await this.cacheSystem.get(key);
    } catch (e) {
      Logger.logError('[CACHE_HANDLER]', 'Failed to load', key);
    }
    if (str) {
      try {
        if (this.diskLoadCount[key]) {
          this.diskLoadCount[key]++;
          console.log('loaded', this.diskLoadCount[key], 'times', key);
        } else {
          this.diskLoadCount[key] = 1;
        }
        const cacheData = new CacheData();
        const obj = JSON.parse(str);
        Object.assign(cacheData, obj);
        // console.log(cacheData.getDate().fromNow());
        if (expireMs > 0 && cacheData.isExpired(expireMs)) {
          console.log('Cache found but is expired. returning null.');
          return null;
        }

        if (cacheData.isUpToDate() === false) {
          console.log('Cache found but is of old version. returning null.');
          return null;
        }
        return cacheData.getData();
      } catch (e) {
        console.log('ERROR While trying to load cache from disk', e);
      }
    }
    return null;
  }
  async clear() {
    try {
      this.cachedData = {};
      return await this.cacheSystem.clear();
    } catch (e) {
      Logger.logError('CACHE SYSTEM', 'Clear cache failed', e);
    }
  }
}
