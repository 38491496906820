import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AdminStoreProvider, StoreProvider } from './Store/ProviderStore';
import { wdStore } from './Setup';
import { Provider } from 'mobx-react';
import { ADMIN_STORE } from './Store/AdminStore';
declare module '*.png';
declare module '*.jpg';

ReactDOM.render(
  <React.StrictMode>
    <Provider wdStore={wdStore} adminStore={ADMIN_STORE}>
      <StoreProvider value={wdStore}>
        <AdminStoreProvider value={ADMIN_STORE}>
          <App />
        </AdminStoreProvider>
      </StoreProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
