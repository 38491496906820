import {
  IAnalyticAdaptor,
  IAnalyticEvent,
  IAnalyticTiming,
  Logger,
  MagentoItem,
  WD,
  Wine,
  AnalyticHandler,
} from '@wine-delivery/wd-shared-lib';
// import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';
import { getUrlQueryString } from './NavigationHelper';

let lastPath = 'NO PATH';
export default class AnalyticAdaptor implements IAnalyticAdaptor {
  constructor(id: string, appName: string) {
    Logger.logInfo('ANALYTIC', 'INITIALIZE', id);
    ReactGA.initialize(id, {
      gaOptions: {
        siteSpeedSampleRate: 100,
      },
      // debug: true,
    });

    // this.custom('set', 'appName', appName);
    ReactGA.ga('set', 'appName', appName);
    // ReactGA.plugin.require('ec');
  }
  onPageView(path: string, history: any) {
    if (lastPath !== path) {
      console.log('onPageView', path);
      let custom: any;
      const user = WD.store.customer;
      if (user) {
        custom = {
          userDisplayName: `${user.firstname} ${user.lastname}`,
          userType: 'Registered',
          email: user.email,
        };
      } else {
        custom = {
          userDisplayName: `Guest`,
          userType: 'Guest',
          email: 'UNKNOWN',
        };
      }
      const referrer = getUrlQueryString(history)['referrer'];
      if (referrer) {
        this.setReferrer(referrer);
        Logger.logInfo('AnalyticAdaptor', 'REFERER SET', referrer);
      }
      //@ts-ignore
      ReactGA.ga('send', 'pageview', path, custom);
      lastPath = path;
    }
  }
  onModalView(modalName: string) {
    // ReactGA.modalview(modalName);
  }
  onEvent(event: IAnalyticEvent) {
    ReactGA.event(event as any);
  }
  timing(timing: IAnalyticTiming) {
    // ReactGA.timing(timing);
  }
  custom(...args: any[]) {
    //@ts-ignore
    ReactGA.ga(args);
  }

  private addProduct(item: MagentoItem, quantity = 1) {
    // ReactGA.plugin.execute('ec', 'addProduct', {
    //   ...AnalyticHandler.getProductAttributes(item, quantity),
    // });
    ReactGA.event('add_to_cart', {
      items: [AnalyticHandler.getProductAttributes(item, quantity)],
    });
  }
  private setAction(
    item: MagentoItem | MagentoItem[],
    actionName: string,
    options: any = undefined,
    { quantity = 1 } = {},
  ) {
    if (item instanceof Array) {
      item.forEach((i) => this.addProduct(i, quantity));
    } else {
      this.addProduct(item, quantity);
    }
    console.log('ANALYTIC', actionName, options);
    //@ts-ignore
    ReactGA.ga(`ec:setAction`, actionName, options);
    // ReactGA.plugin.execute('ec', 'setAction', actionName, options);
  }
  onProductAddToCart(item: MagentoItem, quantity: number) {
    this.setAction(item, 'add', {}, { quantity });
  }
  onProductRemoveFromCart(item: MagentoItem) {
    this.setAction(item, 'remove');
  }
  onProductCheckout(item: MagentoItem) {
    this.setAction(item, 'checkout');
  }
  onProductPurchase(items: MagentoItem[], data: any) {
    this.setAction(items, 'purchase', data);
  }
  onProductViewDetail(item: MagentoItem) {
    this.onEvent({ category: 'PRODUCT', action: 'VIEW', label: item.getSku(), value: 1 });
    this.setAction(item, 'detail');
  }
  onProductImpression(item: MagentoItem) {
    // this.onEvent({ category: "PRODUCT", action: "IMPRESSION", label: item.name, value: 1 });
    // ReactGA.plugin.execute('ec', 'addImpression', {
    //   ...AnalyticHandler.getProductAttributes(item),
    // });
    ReactGA.event('view_item', {
      items: [AnalyticHandler.getProductAttributes(item)],
    });
  }

  setReferrer(url: string | string[]) {
    ReactGA.set({ referrer: url });
  }
  clearReferrer() {
    this.setReferrer(window.location.origin);
  }
}
