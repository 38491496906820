import {
  CartActions,
  IAddress,
  ICartInfo,
  ICustomer,
  IStandardResponse,
  WD,
  ProcessType,
  ICreateCustomerResponse,
  GuestCart,
} from '..';
import {AVAILABLE_SHIPPING_COUNTRIES, getPlatform, Platform} from '../Config';
import {
  getCountryFullName,
  setCustomAttribute,
  getCustomAttribute,
  getCustomAttributeBoolean,
  deepClone,
} from '../Helper/Helper';
import {AnalyticHandler} from '../Handler/AnalyticHandler';
import {CustomerCustomAttribute} from '../Interface/ICustomer';

export class CustomerActions {
  public static async updateAddress(address: IAddress, index: number) {
    address = deepClone(address);
    const newCustomer: ICustomer = deepClone(WD.store.customer);
    newCustomer.addresses[index] = {...address};
    await this.updateCustomer(newCustomer as any);
  }
  public static async addAddress(address: IAddress, options?: {defaultBilling?: boolean; defaultShipping?: boolean}) {
    options = {
      defaultBilling: true,
      defaultShipping: true,
      ...options,
    };
    const newCustomer: ICustomer = deepClone(WD.store.customer);
    // if (newCustomer.addresses.length === 0) {
    address.default_billing = !!options.defaultBilling;
    if (AVAILABLE_SHIPPING_COUNTRIES.indexOf(getCountryFullName(address.country_id)) >= 0 && options.defaultShipping) {
      address.default_shipping = !!options.defaultShipping;
    }
    // }
    newCustomer.addresses.push(address);
    await this.updateCustomerSync(newCustomer as any);
  }
  public static async removeAddress(address: IAddress) {
    const newCustomer = deepClone(WD.store.customer);
    newCustomer.addresses = newCustomer.addresses.filter((a) => a.id !== address.id);
    await this.updateCustomer(newCustomer);
  }
  public static async setDefaultShipping(address: IAddress, boo = true) {
    const newCustomer = deepClone(WD.store.customer);
    newCustomer.addresses.forEach((a) => (a.default_shipping = false));
    newCustomer.default_shipping = null;
    if (address) {
      newCustomer.addresses.find((a) => a.id === address.id).default_shipping = boo;
      if (address.id) {
        newCustomer.default_shipping = address.id as any;
      }
    }
    await this.updateCustomer(newCustomer);
  }
  public static async setDefaultBilling(address: IAddress, boo = true) {
    const newCustomer = deepClone(WD.store.customer);
    newCustomer.addresses.forEach((a) => (a.default_billing = false));
    newCustomer.default_billing = null;
    if (address) {
      newCustomer.addresses.find((a) => a.id === address.id).default_billing = boo;
      if (address.id) {
        newCustomer.default_billing = address.id as any;
      }
    }
    await this.updateCustomer(newCustomer);
  }
  static async updateCustomerSync(customer: ICustomer) {
    const undo = deepClone(WD.store.customer);
    // WD.store.customer = customer;
    await WD.store.threadHandler.runSequential(ProcessType.CUSTOMER, async () => {
      try {
        const result = await WD.store.service.updateCustomer({customer: customer} as any);
        WD.store.customer = result;
      } catch (e) {
        WD.store.customer = undo;
        throw e;
      }
    });

    return;
  }
  static async updateCustomer(customer: ICustomer) {
    const undo = deepClone(WD.store.customer);
    WD.store.customer = customer;
    let result = undefined;
    await WD.store.threadHandler.runSingular(
      ProcessType.CUSTOMER,
      async () => {
        try {
          result = await WD.store.service.updateCustomer({customer: customer} as any);
        } catch (e) {
          WD.store.customer = undo;
          result = undefined;
          throw e;
        }
      },
      {
        onLast: () => {
          if (result) {
            WD.store.customer = result;
          }
        },
        throttle: 2000,
      },
    );

    return;
  }
  public static async socialLogin(
    type: 'facebook' | 'google' | 'linkedin' | 'google-id-token',
    token: string,
  ): Promise<void> {
    AnalyticHandler.onEvent({category: 'CUSTOMER', action: `SOCIAL_LOGIN_${type.toUpperCase()}`, label: token});
    WD.store.isInLogginProcess = true;
    const result = await WD.store.service.socialLogin(token, type);
    await this.loginWithToken(result);
  }
  public static async loginUser(userName: string, password: string) {
    AnalyticHandler.onEvent({category: 'CUSTOMER', action: 'EMAIL_LOGIN', label: userName});
    WD.store.isInLogginProcess = true;
    const token: IStandardResponse = await WD.store.service.login(userName, password);
    if (token.message) {
      return {success: false, msg: token.message, token: ''};
    }
    return await this.loginWithToken(token as any);
  }
  public static async loginWithToken(token: string) {
    try {
      // refresh cart info first to handle redirection from web social login
      await CartActions.refresh();
      if (WD.store.cartInfo && WD.store.cartInfo.items.length > 0) {
        WD.store.isMovingItemToCart = true;
        const itemInfo: ICartInfo = deepClone(WD.store.cartInfo);
        WD.store.throwableResponse.onLoading(WD.store.appLocale.customer_web.login.moving_items_to_cart);
        // const cartId = WD.store.guestCartID;
        CartActions.setGuestCartID('');
        console.log('saving token to disk', WD.getTokenCacheKey(), token);
        WD.store.cacheHandler.saveToDisk(WD.getTokenCacheKey(), token + '');
        const isValidToken = await WD.isUserLoggedIn();
        if (isValidToken) {
          await CartActions.refresh(isValidToken);

          // const result = await WD.store.service.assignGuestCartToCustomer(cartId, WD.store.customer.id);
          // console.log(result);
          for (const item of itemInfo.items) {
            WD.store.throwableResponse.onLoading(`Moving ${item.name} to your cart`);
            if (item.sku.indexOf('Gift') >= 0) {
              const giftMessage: string = await WD.getProductOption(item, 'Gift Message');
              console.log(giftMessage);
              await CartActions.addItemToCart(item.sku, 1, giftMessage);
            } else {
              await CartActions.addItemToCart(item.sku, item.qty);
            }
          }
          WD.store.throwableResponse.onLoading(`All done!`);
          WD.store.throwableResponse.onSuccess();
        }
        WD.store.isMovingItemToCart = false;
      } else {
        WD.store.cacheHandler.saveToDisk(WD.getTokenCacheKey(), token + '');
        await CartActions.refresh();
      }
      WD.store.throwableResponse.closeModal();
      WD.store.throwableResponse.onToastSuccess(WD.store.appLocale.customer_web.login.welcome_to_wd);
      WD.store.isInLogginProcess = false;
      return {success: true, token: token + '', msg: ''};
    } catch (e) {
      WD.store.throwableResponse.closeModal();
      WD.store.isInLogginProcess = false;
      throw e;
    }
  }
  public static async logout() {
    WD.store.throwableResponse.onLoading('Logging out..');
    await WD.store.cacheHandler.saveToDisk(WD.getTokenCacheKey(), '');
    await CartActions.refresh();
    WD.store.myNotifications = [];
    WD.store.throwableResponse.closeModal();
    // await WD.store.throwableResponse.onSuccess();
  }
  public static async refreshOrders() {
    WD.store.ordersCurrentPage = 1;
    WD.store.ordersPageSize = 10;
    const service = await WD.getTokenizedService();
    const orders = await service.getCustomerOrders(WD.store.ordersCurrentPage, WD.store.ordersPageSize);
    WD.store.orders = orders.items;
    WD.store.ordersCount = orders.total_count;
  }
  public static async getNextOrders() {
    const service = await WD.getTokenizedService();
    const orders = await service.getCustomerOrders(WD.store.ordersCurrentPage, WD.store.ordersPageSize);
    WD.store.orders = [...WD.store.orders, ...orders.items];
  }
  public static async subscribeToWD(boo: boolean) {
    const newCustomer = WD.setCustomAttribute(WD.store.customer, 'subscribe_wd', boo ? 1 : 0);
    await CustomerActions.updateCustomer(newCustomer as any);
  }
  public static async subscribeToWDPartners(boo: boolean) {
    const newCustomer = WD.setCustomAttribute(WD.store.customer, 'subscribe_partner', boo ? 1 : 0);
    await CustomerActions.updateCustomer(newCustomer as any);
  }
  public static async subscribeToNewsletter(customer_id: number, subscribe_wd: number, subscribe_partner: number) {
    const response = await WD.store.service.setSubscribeNewsletter({
      newsletter: {customer_id, subscribe_wd, subscribe_partner},
    });
    WD.store.customer.custom_attributes = response.data.custom_attributes;
  }
  public static async saveNotification(customer_id: number, attribute: CustomerCustomAttribute, value: number) {
    const customer = deepClone(WD.store.customer);
    const newCustomer = {...customer};
    setCustomAttribute(newCustomer, attribute, value.toString());
    WD.store.customer = newCustomer;
    try {
      const response = await WD.store.service.setSubscribeNewsletter({newsletter: {customer_id, [attribute]: value}});
      WD.store.customer.custom_attributes = response.data.custom_attributes;
    } catch (e) {
      WD.store.customer = customer;
    }
  }
  public static async registerUser(options: {
    userName: string;
    email: string;
    password: string;
    firstname: string;
    lastname: string;
    receivePartner: 1 | 0;
    receiveWineDelivery: 1 | 0;
    agree: 1 | 0;
  }): Promise<ICreateCustomerResponse> {
    AnalyticHandler.onEvent({category: 'CUSTOMER', action: 'SIGN_UP', label: JSON.stringify(options)});
    const customer: ICreateCustomerResponse = {
      customer: {
        email: options.email,
        firstname: options.firstname,
        lastname: options.lastname,
        custom_attributes: [
          {
            attribute_code: 'subscribe_partner',
            value: options.receivePartner ? 1 : 0,
          },
          {
            attribute_code: 'subscribe_wd',
            value: options.receiveWineDelivery ? 1 : 0,
          },
          {
            attribute_code: 'is_subscribed',
            value: options.agree ? 1 : 0,
          },
        ],
      },
      password: options.password,
    } as any;
    return await WD.registerUser(customer);
  }
  public static async refreshSavedCreditCards() {
    WD.store.savedCreditCards = await WD.store.service.getCustomerSavedCreditCards();
  }
  public static async removeSavedCreditCard(cardId: string) {
    WD.store.savedCreditCards = WD.store.savedCreditCards.filter((c) => c.payment_method_id !== cardId);
    await WD.store.service.deleteCustomerSavedCreditCard(cardId);
  }
  public static async verifyEmail(userId: string, key: string) {
    return WD.store.service.validateEmail(userId, key);
  }
  public static async validatePasswordResetToken(userId: string, resetToken: string) {
    return WD.store.service.validatePasswordResetToken(userId, resetToken);
  }
  public static async resetPasswordByResetToken(
    customerId: string,
    reset_token: string,
    password: string,
  ): Promise<string> {
    return WD.store.service.resetPasswordByResetToken(customerId, reset_token, password);
  }
  public static async setCustomAttribute(attribute: CustomerCustomAttribute, value: string) {
    const newCustomer: ICustomer = deepClone(WD.store.customer);
    setCustomAttribute(newCustomer, attribute, value);
    await this.updateCustomer(newCustomer);
  }
  public static setCustomAttributeBoolean(attribute: CustomerCustomAttribute, boo: boolean) {
    return this.setCustomAttribute(attribute, boo ? '1' : '0');
  }
  public static getCustomAttribute(attribute: CustomerCustomAttribute) {
    return getCustomAttribute(WD.store.customer, attribute);
  }
  public static getCustomAttributeBoolean(attribute: CustomerCustomAttribute) {
    return getCustomAttributeBoolean(WD.store.customer, attribute);
  }
  public static async setDeviceToken(platform: Platform, token: string) {
    let attribute: CustomerCustomAttribute;
    switch (platform) {
      case 'android': {
        attribute = 'device_token_android';
        break;
      }
      case 'ios': {
        attribute = 'device_token_ios';
        break;
      }
      case 'web': {
        attribute = 'device_token_web';
        break;
      }
    }
    await this.setCustomAttribute(attribute, token);
  }
  public static getAllTokens() {
    const tokens: string[] = [];
    const web = this.getCustomAttribute('device_token_web');
    const android = this.getCustomAttribute('device_token_android');
    const ios = this.getCustomAttribute('device_token_ios');
    if (web) tokens.push(web);
    if (android) tokens.push(android);
    if (ios) tokens.push(ios);
    return tokens;
  }
  public static sendSommelierOnPurchaseEvent(skus: string[]) {
    let email = 'GUEST';
    const {customer} = WD.store;
    if (customer) {
      email = customer.email;
    }
    WD.store.service.sommelierOnPurchase(email, skus);
  }
  public static async changeDeliveryDatetime(orderId: string, date: string, timeslot: string) {
    WD.store.service.changeCustomerOrderDatetime(orderId, date, timeslot);
  }
  public static async deleteCustomerAccount() {
    const data = WD.store.accountDeletionReason;
    const account = await WD.store.service.deleteCustomerAccount(data);
    return account;
  }
}
