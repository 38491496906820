import {IAnalyticAdaptor, WD, MagentoItem, Logger, Wine, LOADING_MESSAGES, IPaymentInformation} from '..';
import {getBuildType, getPlatform} from '../Config';
import {getKeyValuePairs, sleep, randomFromList, getDayOfWeek, getDaysInBetween, getPriceRange} from '../Helper/Helper';

export type EventCategory =
  | 'CART'
  | 'CUSTOMER'
  | 'PRODUCT'
  | 'CONVERSION'
  | 'BUTTON'
  | 'LINK'
  | 'OTHER'
  | 'BROWSE'
  | 'INPUT'
  | 'ERROR'
  | 'DATA_LOG'
  | 'GOAL'
  | 'ERROR'
  | 'IMAGE_ICON'
  | 'WINE_BADGE'
  | 'TOGGLE_BUTTON'
  | 'SEARCH_FILTER_MENU'
  | 'SIDEBAR_MENU';
export interface IAnalyticEvent {
  category: EventCategory;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: string;
}
export interface IAnalyticTiming {
  category: string;
  variable: string;
  value: number;
  label?: string;
}
let lastPath = 'NO PATH';

interface EventParam {
  category: EventCategory;
  action: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: string;
  label?: string | {[key: string]: string | boolean};
}
export class AnalyticHandler {
  static adaptor: IAnalyticAdaptor;

  static setAdaptor(adaptor: IAnalyticAdaptor) {
    this.adaptor = adaptor;
  }
  static onPageView(path: string) {
    if (lastPath !== path) {
      this.tryPerformTask(() => {
        Logger.logInfo('[ANALYTIC]', 'PAGE_VIEW', path);
        this.adaptor.onPageView(path);
        lastPath = path;
      });
    }
  }

  static onModalView(modalName: string) {
    this.tryPerformTask(() => {
      Logger.logInfo('[ANALYTIC]', 'MODAL_VIEW', modalName);
      this.adaptor.onModalView(modalName);
    });
  }
  static onEvent(event: EventParam) {
    this.tryPerformTask(() => {
      if (event.value) {
        event.value = Math.round(event.value);
      }
      Logger.logInfo('[ANALYTIC]', 'EVENT', event);
      this.adaptor.onEvent({...event, label: this.toSimpleKeyValuePair(event.label)});
    });
  }
  static timing(timing: IAnalyticTiming) {
    this.tryPerformTask(() => {
      Logger.logInfo('[ANALYTIC]', 'TIMING', timing);
      this.adaptor.timing(timing);
    });
  }
  static custom(...args) {
    this.tryPerformTask(() => {
      Logger.logInfo('[ANALYTIC]', 'CUSTOM', args);
      this.adaptor.custom(...args);
    });
  }
  static onProductAddToCart(sku: string, amount: number) {
    this.tryPerformTask(() => {
      const item = WD.getItem(sku);
      Logger.logInfo('[ANALYTIC]', 'ADD_TO_CART', sku, amount);
      this.adaptor.onProductAddToCart(item, amount);
    });
  }
  static onProductRemoveFromCart(itemId: number) {
    this.tryPerformTask(() => {
      const cartItem = WD.store.cartInfo.items.find((i) => i.item_id === itemId);
      if (cartItem) {
        const sku = cartItem.sku;
        const item = WD.getItem(sku);
        Logger.logInfo('[ANALYTIC]', 'REMOVE_FROM_CART', sku);
        this.adaptor.onProductRemoveFromCart(item);
      }
    });
  }
  static onProductCheckout() {
    this.tryPerformTask(() => {
      const cartInfo = WD.store.cartInfo;
      const items = cartInfo.items.map((i) => WD.getItem(i.sku));
      items.forEach((i) => {
        this.adaptor.onProductCheckout(i);
        Logger.logInfo('[ANALYTIC]', 'CHECKOUT', i.getSku());
      });
    });
  }
  static async onProductPurchase(orderId: string, info: IPaymentInformation) {
    await this.tryPerformTask(async () => {
      if (this.adaptor) {
        WD.store.throwableResponse.onLoading(`${randomFromList(LOADING_MESSAGES)}`);
        const cartTotal = WD.store.cartTotal;
        const cartInfo = WD.store.cartInfo;
        const total = cartTotal.base_grand_total;
        AnalyticHandler.onEvent({
          category: 'CONVERSION',
          action: 'PURCHASE',
          value: total,
        });
        const items = WD.store.cartInfo.items.map((i) => WD.getItem(i.sku));
        Logger.logInfo('[ANALYTIC]', 'PURCHASE', orderId);
        this.adaptor.onProductPurchase(items, {
          id: orderId, // (Required) Transaction id (string).
          affiliation: `${getPlatform()} ${getBuildType()}`, // Affiliation (string).
          revenue: cartTotal.base_grand_total, // Revenue (currency).
          tax: cartTotal.tax_amount, // Tax (currency).
          shipping: cartTotal.shipping_amount, // Shipping (currency).
          coupon: cartTotal.coupon_code, // Transaction coupon (string).
        });
        const wines = items.filter((i) => i.isWine());
        const numberOfBottles = cartInfo.items_qty;
        const gift = items.find((i) => i.isGift());
        const withGiftCard = !!gift;
        const isLoggedIn = !!WD.store.customer;
        const userType = isLoggedIn ? 'Logged in user' : 'Guest';
        const couponCode = cartTotal.coupon_code;
        const withCoupon = !!couponCode;
        const shippingExtension = cartInfo.extension_attributes.shipping_assignments[0];
        const billingAddress = cartInfo.billing_address;
        const discountAmount = cartTotal.discount_amount;
        const deliveryDate = WD.getSelectedDeliveryDate();
        const purchasedDate = new Date();
        const deliveryDayOfWeek = getDayOfWeek(deliveryDate);
        const purchasedDayOfWeek = getDayOfWeek(purchasedDate);
        const daysToDeliveryDate = `${getDaysInBetween(purchasedDate, deliveryDate)}_DAY`;
        let billingCountry = 'UNKNOWN';
        let deliveryCountry = 'UNKNOWN';
        let giftCardName = 'NO_GIFT_CARD';
        let email = 'NOT_LOGIN';
        if (gift) {
          giftCardName = gift.getName();
        }
        if (isLoggedIn) {
          email = WD.store.customer.email;
        }
        if (shippingExtension) {
          deliveryCountry = shippingExtension.shipping.address.country_id;
        }
        if (billingAddress) {
          billingCountry = billingAddress.country_id;
        }
        // SOMMELIER ON PURCHASE
        WD.store.service.sommelierOnPurchase(
          info.email || email,
          wines.map((w) => w.data.sku),
        );

        // const orderLog = {
        //     numberOfBottles, withGiftCard, couponCode, withCoupon, giftCardName,
        //     orderId: orderId + "",
        //     tax: cartTotal.tax_amount,
        //     email, deliveryCountry, billingCountry, discountAmount, userType,
        //     deliveryDayOfWeek, purchasedDayOfWeek, daysToDeliveryDate
        // }

        // GET THE ITEM FROM CART BEFORE IT'S GONE
        // const cartItems = WD.getCartItems();

        // await this.bulkDataLog("ORDER", orderLog, total);
        // for (const { inCartItem, item, total } of cartItems) {
        //     // REMOVE FOR NOW BECAUSE CHECKOUT IS TAKING TOO MUCH TIME
        //     // for (let i = 0; i < inCartItem.qty; i++) {
        //     //     WD.store.throwableResponse.onLoading(`${randomFromList(LOADING_MESSAGES)}`)
        //     //     let itemLog: any = { ...this.getProductAttributes(item, 1) };
        //     //     if (item.isWine()) {
        //     //         const wine: Wine = item as Wine;
        //     //         const earliestDeliveryDate = wine.getEarliestDeliveryDate();
        //     //         const daysToEarliestDeliveryDate = `${getDaysInBetween(purchasedDate, earliestDeliveryDate)}_DAY`;
        //     //         const priceRange = getPriceRange(wine.getPrice());
        //     //         itemLog = { ...itemLog, daysToEarliestDeliveryDate, priceRange }
        //     //     }
        //     //     await this.bulkDataLog("PURCHASE_ITEM", itemLog, total.row_total);
        //     // }
        //     if (item.isWine()) {
        //         const wine: Wine = item as Wine;
        //         const priceRange = getPriceRange(wine.getPrice());
        //         const discountType = wine.getPriceInfo(inCartItem.qty).cheapest.type;
        //         await this.bulkDataLog("PURCHASE_ITEM_SINGLE", { priceRange, discountType }, total.row_total);
        //     }
        // }
      }
    });
  }
  private static async bulkDataLog(
    action: string,
    obj: {[key: string]: string | number | boolean},
    defaultValue: number,
  ) {
    const pairs = getKeyValuePairs(obj);
    for (let {key, value} of pairs) {
      key = this.camelCaseToUppercase(key);
      const type = typeof value;
      if (type === 'boolean') {
        if (value) {
          value = `${action}_${key}`;
        } else {
          value = `${action}_${key.replace('HAS_', 'HAS_NO_').replace('IS_', 'IS_NOT_').replace('WITH_', 'WITHOUT_')}`;
        }
        this.dataLog(`${action}`, `${action}_${key}`, '' + value, defaultValue);
      } else if (type === 'string') {
        const str: string = value;
        if (str.startsWith('[') && str.endsWith(']')) {
          try {
            const list: string[] = JSON.parse(str);
            for (const v of list) {
              this.dataLog(`${action}`, `${action}_${key}`, '' + v, defaultValue);
            }
          } catch (e) {
            Logger.logError('ANALYTIC', 'PARSE STRING LIST LABEL', e + '');
            this.dataLog(`${action}`, `${action}_${key}`, '' + value, defaultValue);
          }
        } else {
          this.dataLog(`${action}`, `${action}_${key}`, '' + value, defaultValue);
        }
      } else if (type === 'number') {
        this.dataLog(`${action}`, `${action}_${key}`, `${action}_${key}`, value);
      }
      await sleep(250);
    }
  }
  private static dataLog(action: string, key: string, stringValue: string, numericValue = 1) {
    stringValue = this.camelCaseToUppercase(stringValue);
    action = this.camelCaseToUppercase(action);
    key = this.camelCaseToUppercase(key);
    const category: any = this.camelCaseToUppercase(`DATA_LOG_${action}`);
    this.onEvent({
      category,
      action: key,
      label: stringValue,
      value: numericValue,
    });
  }

  private static camelCaseToUppercase(str: string) {
    if (!str) str = '';
    return str
      .replace(/\s/gm, '_')
      .replace(/([a-z])([A-Z][A-Z]*)/g, '$1_$2')
      .toUpperCase();
  }
  private static toSimpleKeyValuePair(obj: {[key: string]: string | boolean} | undefined | string) {
    if (!obj) {
      return undefined;
    }
    if (typeof obj === 'string') {
      return obj;
    }
    let str = '';
    getKeyValuePairs(obj).forEach(({key, value}) => {
      str += `${key}:${value + ''},`;
    });
    return str;
  }
  static getProductAttributes(item: MagentoItem, quantity = 1) {
    if (item.isWine()) {
      const wine = new Wine(item);
      return {
        item_id: wine.getSku(),
        sku: wine.getSku(),
        item_name: wine.getName(),
        price: wine.getPrice(),
        brand: wine.getProducer(),
        alcohol: wine.getAlcoholPercent(),
        country: wine.getCountry(),
        region: wine.getRegion(),
        category: JSON.stringify(wine.getCategories()),
        isOrganic: wine.isOrganic(),
        producer: wine.getProducer(),
        merchantId: wine.getMerchantId(),
        grapeVariety: wine.getGrapeVariety(),
        quantity,
      };
    } else {
      return {
        id: item.getSku(),
        name: item.getName(),
        price: item.getPrice(),
      };
    }
  }
  static onProductViewDetail(item: MagentoItem) {
    this.tryPerformTask(() => {
      Logger.logInfo('[ANALYTIC]', 'VIEW_DETAIL', item.getSku());
      this.adaptor.onProductViewDetail(item);
    });
  }
  static onProductImpression(item: MagentoItem) {
    this.tryPerformTask(() => {
      this.adaptor.onProductImpression(item);
    });
  }
  static clearReferrer() {
    this.tryPerformTask(() => {
      this.adaptor.clearReferrer();
    });
  }
  static async tryPerformTask(task: () => any) {
    try {
      if (this.adaptor) {
        await task();
      }
    } catch (e) {
      Logger.logError('[ANALYTIC]', 'ERROR', e);
    }
  }
}
