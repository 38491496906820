import {IMagentoItem} from '../Interface/IMagentoItem';

export interface BuilderChain {
  AND: () => MagentoSearchQueryBuilder;
  OR: () => MagentoSearchQueryBuilder;
  toString: () => string;
}
type SearchFieldNames = keyof IMagentoItem | 'attribute_code';
export class MagentoSearchQueryBuilder {
  index = 0;
  queryString = '';
  pageSize = 50;
  orIndex = 0;
  public custom(key1: string, key2: any, value: any) {
    if (value) {
      this.queryString += `${key1 + ''}[${key2 + ''}]=${value + ''}&`;
    }
    return this.queryString;
  }
  public greaterThen(fieldName: SearchFieldNames, value: number): BuilderChain {
    return this.add(fieldName, value, 'gt');
  }
  public equalTo(fieldName: SearchFieldNames, value: number | string): BuilderChain {
    return this.add(fieldName, value, 'eq');
  }
  public like(fieldName: SearchFieldNames, value: string): BuilderChain {
    return this.add(fieldName, `%${value}%`, 'like');
  }
  private add(fieldName: SearchFieldNames, value: string | number, condition: string): BuilderChain {
    this.queryString += `searchCriteria[filter_groups][${this.index}][filters][${this.orIndex}][field]=${fieldName}&
		searchCriteria[filter_groups][${this.index}][filters][${this.orIndex}][value]=${value}&
        searchCriteria[filter_groups][${this.index}][filters][${this.orIndex}][condition_type]=${condition}&`;
    return {
      AND: () => {
        this.index++;
        this.orIndex = 0;
        return this;
      },
      OR: () => {
        this.orIndex++;
        return this;
      },
      toString: () => {
        return this.toString();
      },
    };
  }
  public or() {
    this.index--;
  }
  public setPageSize(size: number) {
    this.pageSize = size;
    return this;
  }
  public toString() {
    return this.queryString + (this.pageSize > 0 ? `searchCriteria[page_size]=${this.pageSize}` : '');
  }
  public noPaging() {
    this.pageSize = -1;
    return this;
  }
}
