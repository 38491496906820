import Axios from 'axios';

import {IRestService, ThreadHandler, Timer, sleep, toJsonString} from '..';
import {getMagentoApiUrl, GENERAL_CACHE_EXPIRE_MINUTES, getLambdaApiURL} from '../Config';
import {CacheHandler} from './CacheHandler';
import {WD} from '../Helper/WD';
import {RestProcess} from '../Interface/RestProcess';
import {Logger} from './Logger';

const cacheURLs: {
  url: string;
  expireMinutes: number;
}[] = [
  {
    url: 'products/?searchCriteria',
    expireMinutes: GENERAL_CACHE_EXPIRE_MINUTES,
  },
  {
    url: '/directory/countries',
    expireMinutes: GENERAL_CACHE_EXPIRE_MINUTES,
  },
  {
    url: 'availablenextdayproducts',
    expireMinutes: GENERAL_CACHE_EXPIRE_MINUTES,
  },
  {
    url: 'mobiconnect/latestproducts',
    expireMinutes: GENERAL_CACHE_EXPIRE_MINUTES,
  },
  {
    url: 'mobiconnect/saleproducts',
    expireMinutes: GENERAL_CACHE_EXPIRE_MINUTES,
  },
  {
    url: 'wd/products/attributes',
    expireMinutes: GENERAL_CACHE_EXPIRE_MINUTES,
  },
  {
    url: 'stockStatuses/',
    expireMinutes: GENERAL_CACHE_EXPIRE_MINUTES,
  },
  {
    url: 'products/search?',
    expireMinutes: GENERAL_CACHE_EXPIRE_MINUTES,
  },
  {
    url: 'combined-attributes',
    expireMinutes: GENERAL_CACHE_EXPIRE_MINUTES,
  },
];
const noRetry = ['api/location'];
export class RestServiceHandler implements IRestService {
  static cached: {[key: string]: string} = {};
  baseURL: string;
  useCache: boolean;
  noBearerString: boolean;
  constructor(
    private token = '',
    private store: {
      threadHandler: ThreadHandler;
      cacheHandler: CacheHandler;
      internetWorking: boolean;
    },
    {baseURL = getMagentoApiUrl(), useCache = true} = {},
    noBearerString = false,
  ) {
    this.baseURL = baseURL;
    this.useCache = useCache;
    this.noBearerString = noBearerString;
  }
  public requestToLambda(
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    bodyObject = {},
    {baseUrl = undefined, bearerToken = this.token} = {},
  ) {
    return this.request(url, method, bodyObject, {
      baseUrl: baseUrl || getLambdaApiURL(),
      bearerToken,
    });
  }
  public async request(
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    bodyObject = {},
    {baseUrl = undefined, bearerToken = this.token} = {},
  ) {
    while (url.indexOf('\n') >= 0) {
      url = url.replace('\n', '');
    }
    while (url.indexOf('\t') >= 0) {
      url = url.replace('\t', '');
    }
    const timer = new Timer('REST ' + url);
    const base = baseUrl !== undefined ? baseUrl : this.baseURL;
    const requestUrl = base + encodeURI(url);

    const _sentRequest = async () => {
      const authorization = bearerToken ? `${this.noBearerString ? '' : 'Bearer '}${bearerToken}` : undefined;
      let headers = {Accept: 'application/json', 'Content-Type': 'application/json'};
      if (authorization) {
        headers = {...headers, authorization} as any;
      }
      let response;
      const bodyJSON = toJsonString(bodyObject, null, 4);
      const logs = {
        method,
        url: decodeURI(requestUrl),
        headers,
        bodyObject,
        bodyJSON,
        logString: toJsonString({method, url: decodeURI(requestUrl), headers, bodyObject}, null, 4),
      };
      Logger.logInfo('REST', 'SENT', {...logs});
      try {
        if (method !== 'GET') {
          const body = toJsonString(bodyObject);
          response = await Axios.request({url: requestUrl, method, headers, data: body});
          // response = (await fetch(requestUrl, { method, headers, body }));
        } else {
          response = await Axios.request({url: requestUrl, method, headers});
          // response = (await fetch(requestUrl, { method, headers }));
        }
        let timeUsed;
        try {
          response = await response.data;
          logs.logString = toJsonString({method, url: decodeURI(requestUrl), headers, bodyObject, response}, null, 4);
          timeUsed = timer.getTiming() + 'ms';
        } catch (e) {
          Logger.logError('REST', 'ERROR_PARSE', {...logs, timeUsed, response});
          console.error(new Error('Failed to parse JSON'), e);
        }
        timeUsed = timer.getTiming() + 'ms';
        if (response.message) {
          Logger.logError('REST', 'ERROR_MESSAGE', {
            ...logs,
            timeUsed,
            response,
            exception: response._bodyText,
            errorType: 'HAS_MESSAGE',
          });
          throw response.message;
        }
        if (response.trace) {
          const message = this.getMessage(response.message, response.parameters);
          Logger.logError('REST', 'ERROR_TRACE', {
            ...logs,
            timeUsed,
            response,
            modifiedMessage: message,
            errorType: 'HAS_TRACE',
          });
          throw message;
        } else if (response.ok === false || response.status === false) {
          const text: string = response._bodyText;
          Logger.logError('REST', 'ERROR_NOT_OK', {
            timeUsed,
            ...logs,
            url,
            response,
            exception: response._bodyText,
            errorType: 'NOT_OK',
          });
          throw text;
        } else {
          // const jsonString = { string: toJsonString(response) };
          Logger.logSuccess('REST', 'RECEIVED', {timeUsed, ...logs, response});
        }
        this.store.internetWorking = true;
        return response;
      } catch (e) {
        let errorString = 'SOMETHING WRONG';
        try {
          errorString = JSON.parse(toJsonString(e));
        } catch (ee) {
          console.log(ee);
          console.log('CANNOT PARSE ERROR');
        }
        const timeUsed = timer.getTiming() + 'ms';
        if (('' + e).indexOf('Network request failed') >= 0) {
          if (noRetry.find((str) => (e + '').indexOf(str) < 0)) {
            Logger.logError('REST', 'NETWORK_ERROR', {...logs, timeUsed});
            this.store.internetWorking = false;
            await sleep(5000);
            Logger.logError('REST', 'RETRY', {...logs, timeUsed, error: errorString});
            return await this.request(url, method, bodyObject);
          }
          Logger.logError('REST_ERROR', 'NO_RETRY', {...logs, timeUsed, error: errorString});
        }
        if (e.response) {
          if (e.response.data) {
            if (e.response.data.message) {
              const str: string = e.response.data.message;
              if (e.response.data.parameters) {
                const message = this.getMessage(str, e.response.data.parameters);
                Logger.logError('REST_ERROR', 'UNKNOWN_MODIFIED', {
                  ...logs,
                  timeUsed,
                  error: errorString,
                  modifiedMessage: message,
                });
                throw new Error(message);
              } else {
                Logger.logError('REST_ERROR', 'UNKNOWN_NO_MODIFY', {
                  ...logs,
                  timeUsed,
                  error: errorString,
                  message: str,
                });
                throw new Error(str.substr(0, 500));
              }
            } else {
              console.log(e.response.data);
            }
          }
        }
        Logger.logError('REST_ERROR', 'UNKNOWN', {...logs, timeUsed, error: errorString});
        throw e;
      }
    };

    const sentRequest = (async () => {
      const restProcess = new RestProcess(url);
      try {
        this.addToProcessQueue(restProcess);
        const result = await _sentRequest();
        this.removeFromProcessQueue(restProcess);
        return result;
      } catch (e) {
        this.removeFromProcessQueue(restProcess);
        throw e;
      }
    }).bind(this);

    const cacheConfig = cacheURLs.find((u) => url.indexOf(u.url) >= 0);
    if (cacheConfig) {
      const cacheKey = requestUrl + method + toJsonString(bodyObject);
      if (this.useCache) {
        try {
          const response = await this.store.cacheHandler.getCache(cacheKey, cacheConfig.expireMinutes * 60 * 1000, {
            onNotFound: async () => {
              const result = await sentRequest();
              const timeUsed = timer.getTiming() + 'ms';
              Logger.logInfo('REST', 'CACHE_EXPIRED', {method, requestUrl, result, timeUsed});
              return result;
            },
            onUsedCache: (result) => {
              const timeUsed = timer.getTiming() + 'ms';
              Logger.logSystem(
                {
                  title: 'REST',
                  subtitle: 'CACHE',
                  color: 'white',
                  backgroundColor: 'orange',
                },
                {method, requestUrl, result, timeUsed},
              );
            },
          });
          return response;
        } catch (err) {
          const result = await sentRequest();
          const timeUsed = timer.getTiming() + 'ms';
          Logger.logInfo('REST', `FAIL GET CACHE ${cacheKey}`, err + '', {method, requestUrl, result, timeUsed});
          return result;
        }
      } else {
        const response = await sentRequest();
        try {
          this.store.cacheHandler.saveToDisk(cacheKey, response);
        } catch (err) {
          Logger.logInfo('REST', 'FAIL SAVE CACHE', {method, requestUrl, cacheKey, response});
        }
        return response;
      }
    } else {
      return await sentRequest();
    }
  }
  getMessage(message: string, parameters: {[key: string]: string}) {
    if (parameters) {
      Object.keys(parameters).forEach((key) => {
        const value = parameters[key];
        message = message.replace('%' + key, value);
      });
    }
    const matches = /\%([0-9a-zA-Z]*)/g.exec(message);
    if (matches) {
      matches.forEach((m) => {
        if (m.indexOf('%') >= 0) {
          m = m.replace('%', '');
          if (!isNaN(m as any)) {
            const index = Number.parseInt(m);
            message = message.replace('%' + index, parameters[index - 1]);
          } else {
            message = message.replace('%' + m, parameters[m]);
          }
        }
      });
    }
    return message;
  }
  clone(useCache: boolean) {
    return new RestServiceHandler(this.token, this.store, {useCache, baseURL: this.baseURL});
  }

  addToProcessQueue(restProcess: RestProcess) {
    if (!WD.store.restProcesses.find((p) => p.id === restProcess.id)) {
      WD.store.restProcesses.push(restProcess);
    }
  }

  removeFromProcessQueue(restProcess: RestProcess) {
    WD.store.restProcesses = WD.store.restProcesses.filter((p) => p.id !== restProcess.id);
  }
}
