import React from 'react';
import { COLOR } from '@wine-delivery/wd-shared-lib';
import { ResponsiveView } from '../Common/ResponsiveView';
import { Text } from '../Common/Text';
import { ToastOptions } from './ToastDispatcher';
import './Toast.css';

const backgroundColor = {
  success: COLOR.DARK_BLACK,
  fail: COLOR.AMBER,
  error: COLOR.RED,
};

const iconName = {
  success: 'check_circle_outline',
  fail: 'warning',
  error: 'error_outline',
};

type Props = {
  options: ToastOptions;
  show?: boolean;
};

export const Toast = ({ options, show }: Props) => {
  const backgroundStyle = backgroundColor[options.state];

  return (
    <ResponsiveView
      id="wd-toast-view"
      className={`${show ? 'slideInLeft' : 'slideOutLeft'} animated`}
      style={{ ...styles.container, backgroundColor: backgroundStyle }}
      phoneStyle={{ left: 0, right: 0 }}>
      <Text style={{ color: 'white' }}>{options.msg}</Text>
    </ResponsiveView>
  );
};

const styles = {
  container: {
    position: 'fixed',
    left: 50,
    top: 75,
    minHeight: 30,
    minWidth: 250,
    backgroundColor: COLOR.DARK_BLACK,
    margin: 32,
    padding: 8,
    paddingLeft: 16,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: COLOR.TOSCA,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    userSelect: 'none',
    pointerEvents: 'none',
    zIndex: 2,
  } as React.CSSProperties,
  content: {
    height: '100%',
    width: '100%',
  } as React.CSSProperties,
};
