import React from 'react';
import { Modal } from '@mui/material';
import { COLOR } from '@wine-delivery/wd-shared-lib';
import { getBottomBorderStyle, getShadowStyle, getTopBorderStyle } from '../../Helpers/LayoutHelper';
import { AppButton } from '../Common/Button/AppButton';
import { Icon } from '../Common/Icon';
import { ResponsiveView } from '../Common/ResponsiveView';
import { Text } from '../Common/Text';
import { Touchable } from '../Common/Touchable';

type Props = {
  id: string;
  title?: string;
  iconStyle?: 'back' | 'close';
  desktopContainerWidth?: 'half' | 'full';
  desktopContainerHeight?: 'half' | 'third' | 'full';
  phoneContainerWidth?: 'half' | 'fit';
  phoneContainerHeight?: 'half' | 'fit';
  phoneRounded?: boolean;
  actionLabel?: string;
  children: React.ReactNode;
  isOpen: boolean;
  noFooter?: boolean;
  showCloseIcon?: boolean;
  webContainerStyle?: React.CSSProperties;
  phoneContainerStyle?: React.CSSProperties;
  onClose: () => void;
  onAction?: () => void;
};

export function AppModal({
  id,
  title,
  iconStyle,
  desktopContainerWidth,
  desktopContainerHeight,
  phoneContainerWidth,
  phoneContainerHeight,
  phoneRounded,
  actionLabel,
  children,
  isOpen,
  noFooter,
  showCloseIcon,
  webContainerStyle,
  phoneContainerStyle,
  onClose,
  onAction,
}: Props): JSX.Element {
  return (
    <Modal
      id={id}
      open={isOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      onClose={onClose}>
      <ResponsiveView
        webStyle={{
          ...styles.container,
          width: desktopContainerWidth ? (desktopContainerWidth === 'full' ? '100%' : '50%') : '30%',
          height: desktopContainerHeight
            ? desktopContainerHeight === 'full'
              ? '100%'
              : desktopContainerHeight === 'third'
              ? '75%'
              : '50%'
            : undefined,
          ...webContainerStyle,
        }}
        phoneStyle={{
          ...styles.phoneContainer,
          width: phoneContainerWidth ? (phoneContainerWidth === 'fit' ? undefined : '50%') : '100%',
          height: phoneContainerHeight ? (phoneContainerHeight === 'fit' ? undefined : '50%') : '100%',
          borderRadius: phoneRounded ? 5 : undefined,
          ...phoneContainerStyle,
        }}>
        {showCloseIcon && (
          <Touchable
            style={{ cursor: 'pointer', position: 'absolute', top: 5, right: 5, zIndex: 50 }}
            onClick={() => onClose()}>
            <Icon name="close" />
          </Touchable>
        )}
        <ResponsiveView id={`${id}-content`} style={styles.content}>
          {children}
        </ResponsiveView>
        {!noFooter && (
          <ResponsiveView id={`${id}-footer`} style={styles.footer}>
            <AppButton
              label={actionLabel}
              style={styles.footerButton}
              onPress={() => {
                if (onAction) {
                  onAction();
                }
              }}
            />
          </ResponsiveView>
        )}
      </ResponsiveView>
    </Modal>
  );
}

AppModal.displayName = 'AppModal';

const styles = {
  container: {
    backgroundColor: COLOR.WHITE,
    flexDirection: 'column',
    alignSelf: 'center',
    position: 'relative',
    borderRadius: 5,
  } as React.CSSProperties,
  phoneContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: COLOR.WHITE,
    flexDirection: 'column',
    position: 'relative',
  } as React.CSSProperties,
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...getBottomBorderStyle(),
    ...getShadowStyle(),
    padding: 15,
    top: 0,
    position: 'static',
  } as React.CSSProperties,
  titleText: {
    paddingLeft: 10,
  } as React.CSSProperties,
  content: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
  } as React.CSSProperties,
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...getTopBorderStyle(),
    ...getShadowStyle(),
    padding: 10,
    bottom: 0,
    position: 'static',
  } as React.CSSProperties,
  footerButton: {
    paddingLeft: 50,
    paddingRight: 50,
  } as React.CSSProperties,
};
