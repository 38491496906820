import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { ICustomer, getBuildType } from '@wine-delivery/wd-shared-lib';
import { getBuildVersion } from './CommonHelper';

export class DatadogHelper {
  static init() {
    datadogRum.init({
      applicationId: 'd6ce3964-2096-4ef0-90e7-06ac0f1d1408',
      clientToken: 'pub063f22f34b573dab0d56bea35d28af98',
      site: 'datadoghq.com',
      service: 'wine.delivery-website',
      env: getBuildType(),
      // Specify a version number to identify the deployed version of your application in Datadog
      version: getBuildVersion(),
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
    datadogLogs.init({
      clientToken: 'pub063f22f34b573dab0d56bea35d28af98',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: getBuildType(),
      version: getBuildVersion(),
      service: 'wine.delivery-website',
    });
  }

  static startSessionReplay() {
    datadogRum.startSessionReplayRecording();
  }

  static setUser(customer: ICustomer) {
    if (customer) {
      datadogRum.setUser({
        id: customer.id + '',
        name: customer.firstname + ' ' + customer.lastname,
        email: customer.email,
      });
      datadogLogs.setUser({
        id: customer.id + '',
        name: customer.firstname + ' ' + customer.lastname,
        email: customer.email,
      });
    }
  }

  static clearUser() {
    datadogRum.clearUser();
    datadogLogs.clearUser();
  }

  static log(action: string, data?: object, type: 'info' | 'debug' | 'warn' | 'error' = 'info', error?: Error) {
    if (type === 'info') {
      datadogLogs.logger.info(action, data);
    }
    if (type === 'debug') {
      datadogLogs.logger.debug(action, data);
    }
    if (type === 'warn') {
      datadogLogs.logger.warn(action, data);
    }
    if (type === 'error') {
      datadogLogs.logger.error(action, data, error);
    }
  }
}
